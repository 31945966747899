import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";

const StakingReferredIncome = () =>{

  const [referralIncome,setReferralIncome] = useState([])
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [status, setStatus] = useState()

useEffect(()=>{
handleReferralIncome()
},[])


const handleReferralIncome = async () => {
  LoaderHelper.loaderStatus(true);
  await AuthService.getReferralIncome().then(async (result) => {
    console.log(result);
    LoaderHelper.loaderStatus(false);
    if (result.success) {
      try {
        setReferralIncome(result?.data?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
  });
};

const handleHarvest = async (currency_name, interest_amount, _id) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.getHarvest(currency_name, interest_amount, _id).then(async (result) => {
    console.log(result, "kk");
    LoaderHelper.loaderStatus(false);
    if (result.success) {
      try {
        setDisabledButtons(prevDisabledButtons => [...prevDisabledButtons,  _id]);
        alertSuccessMessage("Successfully Harvested, Check your wallet")
        handleReferralIncome()
      } catch (error) {
        console.log(error);
      }
    }
  });
};

    return (
        //
        //
    
        <>
          <section class="inner-page-banner">
            <div class="container">
              <div class="inner text-center">
                <h1 class="title">Total Referral Income</h1>
                <nav class="mt-4">
                  <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                    Total Referral Income
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </section>
          <section class="">
            <div class="container">
            {/* <div class="d-flex-between mb-3 custom_dlflex"><div class="d-flex-between bidder"><span>Total Referal Earned (USDT): </span><div class="d-flex-center ms-1"><span class="text-white">00.000</span></div></div></div> */}
              <div class="tab-content custom-tab-content p-0">
                <div
                  class="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
                  id="funds"
                >
                  <div class="table-responsive">
                    {referralIncome.length === 0 ? (
                      <div className="favouriteData">
                        <img
                          src="images/no-data.svg"
                          className="img-fluid"
                          width="96"
                          height="96"
                          alt=""
                        />
                        <br />
                        <p className="mt-3 mb-4"> No Data Found. </p>
                      </div>
                    ) : (
                      <table class="table mb-0  ">
                        <thead className="border-bottom"  >
                          <tr>
                            <th> Sr No. </th>
                            <th> Date </th>
                            <th> Coin Name </th>
                            <th> Staking Income (PLR) </th>
                            <th> Transaction Type </th>
                            <th> Action</th>
                          </tr>
                        </thead>
                        {referralIncome.length > 0 ? (
                      referralIncome.map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td>

                            <td>{value?.short_name}</td>
                            <td> {value?.amount}</td>
                            <td>{value?.transaction_type}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.short_name, value?.amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status == true ? true : false}>
                                {value?.harvest_status == true || status == true ? "Harvested" : "Harvest"}
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="6">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </table>
                )}
                  </div>
                </div>
              </div>
    
              
    
    
    
            </div>
          </section>
    
   
    
    
    
        </>
      );
    };

export default StakingReferredIncome;