import React, { useEffect, useState } from "react";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import AuthService from "../../../api/services/AuthService";
import { Link, useLocation } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import TVChartContainer from "../../../customComponent/TVChartContainer";
import {
    alertErrorMessage,
    alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import "./trade.css";
import { io } from "socket.io-client";
import moment from "moment";

const Trade = () => {
    const location = useLocation();
    const { state } = location;
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');
    const [search, setsearch] = useState('');
    const [AllData, setAllData] = useState([]);
    const [BuyOrders, setBuyOrders] = useState([]);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [RecentTrade, setRecentTrade] = useState([]);
    const [SellOrders, setSellOrders] = useState([]);
    const [SelectedCoin, setSelectedCoin] = useState(state ? state : null);
    const [baseCurId, setbaseCurId] = useState(state ? state?.base_currency_id : null);
    const [quoteCurId, setquoteCurId] = useState(state ? state?.quote_currency_id : null);
    const [buyprice, setbuyprice] = useState(state ? state?.buy_price : 0);
    const [buyamount, setbuyamount] = useState(1);
    const [buytotal, setbuytotal] = useState(0);
    const [sellPrice, setsellPrice] = useState(state ? state?.sell_price : 0);
    const [sellAmount, setsellAmount] = useState(1);
    const [sellTotal, setsellTotal] = useState(0);
    const [infoPlaceOrder, setinfoPlaceOrder] = useState('LIMIT');
    const [coinFilter, setcoinFilter] = useState('ALL');
    const [BuyCoinBal, setBuyCoinBal] = useState();
    const [SellCoinBal, setSellCoinBal] = useState();
    const [openOrders, setopenOrders] = useState([]);
    const [orderType, setorderType] = useState('All');
    const [pastOrderType, setpastOrderType] = useState('All');
    const [buyTriggerPrice, setbuyTriggerPrice] = useState();
    const [sellTrigegrPrice, setsellTrigegrPrice] = useState();
    const [priceDecimal, setpriceDecimal] = useState(8);
    const [pastOrders, setpastOrders] = useState([]);
    const [pastOrder2, setpastOrder2] = useState([]);
    const [makerFee, setmakerFee] = useState('');
    const [takerFee, settakerFee] = useState('');
    const [favCoins, setfavCoins] = useState([]);
    const [sellOrderPrice, setsellOrderPrice] = useState(undefined);
    const [buyOrderPrice, setbuyOrderPrice] = useState(undefined);
    const [priceChange, setpriceChange] = useState();
    const [priceHigh, setpriceHigh] = useState();
    const [priceLow, setpriceLow] = useState();
    const [volume, setvolume] = useState();
    const [decimals, setdecimals] = useState();
    const [socket, setSocket] = useState(null);


    // ********* Socket Connection and Data fetch ********** //
    useEffect(() => {
        const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
          transports: ['websocket'],
          upgrade: false,
          rejectUnauthorized: false,
          reconnectionAttempts: 3,
          debug: true
        });
      
        setSocket(newSocket);
        if (state) {
            let payload={
                'message':'exchange',
                'userId': userId,
                'base_currency_id':state?.base_currency_id,
                'quote_currency_id':state?.quote_currency_id,
                
            }
            newSocket.emit('message', payload);
   
    }else{
        let payload={
            'message':'exchange',
            'userId': userId,
           
        }
        newSocket.emit('message', payload);
    }
        return () => {
          newSocket.disconnect();
        };
      }, []);

 useEffect(() => {
  if (socket) {
    socket.on('message', (data) => {
      console.log(data, 'Socket data');
      setAllData(data);
      setBuyOrders(data?.buy_order?.slice(-10));
      setRecentTrade(data?.recent_trades?.slice(-10)?.reverse());
      setSellOrders(data?.sell_order?.slice(-10));
      setBuyCoinBal(data?.balance?.quote_currency_balance);
      setSellCoinBal(data?.balance?.base_currency_balance);
      setopenOrders(data?.open_orders?.reverse());
    });
  }
}, [socket]);

    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setSelectedCoin(CoinPairDetails[1]);
            setbaseCurId(CoinPairDetails[1]?.base_currency_id);
            setquoteCurId(CoinPairDetails[1]?.quote_currency_id);
            setbuyprice(CoinPairDetails[1]?.buy_price);
            setsellPrice(CoinPairDetails[1]?.sell_price);
            coinDetails(CoinPairDetails[1]?.base_currency_id);
                let payload={
                    'message':'exchange',
                    'userId': userId,
                    'base_currency_id':CoinPairDetails[1]?.base_currency_id,
                    'quote_currency_id':CoinPairDetails[1]?.quote_currency_id,
                    // 'name': 'Pallav',
                }
                socket.emit('message', payload);
       
        }
    }, [CoinPairDetails, infoPlaceOrder]);


    // ******** Check Decimals Of Buy Price ************//
    function countDecimalPlaces(number) {
        const decimalString = number.toString();
        const decimalIndex = decimalString.indexOf('.');
        if (decimalIndex !== -1) {
            setdecimals(decimalString.length - decimalIndex - 1)
        } else {
            setdecimals(0)
        }
    };


    // ********* Generate Dynamic Options for Price Decimals In order book ******** //
    const generateOptions = () => {
        const options = [];
        for (let i = decimals; i >= 1; i--) {
            const value = Math.pow(10, -i);
            options.push(
                <option selected={i === decimals} key={i} value={i}>
                    {value.toFixed(i)}
                </option>
            );
        }
        return options;
    };

    // ********* Filter Past Orders List by Item Side ********** //
    useEffect(() => {
        let filteredData = pastOrder2?.filter((item) => {
            return pastOrderType === item?.side || pastOrderType === 'All'
        })
        setpastOrders(filteredData ? filteredData : [])
    }, [pastOrderType]);


    // ********* Update Buy Sell 24HChange High Low Volume Price********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency_id === SelectedCoin?.base_currency_id &&item?.quote_currency_id === SelectedCoin?.quote_currency_id 
        })
        if (filteredData) {
            setbuyprice(filteredData[0]?.buy_price);
            setsellPrice(filteredData[0]?.sell_price);
            setpriceChange(filteredData[0]?.change);
            setpriceHigh(filteredData[0]?.high);
            setpriceLow(filteredData[0]?.low);
            setvolume(filteredData[0]?.volume);
        }
    },[AllData]);

    // ********* Search Coins ********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency?.toLowerCase().includes(search?.toLowerCase()) || item?.quote_currency?.toLowerCase().includes(search?.toLowerCase())
        })
        setCoinPairDetails(filteredData)

    }, [search, AllData]);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        token && favoriteList();
    }, []);


    const handleOrderPlace = async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.placeOrder(infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side).then((result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Placed Successfully!!')
                    setbuyOrderPrice(undefined);
                    setsellOrderPrice(undefined);
                    setbuyamount(1);
                    setsellAmount(1);
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };

    const handlePastOrder = async (base_currency_id, quote_currency_id) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.pastOrder(base_currency_id, quote_currency_id).then((result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpastOrders(result?.data)
                    setpastOrder2(result?.data)
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        })
    };

    const cancelOrder = async (orderId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.cancelOrder(orderId).then((result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Cancelled Successfully');
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };

    const coinDetails = async (currId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.coinDetails(currId).then((result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setmakerFee(result?.data?.maker_fee);
                    settakerFee(result?.data?.taker_fee);
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };
    const handleAddFav = async (pairId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.favoriteCoin(pairId).then((result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    favoriteList()
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };
    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result.success) {
                try {
                    setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };
    const handleSelectCoin = (data) => {
        setsellOrderPrice(undefined);
        setbuyOrderPrice(undefined);
        setSelectedCoin(data);
        setbaseCurId(data?.base_currency_id);
        setquoteCurId(data?.quote_currency_id);
        setbuyprice(data?.buy_price);
        setsellPrice(data?.sell_price);
        coinDetails(data?.base_currency_id);
        let payload={
            'message':'exchange',
            'userId': userId,
            'base_currency_id':data?.base_currency_id,
            'quote_currency_id':data?.quote_currency_id,
            // 'name': 'Pallav',
        }
        socket.emit('message', payload);
    };

    const handleOrderType = (e) => {
        setinfoPlaceOrder(e.target.value);
        if (e.target.value === 'MARKET') {
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setbuyprice(SelectedCoin?.buy_price)
            setsellPrice(SelectedCoin?.sell_price)
        };
    };

    return (
        <>
            <div class="trade-wrapper spot login_bg mb-5 ">
                <div class="spot-container container-fluid">
                    <div class="row g-2" >
                        <div class="col-12 col-sm-6 col-lg-6 col-xl-3 col-xxl-2 order-2" >
                            <div class="trade_card  mb-2" >
                                <div class=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between   ">
                                    <h6 class="mb-0">{SelectedCoin?.base_currency}/{SelectedCoin?.quote_currency}</h6>
                                    <div className='d-flex' >
                                        <ul class="nav custom-tabs nav_order">
                                            <li class="fav-tab"><a class="active" data-bs-toggle="tab" href="#all_orders"> <img alt='' src="images/order_1.svg" width="22" height="11" />   </a></li>
                                            <li class="usdt-tab"><a data-bs-toggle="tab" href="#buy_orders"> <img alt='' src="images/order_1.svg" width="22" height="11" />  </a></li>
                                            <li class="btc-tab"><a data-bs-toggle="tab" href="#sell_orders" class="me-0"> <img alt='' src="images/order_1.svg" width="22" height="11" /> </a></li>
                                        </ul>
                                        <div className='num-div' >
                                            <select class="form-select num-select p-0 input-select" aria-label="Default select example" onClick={(e) => { setpriceDecimal(e.target.value) }}>
                                                {/* {generateOptions()} */}
                                                <option selected value={8}>
                                                    0.00000001
                                                </option>
                                                <option value={7}>
                                                    0.0000001
                                                </option>
                                                <option value={6}>
                                                    0.000001
                                                </option>
                                                <option value={5}>
                                                    0.00001
                                                </option>
                                                <option value={4}>
                                                    0.0001
                                                </option>
                                                <option value={3}>
                                                    0.001
                                                </option>
                                                <option value={2}>
                                                    0.01
                                                </option>
                                                <option value={1}>
                                                    0.1
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content buy_sell_row_price" >
                                    <div class="tab-pane fade px-0  active show" id="all_orders">
                                        <div class="price_card">
                                            <div class="price_card_head">
                                                <div>Price({SelectedCoin?.quote_currency})</div>
                                                <div>Quantity({SelectedCoin?.base_currency})</div>
                                                <div>Total({SelectedCoin?.quote_currency})</div>
                                            </div>
                                            <div class="price_card_body scroll_y     " style={{ cursor: "pointer" }} >
                                                {SellOrders.length > 0 ? SellOrders?.map((data) => {
                                                    return (
                                                        <div class="price_item_value" onClick={() => { setbuyamount(data?.remaining); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                            <span class="d-flex align-items-center text-danger "> {(data?.price).toFixed(priceDecimal)}</span>
                                                            <span class=""> {(data?.remaining).toFixed(6)}</span>
                                                            <span class="text-danger"> {(data?.price * data?.remaining).toFixed(6)}</span>
                                                        </div>
                                                    )
                                                }) : <p className="text-center" style={{ textAlign: "center" }}>
                                                    No data Available</p>
                                                }
                                            </div>
                                            <div class="mrkt_trde_tab justify-content-center" >
                                                <b class={priceChange >= 0 ? "text-success" : "text-danger"} >{parseFloat(buyprice?.toFixed(5))}</b>
                                                <i class={priceChange >= 0 ? 'ri-arrow-up-line ri-xl mx-3 text-success' : 'ri-arrow-down-line ri-xl mx-3'}></i>
                                                <span>{parseFloat(priceChange?.toFixed(5))}</span>
                                            </div>
                                            {/* <div class="price_card_head">

                                                <div>Price({SelectedCoin?.quote_currency})</div>
                                                <div>Quantity({SelectedCoin?.base_currency})</div>
                                                <div>Total({SelectedCoin?.quote_currency})</div>
                                            </div> */}
                                            {/* scroll_y */}
                                            <div class="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                                                {BuyOrders.length > 0 ?
                                                    BuyOrders?.map((data) => {
                                                        return (
                                                            <>
                                                                <div class="price_item_value" onClick={() => { setsellAmount(data?.remaining); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                    <span className={"text-success d-flex lign-items-center"} >{(data?.price).toFixed(priceDecimal)}</span>
                                                                    <span class="">{(data?.remaining).toFixed(6)}</span>
                                                                    <span class="text-success">{(data?.price * data?.remaining).toFixed(6)}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }) : <p className="text-center" style={{ textAlign: "center" }}>
                                                        No data Available</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade px-0 " id="buy_orders">
                                        <div class="price_card">
                                            <div class="price_card_head">
                                                <div>Price({SelectedCoin?.quote_currency})</div>
                                                <div>Quantity({SelectedCoin?.base_currency})</div>
                                                <div>Total({SelectedCoin?.quote_currency})</div>
                                            </div>
                                            <div class="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                                                {BuyOrders.length > 0 ?
                                                    BuyOrders?.map((data) => {
                                                        return (
                                                            <>
                                                                <div class="price_item_value" onClick={() => { setsellAmount(data?.remaining); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                    <span className={"text-success d-flex lign-items-center"} >{(data?.price).toFixed(priceDecimal)}</span>
                                                                    <span class="">{(data?.remaining).toFixed(6)}</span>
                                                                    <span class="text-success">{(data?.price * data?.remaining).toFixed(6)}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }) : <p className="text-center" style={{ textAlign: "center" }}>
                                                        No data Available</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade px-0 " id="sell_orders">
                                        <div class="price_card">
                                            <div class="price_card_head">
                                                <div>Price({SelectedCoin?.quote_currency})</div>
                                                <div>Quantity({SelectedCoin?.base_currency})</div>
                                                <div>Total({SelectedCoin?.quote_currency})
                                                </div>
                                            </div>
                                            <div class="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                                                {SellOrders.length > 0 ? SellOrders?.map((data) => {
                                                    return (
                                                        <div class="price_item_value" onClick={() => { setbuyamount(data?.remaining); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                            <span class="d-flex align-items-center text-danger "> {(data?.price).toFixed(priceDecimal)}</span>
                                                            <span class=""> {(data?.remaining).toFixed(6)}</span>
                                                            <span class="text-danger"> {(data?.price * data?.remaining).toFixed(6)}</span>
                                                        </div>
                                                    )
                                                }) : <p className="text-center" style={{ textAlign: "center" }}>
                                                    No data Available</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="trade_card   mb-2" >
                                <div class=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between   ">
                                    <h6 class="mb-0">My Assets</h6>
                                </div>
                                <div class="price_card_body " >
                                    <div class="actv_data d-flex justify-content-between align-items-center mb-3">
                                        <div class="actv_data_name">
                                            <p class="mb-0 text-uppercase"> <strong>{SelectedCoin?.base_currency}</strong></p></div>
                                        <div class="actv_sorting"><p class="mb-0 text-success ">{SellCoinBal ? parseFloat(SellCoinBal).toFixed(6) : 0}</p></div>
                                    </div>
                                    <div class="actv_data d-flex justify-content-between align-items-center mb-3">
                                        <div class="actv_data_name">
                                            <p class="mb-0 text-uppercase"> <strong>{SelectedCoin?.quote_currency}</strong></p></div>
                                        <div class="actv_sorting"><p class="mb-0 text-success ">{BuyCoinBal ? parseFloat(BuyCoinBal).toFixed(6) : 0}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12 col-xl-6  col-xxl-8 order-1 order-xl-2" >
                            <div class="trade_card p-3 mb-2" >
                                <div class="container-chart-briefing-info">
                                    <div class="display-name-asset wm-50">
                                        <h6 class="mb-0 displayName" id="display_name" >
                                            <span> {`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</span></h6>
                                    </div>
                                    {SelectedCoin ? <>
                                        <dl class="wm-50"><dt>Price</dt><dd class="text-success">{buyprice}</dd></dl>

                                        <dl class="wm-50"><dt>24H Change</dt>
                                            <dd class={priceChange >= 0 ? "text-success" : "text-danger"}>{(parseFloat(priceChange).toFixed(8))}</dd>
                                        </dl>
                                        <dl class="wm-50"><dt>24h High</dt>
                                            <dd class="text-success" >{parseFloat(priceHigh?.toFixed(8))}</dd>
                                        </dl>
                                        <dl class="wm-50"><dt>24h Low</dt>
                                            <dd class="text-danger" >{parseFloat(priceLow?.toFixed(8))}</dd>
                                        </dl>
                                        <dl class="wm-50"><dt>24H Vol</dt>
                                            <dd>{parseFloat(volume?.toFixed(8))}</dd>
                                        </dl></> : ''}
                                </div>
                            </div>
                            <div class="trade_card trade_chart mb-2 p-0"  >
                                <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                            </div>
                            <div class="trade_card trade_chart mb-2  p-2">
                                <div class="d-flex align-items-center justify-content-between mb-1" >
                                    <h6 class="title mb-0">Order Book</h6>
                                    <div class="field-box">
                                        <select class=" mb-0 select_mini" name="infoPlaceOrder" onChange={handleOrderType} value={infoPlaceOrder}>
                                            <option value="LIMIT">Limit</option>
                                            <option value="MARKET">Market</option>
                                            {/* <option value="STOPLIMIT">Stop Limit</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-md-6" >
                                        <form action="" class="buysellform data-buy">
                                            <p class="text-success" ><b>Avail Lowest Price </b> </p>
                                            {infoPlaceOrder === 'STOPLIMIT' &&
                                                <div class="input-group mb-3">
                                                    <input type="number" id="buyPrice" name="buyPrice" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={buyTriggerPrice} onChange={(e) => { setbuyTriggerPrice(e.target.value) }} />
                                                    <span class="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                                                </div>}
                                            <div class="input-group mb-3">
                                                <input type="number" id="buyPrice" name="buyPrice" class="form-control" aria-label="Amount (to the nearest dollar)" disabled={infoPlaceOrder === 'MARKET'} value={buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice} onChange={(e) => { setbuyOrderPrice(e.target.value) }} />
                                                <span class="input-group-text text-start"><small>  {SelectedCoin?.quote_currency}</small></span>
                                            </div>
                                            <div class="input-group mb-3">
                                                <input type="number" name="buyAmount" id="buyAmount" aria-invalid="true" class="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => { setbuyamount(e.target.value); setbuytotal(+buyprice * +e.target.value) }} value={buyamount} />
                                                <span class="input-group-text text-start"><small> {SelectedCoin?.base_currency}</small></span>
                                            </div>
                                            <div class="input-group mb-3">
                                                <input type="number" id="buy_order_total" name="buy_order_total" class="form-control" aria-label="Amount (to the nearest dollar)" value={((buyOrderPrice !== undefined || buyOrderPrice) && buyamount) ? +buyOrderPrice * +buyamount : (buyprice && buyamount) ? +buyprice * +buyamount : 0} />
                                                <span class="input-group-text text-start"><small>Total</small></span>
                                            </div>
                                            <div class="row align-items-center" >
                                                <div class="col-md-6" >
                                                    <div class="btn-group btn-group-mini " role="group" aria-label="Basic radio toggle button group">
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio125" autocomplete="off" />
                                                        <label class="btn btn-outline-success" for="btnradio125" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 25) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }} >25%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio250" autocomplete="off" />
                                                        <label class="btn btn-outline-success" for="btnradio250" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 50) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>50%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio375" autocomplete="off" />
                                                        <label class="btn btn-outline-success" for="btnradio375" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 75) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>75%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio3100" autocomplete="off" />
                                                        <label class="btn btn-outline-success" for="btnradio3100" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 100) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>100%</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" >
                                                    {token ? <button type='button' class="btn custom-btn btn-success  w-100 my-3 my-md-0"
                                                        data-bs-toggle="modal" data-bs-target="#BuyConfirm">
                                                        Buy {SelectedCoin?.base_currency}
                                                    </button> : <Link to='/login' class="btn custom-btn btn-primary  w-100 my-3 my-md-0" >
                                                        Login/Register
                                                    </Link>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-md-6" >
                                        <form action="" class="buysellform data-sell">
                                            <p class="text-danger" > <b>Avail Highest Price</b> </p>
                                            {infoPlaceOrder === 'STOPLIMIT' &&
                                                <div class="input-group mb-3">
                                                    <input type="number" id="buyPrice" name="buyPrice" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={sellTrigegrPrice} onChange={(e) => { setsellTrigegrPrice(e.target.value) }} />
                                                    <span class="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                                                </div>}
                                            <div class="input-group mb-3">
                                                <input type="number" id="buyPrice" name="buyPrice" class="form-control" aria-label="Amount (to the nearest dollar)" value={sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice} onChange={(e) => { setsellOrderPrice(e.target.value) }} disabled={infoPlaceOrder === 'MARKET'} />
                                                <span class="input-group-text text-start" ><small> {SelectedCoin?.quote_currency}</small></span>
                                            </div>
                                            <div class="input-group mb-3">
                                                <input type="number" name="buyAmount" id="buyAmount" aria-invalid="true" class="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => { setsellAmount(e.target.value); setsellTotal(+buyprice * +e.target.value) }} value={sellAmount} />
                                                <span class="input-group-text text-start"><small>{SelectedCoin?.base_currency}</small></span>
                                            </div>
                                            <div class="input-group mb-3">
                                                <input type="number" id="buy_order_total" name="buy_order_total" class="form-control" aria-label="Amount (to the nearest dollar)" value={((sellOrderPrice !== undefined || sellOrderPrice) && sellAmount) ? +sellOrderPrice * +sellAmount : (sellPrice && sellAmount) ? +sellPrice * +sellAmount : 0} />
                                                <span class="input-group-text text-start"><small>Total</small></span>
                                            </div>
                                            <div class="row align-items-center" >
                                                <div class="col-md-6" >
                                                    <div class="btn-group btn-group-mini " role="group" aria-label="Basic radio toggle button group">
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio15" autocomplete="off" />
                                                        <label class="btn btn-outline-danger" for="btnradio15" onClick={() => { setsellAmount((SellCoinBal / 100) * 25) }}>25%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio20" autocomplete="off" />
                                                        <label class="btn btn-outline-danger" for="btnradio20" onClick={() => { setsellAmount((SellCoinBal / 100) * 50) }}>50%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio35" autocomplete="off" />
                                                        <label class="btn btn-outline-danger" for="btnradio35" onClick={() => { setsellAmount((SellCoinBal / 100) * 75) }}>75%</label>
                                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio300" autocomplete="off" />
                                                        <label class="btn btn-outline-danger" for="btnradio300" onClick={() => { setsellAmount((SellCoinBal / 100) * 100) }}>100%</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" >
                                                    {token ? <button type='button' class="btn custom-btn btn-danger  w-100 my-3 my-md-0"
                                                        data-bs-toggle="modal" data-bs-target="#SellConfirm">
                                                        Sell {SelectedCoin?.base_currency}
                                                    </button> : <Link to='/login' class="btn custom-btn btn-primary  w-100 my-3 my-md-0" >
                                                        Login/Register
                                                    </Link>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-md-12 text-center mt-1" ><small><small class="text-gray">Fee: Maker: {makerFee}% | Taker: {takerFee}% </small></small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 col-xl-3 col-xxl-2 order-3 order-xl-3 " >
                            <div class="trade_card " >
                                <div class=" trade_tabs buy_sell_cards   ">
                                    <ul class="nav custom-tabs nav_order">
                                        {token && <li class="favt-tab"><a data-bs-toggle="tab" href="#tab_fav" onClick={() => { setcoinFilter('FAV') }}>FAV. </a></li>}
                                        <li class="all-tab"><a class="active" data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('ALL') }}> All </a></li>
                                        <li class="usdt-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('USDT') }}>USDT </a></li>
                                        <li class="btc-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('INR') }} class="">INR</a></li>
                                    </ul>
                                </div>
                                <div class="spot-list-search">
                                    <div class="ivu-input" >
                                        <i class="ri-search-2-line"></i>
                                        <input autocomplete="off" spellcheck="false" type="search" placeholder="Search" class=""
                                            onChange={(e) => { setsearch(e.target.value) }} value={search} />
                                    </div>
                                </div>

                                <div class="price_card">
                                    <div class="price_card_head">
                                        <div>Pair</div>
                                        <div>Price</div>
                                        <div>24H%</div>
                                    </div>

                                    <div class="price_card_body tab-content scroll_y" style={{ cursor: "pointer" }}>
                                        <div class="tab-pane px-0" id="tab_fav" >
                                            {CoinPairDetails ? CoinPairDetails?.map((data) => {
                                                return (
                                                    favCoins.includes(data?.base_currency_id) && <div class="price_item_value"
                                                    ><span class="d-flex align-items-center">
                                                            <i class={favCoins.includes(data?.base_currency_id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?.base_currency_id) }} >
                                                            </i> <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt></span><span class="">{data?.buy_price}</span><span class={data?.change >= 0 ? "text-success" : "text-danger"}>{parseFloat(data?.change.toFixed(8))}%</span></div>

                                                )
                                            }) : null}
                                        </div>
                                        <div class="tab-pane px-0 active" id="tab_all" >
                                            {CoinPairDetails ? CoinPairDetails?.map((data) => {
                                                return (
                                                    (data?.quote_currency === coinFilter || coinFilter === 'ALL') &&
                                                    <div class="price_item_value" >
                                                        <span class="d-flex align-items-center">
                                                            {token && <i class={favCoins.includes(data?.base_currency_id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?.base_currency_id) }} >
                                                            </i>} <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt> </span><span class="">{data?.buy_price}</span><span class={data?.change >= 0 ? "text-success" : "text-danger"}>{parseFloat(data?.change.toFixed(8))}%</span></div>
                                                )
                                            }) : null}
                                        </div>

                                    </div>
                                    <div class="mrkt_trde_tab " >
                                        <span>Market Traders</span>
                                    </div>
                                    <div class="price_card_head">
                                        <div>Price({SelectedCoin?.quote_currency})</div>
                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                        <div>Time
                                        </div>
                                    </div>
                                    <div class="price_card_body scroll_y" style={{ cursor: "pointer" }}>
                                        {RecentTrade.length > 0 ?
                                            RecentTrade.map((item, index) =>
                                                <>
                                                    <div class="price_item_value "><span className={item?.side === "BUY" ? "text-success d-flex align-items-center" : "text-danger d-flex align-items-center"}> {parseFloat((item?.price).toFixed(8))}</span><span class="">{(item?.quantity).toFixed(5)}</span><span > {item.updatedAt && moment(item.updatedAt).format("LTS")}</span></div>
                                                </>
                                            ) : <p className="text-center" style={{ textAlign: "center" }}>
                                                No data Available</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12 col-xl-12 order-4" >
                        <div class="trade_card mb-0">
                            <div class="d-flex-between trade_tabs w-100 align-items-center buy_sell_cards   ">
                                <ul class="nav custom-tabs order_his_tabs ">
                                    <li class="buysell-tab"><a class="active" data-bs-toggle="tab" href="#open_orders">Open Orders</a></li>
                                    <li class="buysell-tab"><a data-bs-toggle="tab" href="#past_orders" onClick={() => { token && handlePastOrder(baseCurId, quoteCurId) }}>Past Orders </a></li>
                                </ul>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active  px-0" id="open_orders">
                                    <div class="scroll_y" style={{ height: '500px' }}>
                                        <div className='table-responsive'>
                                            <table className="table table_home ">
                                                <thead>
                                                    <tr>
                                                        <th>Trading Pair</th>
                                                        <th> Date</th>
                                                        <th> Type</th>
                                                        <th> <div class="field-box">
                                                            <select class=" mb-0 form-select-sm" name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                                                <option value="All">All</option>
                                                                <option value="BUY">Buy</option>
                                                                <option value="SELL">Sell</option>
                                                            </select>
                                                        </div></th>
                                                        <th> Price</th>
                                                        <th>Amount</th>
                                                        <th>Remaining</th>
                                                        <th>Filled</th>
                                                        <th>Total</th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {openOrders?.length > 0 ? openOrders.map((item, index) =>
                                                        (orderType === item?.side || orderType === 'All') &&
                                                        <tr>
                                                            <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                            <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                                                            <td>{item?.order_type}</td>
                                                            <td>{item?.side}</td>
                                                            <td>{item?.price.toFixed(8)}</td>
                                                            <td>{item?.quantity.toFixed(8)}</td>
                                                            <td>{item?.remaining.toFixed(8)}</td>
                                                            <td>{item?.filled.toFixed(8)}</td>
                                                            <td>{(item?.price * item?.quantity).toFixed(8)}</td>
                                                            <td>
                                                                <a href='#/' class=" btn btn-danger btn-sm btn-icon" onClick={() => { cancelOrder(item?._id) }}><i class="ri-delete-bin-6-line pr-0"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ) : <tr rowSpan="5">
                                                        <td colSpan="12">
                                                            <p style={{ textAlign: "center" }}>
                                                                No data Available
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-pane fade show  px-0 " id="past_orders">
                                    <div class="scroll_y">
                                        <div className='table-responsive'>
                                            <table class="table table_home ">
                                                <thead>
                                                    <tr>
                                                        <th>Trading Pair</th>
                                                        <th>Date</th>
                                                        <th><div class="field-box">
                                                            <select class=" mb-0 form-select-sm" name="" value={pastOrderType} onChange={(e) => { setpastOrderType(e.target.value) }}>
                                                                <option value="All">All</option>
                                                                <option value="BUY">Buy</option>
                                                                <option value="SELL">Sell</option>
                                                            </select>
                                                        </div></th>
                                                        <th>Average Filled Price</th>
                                                        <th>Executed</th>
                                                        <th>Total</th>
                                                        <th>Fee</th>
                                                        <th>Order Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pastOrders.length > 0 ? pastOrders.map((item, index) =>
                                                        <tr>
                                                            <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                            <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                                                            <td>{item?.side}</td>
                                                            <td>{item?.price.toFixed(8)}</td>
                                                            <td>{item?.quantity.toFixed(8)}</td>
                                                            <td>{(item?.quantity * item?.price).toFixed(8)}</td>
                                                            <td>{item?.fee.toFixed(8)}</td>
                                                            <td>{item?.order_type}</td>
                                                        </tr>
                                                    ) : <tr rowSpan="5">
                                                        <td colSpan="12">
                                                            <p style={{ textAlign: "center" }}>
                                                                No data Available
                                                            </p>
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal fade" id="BuyConfirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <form className="modal-content">
                        <div class="modal-header flex-column px-8">
                            <h3 class="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3>
                            <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                class="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body conin_purchase">
                            <div className='row'>
                                <div className="col-md-4 m-auto mb-5 text-center" >
                                    <button type="button" className="btn custom-btn btn-success m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}> <span>Buy</span>
                                    </button>
                                </div>
                                <div className="col-md-4 m-auto mb-5 text-center" >
                                    <button type="button" className="btn custom-btn btn-primary m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade" id="SellConfirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <form className="modal-content">
                        <div class="modal-header flex-column px-8">
                            <h3 class="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3>
                            <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                class="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body conin_purchase">
                            <div className='row'>
                                <div className="col-md-4 m-auto mb-5 text-center" >
                                    <button type="button" className="btn custom-btn btn-success m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice, sellAmount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')} > <span>Sell</span>
                                    </button>
                                </div>
                                <div className="col-md-4 m-auto mb-5 text-center" >
                                    <button data-bs-dismiss="modal" type="button" className="btn custom-btn btn-primary m-auto w-100 btn btn-block"> <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Trade;
