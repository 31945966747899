import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> Privacy Policy </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Privacy policy</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <p>Thank you for choosing Polarize Crypto Exchange (hereafter referred to as "Polarize," "we," "our," or "us"). We value the trust you place in us and are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our website (www.polarize.exchange) or any related services offered by Polarize (collectively, the "Services").</p>

                <p>By accessing or using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to our practices concerning the collection, use, and disclosure of your information. If you do not agree to the terms of this Privacy Policy, please do not use our Services.</p>


                <p>
                <h5>1. Information We Collect</h5>

                <ul>
                  <li>
                  <strong>1.1 Personal Information:   </strong>   
                   While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, other information ("Personal Information").
                   </li>
                   <li>
                  <strong>1.2 Transaction Information:   </strong>    
                  We collect information about your cryptocurrency transactions, wallet addresses, and other transactional data while using our Services.
                   </li>
                   <li>
                  <strong>1.3 Log Data:  </strong>
                  Like many websites, we automatically collect certain information when you access our Services. This may include your IP address, browser type, operating system, referring URLs, device information, and access times.
                   </li>
                   <li>
                  <strong>1.4. Cookies and Similar Technologies:   </strong>     
                  We use cookies and similar tracking technologies to enhance your experience, provide personalized content, and gather information about your usage patterns on our website.
                   </li>
                </ul>
                </p>

                <p>
                <h5>2. How We Use Your Information:</h5>

                <ul>
                  <li>
                  <strong>2.1. Provide and Improve Services:    </strong>   
                  We use the information collected to deliver and maintain our Services, respond to your inquiries, and enhance the overall user experience.
                   </li>
                   <li>
                  <strong>2.2. Account Management:    </strong>    
                  We may use your information to create and manage your account, verify your identity, and process your transactions.
                   </li>
                   <li>
                  <strong>2.3. Communication:   </strong>
                  We may use your contact information to send you transactional messages, service-related updates, and marketing communications (where permitted by law). You can opt-out of marketing communications at any time.
                   </li>
                   <li>
                  <strong>2.4. Security and Fraud Prevention:    </strong>     
                  We use your data to enhance the security of our platform and detect and prevent fraudulent activities.
                   </li>
                </ul>
                </p>

                <p>
                <h5>3. How We Share Your Information:</h5>

                <ul>
                  <li>
                  <strong>3.1. Service Providers:    </strong>   
                  We may share your personal information with trusted third-party service providers who assist us in operating our Services, processing payments, or performing other business functions. These providers are obligated to maintain the confidentiality and security of your information.
                   </li>
                   <li>
                  <strong>3.2. Legal Compliance:    </strong>    
                  We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.
                   </li>
                   <li>
                  <strong>3.3. Business Transfers:  </strong>
                  In the event of a merger, acquisition, or sale of all or part of our business, your information may be transferred as part of the transaction, subject to confidentiality and security safeguards.
                   </li>
                </ul>
                </p>

                <p>
                <h5>4. Your Rights and Choices:</h5>

                <ul>
                  <li>
                  <strong>4.1. Account Settings:    </strong>   
                  You can access and update your account information through your account settings.
                   </li>
                   <li>
                  <strong>4.2. Marketing Communications:    </strong>    
                  You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in the messages.
                   </li>
                   <li>
                  <strong>4.3. Cookies:  </strong>
                  You can manage your cookie preferences through your browser settings.
                   </li>
                </ul>
                </p>

                <p>
                <h5>5. Data Security:</h5>

                <ul>
                  <li>
                  <strong>5.1.   </strong>   
                  We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, misuse, alteration, or destruction.
                   </li>
                   <li>
                  <strong>5.2.   </strong>    
                  While we strive to protect your data, no transmission over the internet or electronic storage method is 100% secure. Therefore, we cannot guarantee its absolute security.
                   </li>
                </ul>
                </p>

                <p>
                <h5>6. Children's Privacy:  </h5>

                <ul>
                  <li>
                  <strong>6.1.    </strong>   
                  Our Services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that a child has provided us with personal information, please contact us, and we will take appropriate steps to delete such information.
                   </li>
                </ul>
                </p>

                <p>
                <h5>7. Changes to this Privacy Policy:</h5>

                <ul>
                  <li>
                  <strong>7.1.   </strong>   
                  We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated policy on our website or through other communication methods.
                   </li>
                </ul>
                </p>

                <p>
                <h5>8. Contact Us:   </h5>

                <ul>
                  <li>
                  <strong>8.1.   </strong>   
                  If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                  <br/>
                  <br/>
                  Email : support@polarizenetwork.com
                  <br/>
                  Call : 1800-270-4679 
                   <br/>
                   <br/>
                  Please note that this Privacy Policy applies solely to the information collected by Polarize Crypto Exchange and does not cover the practices of third-party websites or services that we do not control. We encourage you to review the privacy policies of such third-party platforms.


                   </li>
                </ul>
                </p>







              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicyPage;