import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { io } from "socket.io-client";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import moment from "moment";
import { useEditProfile } from "../../../context/EditProfileContext";
import StakingDetails from "../StakingDetails";

const StakingPage = () => {
  useEffect(() => {
    handleStaking();
  }, []);

  const navigate = useNavigate()
  const { setRewardRate, setDuration} = useEditProfile()

  const [stakingHistory, setStakingHistory] = useState([]);
  const [AllData, setAllData] = useState();

  useEffect(() => {
    let socket = io(ApiConfig?.webSocketUrl, {
      transports: ["websocket"],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true,
    });
    socket.on("connect", () => {
      console.log("Market Socket Connected");
      socket.emit("message", "market");
    });
    socket.on("message", (data) => {
      console.log(data, "market data");
      setAllData(data.pairs[0]?.buy_price);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleStaking = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getStakingHistory().then(async (result) => {
      console.log(result);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setStakingHistory(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    //
    //

    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Staking</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Staking
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container">
          <div class="tab-content custom-tab-content p-0">
            <div
              class="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds"
            >
              <div class="table-responsive">
                {stakingHistory.length === 0 ? (
                  <div className="favouriteData">
                    <img
                      src="images/no-data.svg"
                      className="img-fluid"
                      width="96"
                      height="96"
                      alt=""
                    />
                    <br />
                    <p className="mt-3 mb-4"> No Data Found. </p>
                  </div>
                ) : (
                  <table class="table  ">
                    <thead className="border-bottom"  >
                      <tr>
                        <th>Sr No.</th>
                        <th> Staked Date</th>
                        <th>Staked PLR</th>
                        <th>Amount</th>
                        <th>Reward Rate</th>
                        <th>Duration</th>
                        <th>Module</th>
                      </tr>
                    </thead>
                    {stakingHistory.length > 0 ? (
                      stakingHistory.map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                            onClick={() =>
                              {sessionStorage.setItem("duration", value?.month_time); sessionStorage.setItem("stakingId", value?._id); navigate("/stakingDetails")}
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td>

                            <td>{value?.stacking_price}</td>
                            <td>$ {value?.usdtAmount}</td>
                            <td>{value?.month_percentage}%</td>
                            <td>{value?.month_time} Year</td>
                            <td>{value?.phase_name}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="6">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>

          



        </div>
      </section>

{/* <StakingDetails rewardRate={rewardRate}/> */}
      {/* Modal */}
      <div
            class="modal fade"
            id="detail_modal"
            tabindex="-1"
            aria-labelledby="detail_modal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" >
                <div class="modal-header flex-column p-0 no-border">
                  <button
                    type="button"
                    class="btn-custom-closer"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="ri-close-fill"></i>
                  </button>
                </div>
                <div class="modal-body p-0">
                <table class="table mb-0 ">
                  <thead >
                    <tr>
                      <th>Sr No.</th>
                      <th>Reward Return History</th>
                      <th>Harvest</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#detail_modal">
                      <td>1</td>
                      {/* <td>{rewardRate}%</td> */}
                      <td>
                        <button  className="btn btn-sm btn-gradient" >Harvest</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*  */}
                {/* <>
                    <div class="tt_item ">
                      <span class="tt_disable">Coin</span>
                     <span class="tt_normal">
                        <b> {transferData?.short_name} </b>
                      </span> 
                    </div>
                      
                    <div class="tt_item ">
                    <span class="tt_disable">Status</span>
                    
                  </div>
                  <div class="tt_data">
                    <div class="tt_item">
                      <span class="tt_disable">Transaction Type</span>
                      <span class="tt_normal">
                         <b>{transferData?.transaction_type}</b> 
                      </span>
                    </div>
                    <div class="tt_item ">
                      <span class="tt_disable">Fee</span>
                      <span class="tt_normal">
                         <b>{parseFloat(transferData?.fee).toFixed(5)}</b> 
                      </span>
                    </div>
                    <div class="tt_item">
                      <span class="tt_disable">Amount</span>
                      <span class="tt_normal">
                         <b>{transferData?.amount}</b> 
                      </span>
                    </div>
                    <div class="tt_item ">
                      <span class="tt_disable">From Address</span>
                      <span class="tt_normal">
                         <b>{transferData?.from_address}</b> 
                      </span>
                    </div>
                    <div class="tt_item ">
                      <span class="tt_disable">To Address</span>
                      <span class="tt_normal">
                       <b> {transferData?.to_address} </b> 
                      </span>
                    </div>
                    <div class="tt_item ">
                      <span class="tt_disable">Chain</span>
                      <span class="tt_normal">
                         <b> {transferData?.chain} </b> 
                      </span>
                    </div>
                    
                  </div>
                  </> */}

                </div>
              </div>
            </div>
          </div>



    </>
  );
};

export default StakingPage;
