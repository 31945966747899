import { createContext, useContext, useState, useEffect } from "react";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";

const EditProfileContext = createContext();

const EditProfileProvider = ({ children }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [getSelfy, setGetSelfy] = useState([]);
  const [editProfile, setEditProfile] = useState(true);
  const [rewardRate,setRewardRate] = useState()
  const [duration,setDuration] = useState()

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      handleDetials();
    }
  }, []);

  const handleDetials = async () => {
    // LoaderHelper.loaderStatus(true);
    await AuthService.getDetails().then(async (result) => {
      if (result.sucess) {
        //LoaderHelper.loaderStatus(false);
        try {
          // setEmailId(result?.data[0]?.logindata[0]?.emailId);

          setFirstName(result?.data?.firstName);

          setLastName(result?.data?.lastName);
          setGetSelfy(result?.data?.profilepicture);
          localStorage.setItem("selfie", result?.data?.profilepicture);
        } catch (error) {
          // alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        // LoaderHelper.loaderStatus(false);
        console.log(result?.message);
      }
    });
  };

  return (
    <EditProfileContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        emailId,
        setEmailId,
        getSelfy,
        setGetSelfy,
        editProfile,
        setEditProfile,
        rewardRate,setRewardRate, duration,setDuration
      }}
    >
      {children}
    </EditProfileContext.Provider>
  );
};
const useEditProfile = () => useContext(EditProfileContext);

export { EditProfileProvider, useEditProfile };
