import Swal from "sweetalert2";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  toast.error(message ? message.toUpperCase() :'Error', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    closeButton: false,
  });

}

function alertSuccessMessage(message) {
  toast.success(message ? message.toUpperCase() :'Success', {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    closeButton: false

  });


}
function alertWarningMessage(message) {
  toast.info(message ? message.toUpperCase() :'Oops', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  })};

  function alertMessage(message) {

    // Swal.fire('', message,);
    Swal.fire({
      title: 'We are under maintenance.',
      text: 'STAY TUNED!',
      // icon: 'warning',
      // showCancelButton: true,
      // confirmButtonColor: '#00c100',
      confirmButtonColor : '#121212',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'Let`s Explore'
    })
  }
  



export { alertErrorMessage, alertSuccessMessage, alertWarningMessage, alertMessage };