import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";

const NotificationPage = () => {

const id = sessionStorage.getItem("userId")

  useEffect(() => {
    handleNotifications();
    handleNotificationStatus(id)
  }, []);

  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getNotificationList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data.length > 0) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleNotificationStatus = async (id) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notificationStatus(id).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data.length > 0) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    //
    //

    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Notifications</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Notifications
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container">
          <div class="tab-content custom-tab-content p-0">
            <div
              class="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds"
            >
              <div class="table-responsive">
                {notifications.length === 0 ? (
                  <div className="favouriteData">
                    <img
                      src="images/no-data.svg"
                      className="img-fluid"
                      width="96"
                      height="96"
                      alt=""
                    />
                    <br />
                    <p className="mt-3 mb-4"> No Data Found. </p>
                  </div>
                ) : (
                  <table class="table ">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    {notifications.length > 0 ? (
                      notifications.map((value, index) => (
                        <tbody>
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td>

                            <td>{value?.title}</td>
                            <td>{value?.message}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="6">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotificationPage;
