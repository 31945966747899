import React from "react";

const AmlPolicy = () => {
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">AML (ANTI MONEY LAUNDERING) POLICY </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Aml Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="create-item-wrapper plicy_sec">
                <p>
                  The act of money laundering involves disguising illicit funds
                  by converting them into cash or other seemingly legitimate
                  investments. At Polarize Exchange, we are deeply committed to
                  providing our customers with exceptional customer service by
                  prioritizing account security. To combat any form of money
                  laundering, we have implemented a Know Your Customer (KYC)
                  policy. Our Anti-Money Laundering Policy (referred to as the
                  “AML Policy”) outlines the procedures and mechanisms
                  established by Polarize Exchange to prevent money laundering.
                </p>

                <p>
                  The primary objective of our AML Policy is to assess the risk
                  associated with our customers, transactions, and services in
                  order to minimize potential harm. Through a risk-focused
                  approach, we educate our employees on detecting crime revenues
                  and terrorist financing. We also provide information to our
                  customers regarding our adherence to applicable regulations,
                  particularly the Law on the Prevention of Laundering Crime
                  Revenues no. 5549 and the Law on Prevention of Financing
                  Terrorism.
                </p>

                <p>
                  Our AML Policy applies to real individuals and/or legal
                  entities who are members of our platform at
                  www.polarize.exchange , who benefit from our services, and who
                  agree to the terms of our policy. Polarize Exchange has
                  developed our AML Policy in accordance with international
                  legal norms and standards, as well as applicable legislation.
                  We have implemented measures such as verifying the identities
                  of all customers, monitoring customer transactions with a
                  risk-based approach, reporting any suspicious transactions to
                  relevant institutions and authorities as required by
                  legislation, recording such transactions. We coordinate the
                  implementation and feasibility of our AML Policy throughout
                  our company to ensure the protection of our customers and the
                  integrity of our business operations.
                </p>

                <h5>
                  I. Polarize Exchange has put in place the following policies
                  to ensure compliance:
                </h5>
                <p>
                  We do not engage in any business relationships with
                  individuals or groups involved in criminal and/or terrorist
                  activities.
                  <br />
                  <br />
                  We do not process any transactions that originate from
                  criminal or terrorist activities.
                  <br />
                  <br />
                  We do not assist or facilitate any procedures related to
                  criminal or terrorist activities.
                </p>

                <h5>II. Risk</h5>
                <p>
                  Polarize Exchange employs a risk-based approach to combat
                  money laundering and terrorism financing that complies with
                  national and international standards. As a result, we
                  implement measures to prevent money laundering and terrorism
                  financing that are commensurate with the identified risks,
                  allowing for efficient allocation of resources. We prioritize
                  the allocation of resources towards higher-risk areas.
                  <br />
                  <br />
                  In order to prevent money laundering and terrorism financing,
                  Polarize Exchange conducts risk analysis using various methods
                  to monitor the financial activities of our customers. This
                  enables us to monitor our customers based on the information
                  obtained from the risk analysis. Our risk-based approach
                  ensures that we remain vigilant in our efforts to prevent
                  money laundering and terrorism financing.
                </p>

                <h5>
                  III. The following customers and transactions fall under the
                  high-risk category:
                </h5>
                <ol>
                  <li>
                    • When the total value of a single cryptocurrency or the
                    total value of multiple interconnected transactions equals
                    or exceeds USD 100,000.00.
                  </li>

                  <li>
                    {" "}
                    • When the total value of a single cryptocurrency exchange
                    or the total value of multiple interconnected transactions
                    equals or exceeds USD 100,000.00.
                  </li>

                  <li>
                    {" "}
                    • When transactions are suspected of being in violation of
                    current legislation and require notification.
                  </li>

                  <li>
                    {" "}
                    • When there is any doubt regarding the accuracy or adequacy
                    of previously obtained identity information.
                  </li>

                  <li>
                    {" "}
                    • When complex transactions are involved, which have the
                    potential to conceal third-party beneficiaries.
                  </li>

                  <li>
                    • When there is any doubt regarding the accuracy or adequacy
                    of previously obtained identity information.
                  </li>

                  <li> • When monetary funds cannot be easily verified.</li>

                  <li>
                    {" "}
                    • When unusual transactions with unclear economic or
                    legitimate purposes occur.
                  </li>
                </ol>

                <h5>IV. Transaction </h5>
                <p>
                  Transaction monitoring and analysis of customer data are
                  crucial for assessing risks and detecting suspicious
                  transactions. If Polarize Exchange suspects any money
                  laundering activity, we reserve the right to monitor all
                  transactions, including those involving customers and
                  transactions in the high-risk group, complex and unusual
                  transactions, transactions with high-risk countries,
                  customers' documents and information, and mandatory
                  information about the sale and purchase of cryptocurrencies.
                  We also monitor whether the transactions are consistent with
                  the relevant information.
                  <br />
                  <br />
                  If suspicious activity is detected, we may take the following
                  actions:
                  <ul>
                    <li>
                      ● Report the suspicious transactions to the appropriate
                      law enforcement agencies.
                    </li>
                    <li>
                      ● Request additional information and documents from the
                      customer.
                    </li>
                    <li>● Suspend or close the customer's account.</li>
                  </ul>
                  This list is not exhaustive, as our AML Policy Compliance
                  Officer monitors customer transactions daily and decides
                  whether to report and deem them suspicious.
                </p>

                <h5>V. Verification</h5>
                <p>
                  The Verification Procedure at Polarize Exchange is designed to
                  comply with the company's own standards for combatting money
                  laundering and ensuring adherence to the KYC policy.
                  <br />
                  <br />
                  To complete the verification procedure, customers are required
                  to provide identity documents approved by official
                  authorities, such as a passport or identity card.Polarize
                  Exchange reserves the right to collect customer information
                  for AML purposes and ensures that such information is
                  processed and stored securely in accordance with the Polarize
                  Exchange Confidentiality Policy.
                  <br />
                  <br />
                  In some cases, Polarize Exchange may request a second form of
                  Customer ID, such as a bank receipt or utility bill not older
                  than three months, which must include the customer's full name
                  and current address.
                  <br />
                  <br />
                  Customers deemed as high-risk or suspicious may be required to
                  provide additional information even after their initial
                  verification documents have been submitted and verified.
                  <br />
                  <br />
                  If a customer's identity information is found to be altered or
                  their actions are deemed suspicious, Polarize Exchange
                  reserves the right to request updated documents, regardless of
                  whether their previous identity has been verified in the past
                  <br />
                  <br />
                  If Polarize Exchange suspects any instances of money
                  laundering or financing of terrorism related to its services,
                  all transactions considered suspicious after thorough
                  inspections are reported to the Financial Crimes Investigation
                  Board, regardless of the transaction amount. Those individuals
                  who performed the suspicious transaction and their
                  representatives, executives, and employees who fail to report
                  such transactions will be subject to various legal,
                  administrative, and criminal sanctions.
                  <br />
                  <br />
                  The Anti-Money Laundering Officer at Polarize Exchange is
                  accountable for upholding the AML Policy, including:
                  <ul>
                    <li>- Gathering customer identity information,</li>
                    <li>
                      - Developing and revising all internal policies and
                      procedures for producing, reviewing, presenting, and
                      storing all mandatory reports according to current laws
                      and regulations,
                    </li>
                    <li>
                      - Monitoring and evaluating significant variances
                      resulting from customers' exceptional activities,
                    </li>
                    <li>
                      - Establishing a record keeping system for storing and
                      retrieving documents, files, forms, and session logs,
                    </li>
                    <li>
                      - Periodically reviewing and updating risk assessments.
                    </li>
                  </ul>
                </p>

                <h5>VI. Updates </h5>
                <p>
                  Polarize Exchange fulfills its obligation to provide staff
                  training on policies and procedures in accordance with
                  applicable legislation. The company mainly focuses on
                  providing training related to the Procedure for Preventing
                  Money Laundering, and ensures that this information is updated
                  regularly.
                  <br />
                  <br />
                  The company conducts periodic audits to ensure that its
                  policies and procedures related to directives and
                  notifications on the “Law Against Money Laundering and
                  Financing of Terrorism” are compliant with applicable
                  legislation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmlPolicy;
