import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { io } from "socket.io-client";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const MarketPage = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [AllData, setAllData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [categoryList, setcategoryList] = useState();
  const [activeTab, setActiveTab] = useState("ALL");
  const [activeCategory, setActiveCategory] = useState("All");
  const [search, setsearch] = useState("");
  const [favCoins, setfavCoins] = useState([]);
  const [hotPairs, sethotPairs] = useState();
  const [newListedPair, setnewListedPair] = useState();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
      transports: ['websocket'],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true
    });
  
    setSocket(newSocket);
    let payload={
        'message':'market',
    }
    newSocket.emit('message', payload);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('message', (data) => {
        console.log(data, "market data");
        setAllData(data.pairs);
        setCoinData(data.pairs);
        sethotPairs(data.hot.slice(0, 3));
        setnewListedPair(data.new_listed.slice(0, 3));
      });
    }
  }, [socket]);

  console.log(hotPairs, "hot")

  useEffect(() => {
    if(token){
      CategoryList();
      favoriteList();
    }
 
  }, []);

  const nextPage = (data) => {
    navigate("/trade", { state: data });
  };

  const CategoryList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.categoryList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setcategoryList(result.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  const handleAddFav = async (pairId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.favoriteCoin(pairId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          favoriteList();
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const favoriteList = async () => {
    await AuthService.favoriteList().then((result) => {
      if (result.success) {
        try {
          setfavCoins(result?.data?.pairs ? result?.data?.pairs : [""]);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  useEffect(() => {
    let filteredData = AllData?.filter((item) => {
      return (
        item?.base_currency?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.quote_currency?.toLowerCase().includes(search?.toLowerCase())
      );
    });
    setCoinData(filteredData);
  }, [search, AllData]);

  return (
    <>
      <section className="inner-page-banner pb-2">
        <div className="container">
          <div className="inner text-start p-0">
            <h1 className="title">Market </h1>
          </div>
        </div>
      </section>
      <section className=" live_prices mt-0 market_prices">
        <div className="container">
          <div className="row mb-4 gx-2">
            {/* <div className="col-lg-6">
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR"> */}
            {/* <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <span className="rankItem_titleWrap__6tzjz">
                      <span>Hot</span>
                    </span>
                  </div>
                </div> */}
            {/* <div className="ant-card-body">
                  {hotPairs &&
                    hotPairs.map((data) => {
                      return (
                        <a href="#/" className="ant-list row gx-0">
                          <div className=" col-4">
                            <div className="spotName">
                              <img
                                alt=""
                                src={ApiConfig?.appUrl + data?.icon_path}
                                width="20"
                                height="20"
                              />
                              <div className="symblecurrency">
                                {data?.base_currency}/{data?.quote_currency}
                              </div>
                            </div>
                          </div>
                          <div className="col-5 text-end">
                            <span>{data?.buy_price?.toFixed(8)}</span>
                          </div>
                          <div className="col-3 text-end">
                            <span
                              className={
                                data?.change >= 0
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {data?.change?.toFixed(5)}%
                            </span>
                          </div>
                        </a>
                      );
                    })}
                </div> */}
            {/* </div>
            </div> */}
            {/* <div className="col-lg-4" >
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <span className="rankItem_titleWrap__6tzjz"><span>Top Futures</span></span>
                  </div>
                </div>
                <div className="ant-card-body">
                  <a href="#/" className="ant-list row gx-0">
                    <div className=" col-4" >
                      <div className="spotName" >
                        <img alt="" src="https://api.taxbits.io/uploads/icon/ftm.png" width="20" height="20" />
                        <div className="symblecurrency">MX/USDT<small>Perpetual</small></div>
                      </div>
                    </div>
                    <div className="col-5 text-end" ><span>17861.583107</span></div>
                    <div className="col-3 text-end" >
                      <span className="text-danger">-0.78%</span>
                    </div>
                  </a>
                  <a href="#/" className="ant-list row gx-0">
                    <div className=" col-4" >
                      <div className="spotName" >
                        <img alt="" src="https://api.taxbits.io/uploads/icon/ftm.png" width="20" height="20" />
                        <div className="symblecurrency">
                          MX/USDT
                          <small>Perpetual</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 text-end" >
                      <span>17861.583107</span>
                    </div>
                    <div className="col-3 text-end" >
                      <span className="text-danger">-0.78%</span>
                    </div>
                  </a>
                  <a href="#/" className="ant-list row gx-0">
                    <div className=" col-4" >
                      <div className="spotName" >
                        <img alt="" src="https://api.taxbits.io/uploads/icon/ftm.png" width="20" height="20" />
                        <div className="symblecurrency">
                          MX/USDT
                          <small>Perpetual</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 text-end" >
                      <span>17861.583107</span>
                    </div>
                    <div className="col-3 text-end" >
                      <span className="text-danger">-0.78%</span>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <span className="rankItem_titleWrap__6tzjz">
                      <span>Newest</span>
                    </span>
                  </div>
                </div>
                <div className="ant-card-body">
                  {newListedPair &&
                    newListedPair?.map((data) => {
                      return (
                        <a href="#/" className="ant-list row gx-0">
                          <div className=" col-4">
                            <div className="spotName">
                              <img
                                alt=""
                                src={ApiConfig?.appUrl + data?.icon_path}
                                width="20"
                                height="20"
                              />
                              <div className="symblecurrency">
                                {data?.base_currency}/{data?.quote_currency}
                              </div>
                            </div>
                          </div>
                          <div className="col-5 text-end">
                            <span>{data?.buy_price?.toFixed(8)}</span>
                          </div>
                          <div className="col-3 text-end">
                            <span
                              className={
                                data?.change >= 0
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {data?.change?.toFixed(5)}%
                            </span>
                          </div>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs">
              <li>
                <a data-bs-toggle="tab" href="#tt_history">
                  Favorite
                </a>
              </li>
              <li>
                <a className="active" data-bs-toggle="tab" href="#funds">
                  Spot
                </a>
              </li>
            </ul>
            <div className="searchBar custom-tabs">
              <i className="ri-search-2-line"></i>
              <input
                type="search"
                className="custom_search"
                placeholder="Search Crypto"
                value={search}
                onChange={(e) => {
                  setsearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane container active show fade" id="funds">
              <div className="mrt_row">
                <div
                  className="btn-group btn_radio_group "
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <button
                    onClick={() => setActiveTab("ALL")}
                    type="button"
                    className={`btn  btn-outline-primary ${
                      activeTab === "ALL" && "active"
                    }`}
                    for="ALL"
                  >
                    ALL
                  </button>
                  <button
                    onClick={() => setActiveTab("USDT")}
                    type="button"
                    className={`btn  btn-outline-primary ${
                      activeTab === "USDT" && "active"
                    }`}
                    for="USDT"
                  >
                    USDT
                  </button>
                 
                  <button
                    onClick={() => setActiveTab("INR")}
                    type="button"
                    className={`btn  btn-outline-primary ${
                      activeTab === "INR" && "active"
                    }`}
                    for="INR"
                  >
                    INR
                  </button>
                  <button
                    onClick={() => setActiveTab("PLR")}
                    type="button"
                    className={`btn  btn-outline-primary ${
                      activeTab === "PLR" && "active"
                    }`}
                    for="PLR"
                  >
                    PLR
                  </button>
                 
                </div>
                <div className="scroll-subtabs">
                  {/* <a
                    href="#/"
                    className={`subtabs_link ${
                      activeCategory === "All" && "active"
                    }`}
                    onClick={() => setActiveCategory("All")}
                  >
                    All
                  </a> */}
                  {/* {categoryList &&
                    categoryList?.map((data) => {
                      return (
                        <>
                          <a
                            href="#/"
                            className={`subtabs_link ${
                              activeCategory === data?.category && "active"
                            }`}
                            onClick={() => setActiveCategory(data?.category)}
                          >
                            {data?.category}
                          </a>
                        </>
                      );
                    })} */}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th> Pair</th>
                      <th> Price</th>
                      <th> 24H Change</th>
                      <th> 24H High</th>
                      <th> 24H Low</th>
                      <th> 24H Vol</th>
                      <th> 24H Turnover</th>
                      <th> Operation</th>
                    </tr>
                  </thead>
                  <tbody style={{fontSize:"12.68px"}}>
                    {coinData
                      ? coinData.map(
                          (item, key) =>
                            (activeTab === item?.quote_currency ||
                              activeTab === "ALL") &&
                            (activeCategory === item?.category ||
                              activeCategory === "All") && (
                              <tr>
                                <td>
                                  <div className="td_div">
                                    {token && (
                                      <span
                                        className={"star_btn btn_icon active"}
                                      >
                                        <i
                                          class={
                                            favCoins.includes(
                                              item?.base_currency_id
                                            )
                                              ? "ri ri-star-fill text-warning me-2 "
                                              : "ri ri-star-line me-2 "
                                          }
                                          onClick={() => {
                                            handleAddFav(
                                              item?.base_currency_id
                                            );
                                          }}
                                        >
                                          {" "}
                                        </i>
                                      </span>
                                    )}
                                    <img
                                      alt=""
                                      src={ApiConfig.appUrl + item?.icon_path}
                                      className="img-fluid icon_img coinimg me-2 "
                                    />
                                    {item?.base_currency}/{item?.quote_currency}
                                  </div>
                                </td>
                                <td>
                                  <b>{item?.buy_price?.toFixed(7)}</b>
                                </td>
                                <td
                                  className={ 
                                    item?.change >= 0
                                      ? "color-green text-success"
                                      : "color-red text-danger" 
                                  }
                                >
                                  <b>{item?.change?.toFixed(7)}</b>
                                </td>
                                <td>
                                  <b>{item?.high?.toFixed(7)}</b>
                                </td>
                                <td>
                                  <b>{item?.low?.toFixed(7)}</b>
                                </td>
                                <td>
                                  <b>{item?.volume?.toFixed(7)}</b>
                                </td>
                                <td>
                                  <b>
                                    {(item?.buy_price * item?.volume)?.toFixed(
                                      7
                                    )}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href="#/"
                                    onClick={() => nextPage(item)}
                                    className="btn btn-gradient btn-sm"
                                  >
                                    <span>Trade</span>
                                  </a>
                                </td>
                              </tr>
                            )
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane container fade" id="tt_history">
              <div>
                {token ? (
                  <table className="table ">
                    <thead>
                      <tr>
                        <th> Pair</th>
                        <th> Price</th>
                        <th> 24H Change</th>
                        <th> 24H High</th>
                        <th> 24H Low</th>
                        <th> 24H Vol</th>
                        <th> 24H Turnover</th>
                        <th> Operation</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:"13.7px"}}>
                      {coinData
                        ? coinData.map(
                            (item, key) =>
                              favCoins.includes(item?.base_currency_id) && (
                                <tr>
                                  <td>
                                    <div className="td_div">
                                      <span
                                        className={"star_btn btn_icon active"}
                                      >
                                        <i
                                          className="ri-star-fill"
                                          onClick={() => {
                                            handleAddFav(
                                              item?.base_currency_id
                                            );
                                          }}
                                        ></i>
                                      </span>
                                      <img
                                        alt=""
                                        src={
                                          ApiConfig.baseUrl + item?.icon_path
                                        }
                                        className="img-fluid icon_img coinimg me-2 "
                                      />
                                      {item?.base_currency}/
                                      {item?.quote_currency}
                                    </div>
                                  </td>
                                  <td>
                                    <b>{item?.buy_price?.toFixed(7)}</b>
                                  </td>
                                  <td
                                    className={
                                      item?.change >= 0
                                        ? "color-green text-success"
                                        : "color-red text-danger"
                                    }
                                  >
                                    <b>{item?.change?.toFixed(7)}</b>
                                  </td>
                                  <td>
                                    <b>{item?.high?.toFixed(7)}</b>
                                  </td>
                                  <td>
                                    <b>{item?.low?.toFixed(7)}</b>
                                  </td>
                                  <td>
                                    <b>{item?.volume?.toFixed(7)}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {(
                                        item?.buy_price * item?.volume
                                      )?.toFixed(7)}
                                    </b>
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => nextPage(item)}
                                      className="btn btn-gradient btn-sm"
                                    >
                                      <span>Trade</span>
                                    </a>
                                  </td>
                                </tr>
                              )
                          )
                        : null}
                    </tbody>
                  </table>
                ) : (
                  <div className="favouriteData">
                    <img
                      src="images/no-data.svg"
                      className="img-fluid"
                      width="96"
                      height="96"
                      alt=""
                    />
                    <br />
                    <p className="mt-3 mb-4">
                      {" "}
                      No results.... Go to{" "}
                      <Link className="btn-link" to="/login">
                        Sign in
                      </Link>{" "}
                      and add your favorite coins from Spot.{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketPage;
