import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Col, Row } from "react-bootstrap";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import HomeService from "../../../api/services/HomeService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import { io } from "socket.io-client";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { $ } from "react-jquery-plugin";

const FundPage = () => {
  const [fundData, setfundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState([]);
  const [transactions, setTransactions] = useState();
  const [amount, setAmount] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [fees, setFees] = useState("");
  const [transactionType, setTransactiontype] = useState("");
  const [transID, setTransID] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [amount_val, setAmount_avl] = useState("");
  const [wallet_Add, setwallet_Add] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [amountVal, setAmountVal] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tdsAmount, setTdsAMount] = useState("");
  const [localInrImage, setLocalInrImage] = useState("");
  const [transVal, setTransVal] = useState("");
  const [fee, setFee] = useState("");
  const [status, setStatus] = useState("");
  const [inrImage, setInrImage] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [transferHistory, setTransferHistory] = useState([])
  const [transactionHistory, setTransactionHistory] = useState([])
  const [transferData, setTransferData] = useState()
  const [coinName, setCoinName] = useState("")
  const [bankDetails, setBankDetails] = useState([]);
  const [percentage, setPercentage] = useState("");
  const [stakeAmount, setStakeAmount] = useState();
  const [time, setTime] = useState();
  const [stakePhase, setStakePhase] = useState()
  const [currencyId, setCurrencyId] = useState()
  const [AllData, setAllData] = useState([])
  const [tradeHisDetails, settradeHisDetails] = useState();
  const [hide, setHide] = useState(false)
  const [hideDuration, setHideDuration] = useState(false)
  const [stacking_price, setstacking_price] = useState()
  const [socket, setSocket] = useState(null);
  const [referralCode, setReferralCode] = useState()
  const [packageList,setPackageList] = useState([])
  const [duration,setDuration] = useState([])
  const [finalFundData,setFinalFundData] = useState([])
  const [isEmptyWallet,setIsEmptyWallet] = useState(false);
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);



  const userId = sessionStorage.getItem("userId");


  useEffect(() => {
   
    if(isEmptyWallet ) {
      let emptyWalletData = fundData?.filter((item) => (
        item?.balance != 0))
        setFinalFundData (emptyWalletData);
    }  else{
      setFinalFundData(fundData)
    }  

  }, [isEmptyWallet, fundData] );

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

 const handlePackageList = async () => {
   LoaderHelper.loaderStatus(true);
   await AuthService.packageList().then(async (result) => {
    console.log(result,"pppp")
     if (result.success) {
       LoaderHelper.loaderStatus(false);
       try {
         setPackageList(result.data[0]);
       } catch (error) {
         alertErrorMessage(error);
       }
     } else {
       LoaderHelper.loaderStatus(false);
       alertErrorMessage(result.message);
     }
   });
 };


 console.log(stakePhase, "sp");

 const handleGetDuration = async (phase) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.durations(phase).then(async (result) => {
    if (result.success) {
      LoaderHelper.loaderStatus(false);
      try {
        setDuration(result?.data);
      } catch (error) {
        alertErrorMessage(error);
      }
    } else {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(result.message);
    }
  });
};


 const handleGetPercentage = async (stakePhase, time, usdtAmount) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.getPercentage(stakePhase, time, usdtAmount).then((result) => {
    if (result.success) {
      LoaderHelper.loaderStatus(false);
      try {
        setPercentage(result?.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(result.message);
    }
  });
};




 const timeoutRef = useRef(null);
const handleInputAmount = (e) => {
  setStakeAmount(e.target.value);
  setstacking_price((e.target.value) * AllData)
  clearTimeout(timeoutRef.current);
  timeoutRef.current = setTimeout(() => {
    handleGetPercentage(stakePhase, time, e.target.value * AllData);
  }, 1000);
};

const timeoutRef2 = useRef(null);
  const handleStakephase = (e) => {
    setStakePhase(e.target.value)
    clearTimeout(timeoutRef2.current);
    timeoutRef2.current = setTimeout(() => {
      handleGetDuration(e.target.value);
    }, 700);
  };
 
console.log(stakeAmount,"aaaaa");

  useEffect(() => {
    const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
      transports: ['websocket'],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true
    });

    setSocket(newSocket);
    let payload = {
      'message': 'market',
    }
    newSocket.emit('message', payload);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('message', (data) => {
        let filteredData = data?.pairs?.filter((item) => {
          return item?.base_currency === 'PLR' && item?.quote_currency === "USDT"
        })
        setAllData(filteredData[0]?.buy_price);
      });
    }
  }, [socket]);


  console.log(AllData, "all");

  useEffect(() => {
    getUserfunds();
    estimatedPortfolio();
    TradeHistory();
    TransferHistory()
    verifyDeposit()
    handleBankAcc()
    TransactionHistory()
  }, []);

  function myFunction(value, index, array) {
  return value.currency === "Polarize Network";
}
  function myFunction1(value, index, array) {
  return value.currency === "Indian Rupee";
}
  function myFunction2(value, index, array) {
  return value.currency === "Tether";
}
  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds(orderId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          const rand = result.data;
          const idx = rand.findIndex(myFunction);
          const idx1 = rand.findIndex(myFunction1);
          const idx2 = rand.findIndex(myFunction2);
          console.log(idx)
          const plr = rand[idx];
          const inr = rand[idx1];
          const usdt = rand[idx2];
          delete rand[idx];
          delete rand[idx1];
          delete rand[idx2];
          rand.unshift(inr);
          rand.unshift(usdt);
          rand.unshift(plr);
          console.log(rand)
          setfundData(result?.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };


  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectDeposit("");
    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setSelectDeposit(result?.data);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      }
      return;
    });
  };

  // ********* Estimated Portfolio********** //
  const estimatedPortfolio = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.estimatedPortfolio().then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setEstimatedportfolio(result?.data);
        } catch (error) {
          alertErrorMessage(result.message)
        }
      }
      return;
    });
  };

  // ********* Trade History ********** //
  const TradeHistory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradeHistory().then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          settradeHistory(result?.data);
        } catch (error) {
          console.log(error);
        }
      }
      return;
    });
  };

  // ********* Transaction History ********** //
  const TransactionHistory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.transactionHistory().then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setTransactionHistory(result?.data);
        } catch (error) {
          console.log(error);
        }
      }
      return;
    });
  };

  // ********* Transfer History ********** //
  const TransferHistory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.transferHistory().then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setTransferHistory(result?.data);
        } catch (error) {
          console.log(error);
        }
      }
      return;
    });
  };

  console.log(transferHistory, "status")

  // ********* Coin Min Deposit Data ********** //
  const coinDetails = async (currId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.coinDetails(currId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setminDeposit(result?.data?.min_deposit);
          setFee(result?.data?.withdrawal_fee)
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleWithdrawCoin = async (
    wallet_Add, amount_val, coinName, otp
  ) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalAmount(
      wallet_Add,
      amount_val,
      coinName,
      otp
    ).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          setwallet_Add("");
          setAmount_avl("");
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const verifyDeposit = async () => {
    // LoaderHelper.loaderStatus(true);
    await AuthService.getVerifyDeposit().then((result) => {
      if (result.success) {
        // LoaderHelper.loaderStatus(false);
        try {
          const interval = setInterval(() => {
            verifyDeposit();
          }, 300000);
          return () => clearInterval(interval);
        } catch (error) {
        }
      } else {
        // LoaderHelper.loaderStatus(false);
      }
    });
  };

  const handleBankAcc = async (user_Id) => {
    console.log("hellooo");
    await HomeService.getAccDetails().then(async (result) => {
      if (result.success) {
        try {
          setBankDetails(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  console.log(stakePhase, "phase")
  console.log(time, "time");

  const handleDepositCoinInr = async (amount_val, transVal, localInrImage) => {
    var formData = new FormData();
    formData.append("amount", amount_val);
    formData.append("deposit_slip", localInrImage);
    formData.append("transaction_number", transVal);
    LoaderHelper.loaderStatus(true);
    await HomeService.depositAmountInr(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          $("#Deposit_modal").modal("hide");
          setAmountVal("");
          setTransVal("");
          setInrImage("")
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleWithdrawCoinInr = async (withdrawAmount, otp) => {
    LoaderHelper.loaderStatus(true);
    await HomeService.withdrawlAmountInr(withdrawAmount, otp).then(
      async (result) => {
        if (result.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage(result.message);
            setWithdrawAmount("");
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleChangeInrImage = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    setInrImage(event.target.files[0].name);
    const imgata = URL.createObjectURL(fileUploaded);
    setLocalInrImage(fileUploaded);
  };

  const handleInputSellChange = (event) => {
    switch (event.target.name) {
      case "amountVal":
        setAmountVal(event.target.value);
        break;
      case "withdrawAmount":
        setWithdrawAmount(event.target.value);
        break;
      case "wallet_Add":
        setwallet_Add(event.target.value);
        break;
      case "amount_val":
        setAmount_avl(event.target.value);
        break;
      case "transVal":
        setTransVal(event.target.value);
        break;
      case "inrImage":
        setInrImage(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleStaking = async (userId, stakePhase, stakeAmount, time, percentage, currencyId, stacking_price, referralCode) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getStaking(userId, stakePhase, stakeAmount, time, percentage, currencyId, stacking_price, referralCode).then(
      async (result) => {
        if (result.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage("Staking Placed Successfully");
            $("#Stack_modal").modal("hide");
            setTime("");
            setStakeAmount("");
            setPercentage("");
            getUserfunds();
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleGetCode2 = async () => {
    const token = sessionStorage.getItem("token");
  const decoded = jwtDecode(token);
  let signId = decoded.data.emailId || decoded.data.mobileNumber;
  console.log(decoded, 'decoded')
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId).then(async result => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  }


  // const handlePercentage = (e) => {
  //   console.log(e, "New value");
  //   if (stakePhase == "Phase 1") {
  //     if (time == 12) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         console.log('600');
  //         setPercentage(3);
  //       } else if (e <= 1200) {
  //         setPercentage(4);
  //       } else if (e >= 1200) {
  //         setPercentage(5);
  //       }
  //     }

  //     else if (time == 24) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(4);
  //       } else if (e <= 1200) {
  //         setPercentage(5);
  //       } else if (e >= 1200) {
  //         setPercentage(6);
  //       }
  //     }

  //     else if (time == 36) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(5);
  //       } else if (e <= 1200) {
  //         setPercentage(6);
  //       } else if (e >= 1200) {
  //         setPercentage(7);
  //       }
  //     }
  //   }

  //   //
  //   else if (stakePhase == "Phase 2") {
  //     if (time == 12) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(2);
  //       } else if (e <= 1200) {
  //         setPercentage(3);
  //       } else if (e >= 1200) {
  //         setPercentage(4);
  //       }
  //     }

  //     else if (time == 24) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(3);
  //       } else if (e <= 1200) {
  //         setPercentage(4);
  //       } else if (e >= 1200) {
  //         setPercentage(5);
  //       }
  //     }

  //     else if (time == 36) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(4);
  //       } else if (e <= 1200) {
  //         setPercentage(5);
  //       } else if (e >= 1200) {
  //         setPercentage(6);
  //       }
  //     }
  //   }
  //   //
  //   else if (stakePhase == "Phase 3") {
  //     if (e < 100) {
  //       setPercentage(0);
  //     }
  //     else if (time == 12) {
  //       if (e <= 600) {
  //         setPercentage(2);
  //       } else if (e <= 1200) {
  //         setPercentage(3);
  //       } else if (e >= 1200) {
  //         setPercentage(4);
  //       }
  //     }

  //     else if (time == 24) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(2.5);
  //       } else if (e <= 1200) {
  //         setPercentage(3.5);
  //       } else if (e >= 1200) {
  //         setPercentage(4.5);
  //       }
  //     }

  //     else if (time == 36) {
  //       if (e < 100) {
  //         setPercentage(0);
  //       }
  //       else if (e <= 600) {
  //         setPercentage(3);
  //       } else if (e <= 1200) {
  //         setPercentage(4);
  //       } else if (e >= 1200) {
  //         setPercentage(5);
  //       }
  //     }
  //   }

  // };

  const sendOTP = () => {
    handleGetCode2();
    setMinutes(1);
    setSeconds(59);
  };
  const resendOTP = () => {
    handleGetCode2();
    setMinutes(1);
    setSeconds(59);
  };

  console.log(typeof stakePhase, "sttt")


  console.log(stacking_price, 'stacking_price');

  const resetHandler = () => {
    setTime("");
    setStakeAmount("");
    setPercentage("");
    setStakePhase("")
  };

  return (
    <div onClick={(e) => {
      // e.preventDefault();
      resetHandler();
    }}>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Funds</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Wallet
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container">
          <div class="d-flex-between mb-3 custom_dlflex">
            <ul class=" custom-tabs nav nav-pills mb-2 overflowx_scroll funds_tab  market_tabs">
              <li>
                <a class=" active" data-bs-toggle="tab" href="#funds">
                  Funds
                </a>
              </li>
              <li>
                <a data-bs-toggle="tab" href="#tt_history">
                  {" "}
                  Wallet History
                </a>{" "}
              </li>
              <li>
                <a data-bs-toggle="tab" href="#tradehistory">
                  Trade History
                </a>
              </li>
              <li>
                <a data-bs-toggle="tab" href="#transactionhistory">
                  Stake History
                </a>
              </li>
            </ul>
            <div class="d-flex-between bidder">
              <span>Estimated Portfolio : </span>
              <div class="d-flex-center ms-1">
                <span class="text-white">{estimatedportfolio}</span>
              </div>
            </div>
          </div>
          <div class="tab-content custom-tab-content p-0">
            <div
              class="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds"
            >
              <div className="table_search table_filter p-3" >
              <div class="form-check d-flex me-2"> 
                  <input class="form-check-input" type="checkbox" value={isEmptyWallet} id="flexCheckDefault" onClick={() => setIsEmptyWallet((isEmptyWallet) => !isEmptyWallet)} />
                  <label class="form-check-label" for="flexCheckDefault"> Hide Empty Balance </label>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table ">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>ASSETS</th>
                      <th>Available Balance</th>
                      <th>Locked</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fundData?.length > 0 ? (
                      finalFundData?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.short_name}</td>
                          <td>{item?.balance?.toFixed(8)}</td>
                          <td>{item?.locked_balance?.toFixed(8)}</td>
                          <td>
                            {parseFloat(
                              item?.balance + item?.locked_balance
                            ).toFixed(8)}
                          </td>

                          <td>
                            {item?.short_name === "LTC" ? (
                              <a
                                href="#/"
                                class=" tb_btn badge badge-success mx-1"
                                onClick={() => {
                                  alertErrorMessage(
                                    "Depost is not available on this Chain"
                                  );
                                }}
                              >
                                Deposit
                              </a>
                            ) : (
                              <a
                                href="#/"
                                class=" tb_btn badge badge-success mx-1"
                                data-bs-toggle="modal"
                                data-bs-target="#Deposit_modal"
                                onClick={() => {
                                  coinDetails(item?.currency_id);
                                  setSelectedCurrency(item?.short_name);
                                  setSelectedchain(item?.chain);
                                  item?.address
                                    ? setSelectDeposit(item?.address)
                                    : getDepostAddress(
                                      item?.currency_id,
                                      item?.chain
                                    );
                                }}
                              >
                                Deposit
                              </a>
                            )}
                            <a
                              href="#/"
                              class=" tb_btn  badge badge-danger  mx-1"
                              data-bs-toggle="modal"
                              data-bs-target="#Withdraw_modal"
                              onClick={() => { setCoinName(item?.short_name); coinDetails(item?.currency_id); setSelectedCurrency(item?.short_name); }}
                            >
                              Withdraw
                            </a>
                            {item?.short_name === "PLR" ?
                              <a
                                href="#/"
                                class=" tb_btn  badge badge-primary  mx-1"
                                data-bs-toggle="modal"
                                data-bs-target="#Stack_modal"
                                onClick={() => {setCurrencyId(item?.currency_id);handlePackageList();}}
                              >
                                Stake
                              </a> : ""}

                          </td>

                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="tt_history"
            >
              <div class="table-responsive">
                <table class="table ">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Date/Time</th>
                      <th>Coin</th>
                      <th>Amount</th>
                      {/* <th>Chain</th>
                      <th>Fee</th>
                      <th>From Address</th>
                      <th>To Address</th> */}
                      <th>Transaction Type</th>
                      <th>Status </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transferHistory?.length > 0 ? (
                      transferHistory?.map((item, index) => (
                        <tr
                          key={index}
                          className="cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#transfer_history"
                          onClick={() => setTransferData(item)}
                        >
                          <td class="color-grey">{index + 1}</td>
                          <td class="color-grey">
                            {
                              <Moment
                                date={item?.createdAt}
                                format="DD/MM/ YYYY hh:mm"
                              />
                            }
                          </td>
                          <td class="color-grey">{item?.short_name}</td>
                          <td class="color-grey">
                            {parseFloat(item?.amount).toFixed(5)}
                          </td>
                          {/* <td class="color-grey">{item?.chain}</td>
                          <td class="color-grey">
                            {parseFloat(item?.fee).toFixed(5)}
                          </td>
                          <td>{item?.from_address}</td>
                          <td>{item?.to_address}</td> */}
                          <td class="color-grey">{item?.transaction_type}</td>
                          <td>
                            <span
                              class={`${item?.status === "Completed"
                                ? "badge badge-success"
                                : item?.status === "Cancel"
                                  ? "badge badge-danger"
                                  : item?.status === "Cancelled"
                                    ? "badge badge-danger"
                                    : item?.status === "Pending"
                                      ? "badge badge-warning"
                                      : ""
                                }`}
                            >
                              {item?.status}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* *****Trade History******** */}
            <div
              class="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="tradehistory"
            >
              <div class="table-responsive">
                <table class="table ">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Date/Time</th>
                      <th>Trading Currency</th>
                      <th>Side</th>
                      <th>Order Type</th>
                      <th>Price</th>
                      <th>Executed</th>
                      <th>Total</th>
                      <th>Transaction Type </th>
                      <th>Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tradeHistory?.length > 0 ? (
                      tradeHistory?.map((item, index) => (
                        <tr
                          key={index}
                          className="cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#trade_history" onClick={() => { settradeHisDetails(item) }}
                        >
                          <td class="color-grey">{index + 1}</td>
                          <td class="color-grey">
                            {
                              <Moment
                                date={item?.createdAt}
                                format="DD-MM-YYYY : HH:MM"
                              />
                            }
                          </td>
                          <td class="color-grey">{item?.currency}</td>
                          <td class="color-grey">{item?.side}</td>
                          <td class="color-grey">{item?.order_type}</td>
                          <td class="color-grey">{item?.price.toFixed(5)}</td>
                          <td class="color-grey">{parseFloat(item?.quantity?.toFixed(8))}</td>
                          <td class="color-grey">
                            {(item?.price * item?.quantity).toFixed(5)}
                          </td>
                          <td class="color-grey">{item?.transaction_type}</td>
                          <td class="color-grey">{parseFloat(item?.fee?.toFixed(8))}</td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* *****Transaction History******** */}
            <div
              class="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="transactionhistory"
            >
              <div class="table-responsive">
                <table class="table ">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Date/Time</th>
                      <th>Coin</th>
                      <th>Phase</th>
                      <th>Interest Rate</th>
                      <th>Interest Amount</th>
                      <th>Transaction Type </th>

                    </tr>
                  </thead>
                  <tbody>

                    {transactionHistory?.length > 0 ? (
                      transactionHistory?.map((item, index) => (
                        <tr
                          key={index}
                          className="cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#transfer_history"
                        >
                          <td class="color-grey">{index + 1}</td>
                          <td class="color-grey">
                            {
                              <Moment
                                date={item?.createdAt}
                                format="DD-MM-YYYY : HH:MM"
                              />
                            }
                          </td>
                          <td class="color-grey">{item?.currency_name}</td>
                          <td class="color-grey">{item?.phase_name}</td>
                          <td class="color-grey">{item?.interest_rate}</td>
                          <td class="color-grey">{item?.interest_amount}</td>
                          <td class="color-grey">{item?.transaction_type}</td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="Deposit_modal"
        tabindex="-1"
        aria-labelledby="Deposit_modalLaebl"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5">
              <form action="#">
                {selectedCurrency == "INR" ? (
                  <>
                    <h6 class="flex_amount mb-3"> Bank Account Details</h6>{" "}
                    <hr />
                    {bankDetails?.map((item) => (
                      <>
                        <div className="bb_content">
                          <div class="flex_txt">
                            <strong>Bank Name :</strong>
                            {item?.bank_name}
                          </div>
                          <div class=" flex_txt">
                            <strong>Bank Account Number : </strong>
                            {item?.account_number}
                          </div>
                          <div class="flex_txt">
                            <strong>Account Name :</strong>
                            {item?.holder_name}
                          </div>

                          <div class=" flex_txt">
                            <strong>IFSC Code :</strong>
                            {item?.ifsc}
                          </div>
                          <div class="flex_txt">
                            <strong>Branch Name :</strong>
                            {item.branch}
                          </div>
                        </div>
                      </>
                    ))}
                    {/* <div class="flex_amount mb-3">
                      <div class="d-flex  flex_a">
                        <strong>Bank Name :</strong> ICICI BANK
                      </div>
                      <div class=" d-flex  flex_a">
                        <strong>Bank Account Number : </strong> 755001000141
                      </div>
                      <div class="d-flex  flex_a">
                        <strong>Account Holder Name :</strong> BADDETI
                        CHALAPATHI
                      </div>
                      <div class=" d-flex  flex_a">
                        <strong>Branch Name :</strong> Indiranagar 100 feet road
                      </div>
                      <div class=" d-flex  flex_a">
                        <strong>IFSC Code :</strong> ICIC0007550
                      </div>
                    </div> */}
                    <div className="form-group mb-4">
                      <input
                        className="form-control"
                        type="text"
                        name="amountVal"
                        value={amountVal}
                        placeholder="Amount"
                        onChange={handleInputSellChange}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <input
                        className="form-control"
                        type="text"
                        name="transVal"
                        value={transVal}
                        placeholder="Transaction Number"
                        onChange={handleInputSellChange}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12 upload-area">
                        <div className="brows-file-wrapper">
                          <input
                            name="file"
                            className="inputfile"
                            type="file"
                            onChange={handleChangeInrImage}
                          />
                          {inrImage === "" ? (
                            <label
                              for="file"
                              title="No File Choosen"
                              style={{ height: "167px" }}
                            >
                              <i className="ri-upload-cloud-line"></i>
                              <span className="text-center mb-2">
                                Choose a File
                              </span>
                              <span className="file-type text-center mt--10">
                                Drag or choose your file to upload
                              </span>
                            </label>
                          ) : (
                            <label
                              for="file"
                              title="No File Choosen"
                              style={{ height: "167px" }}
                            >
                              <i className=" text-success ri-check-double-fill"></i>
                              <span className="text-center mb-2">
                                File Uploaded
                              </span>
                              <span className="file-type text-center mt--10">
                                {inrImage}
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <button
                        type="button"
                        className="btn btn-gradient btn-small w-100 justify-content-center"
                        disabled={!amountVal}
                        onClick={() =>
                          handleDepositCoinInr(
                            amountVal,
                            transVal,
                            localInrImage
                          )
                        }
                      >
                        <span>Deposit INR</span>
                      </button>
                      <small class="mt-1 d-block">
                        <span className="onceDeposit">
                          Once Deposit It will Take Minimum Two Hours for
                          Confirm
                        </span>
                      </small>
                    </div>
                  </>
                ) : (
                  <>
                    <div id="bnm">
                      <img
                        alt=""
                        src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                        className="qr_img img-fluid"
                      />
                      <div
                        style={{ fontWeight: "bolder" }}
                        className="text-center text-danger mb-2"
                      >
                        Chain : BEP20{" "}
                      </div>
                      <input
                        className="shareUrl-input copy_url js-shareUrl text-center"
                        type="text"
                        readonly="readonly"
                        value={selectedDeposit}
                        onClick={() => {
                          navigator.clipboard.writeText(selectedDeposit);
                          alertSuccessMessage("Copied!!");
                        }}
                      />
                      <small className="text-center d-block mtb-2">
                        Click above to copy the Code.
                      </small>
                      <ul className="disclaimer mt-3">
                        <label>Deposit Tips</label>
                        <li>
                          • Minimum deposit of {minDeposit} {selectedCurrency},
                          deposit below that cannot be recovered.
                        </li>
                        <li>
                          • Please deposit only {selectedCurrency} on this
                          address. If you deposit any other coin, it will be
                          lost forever.
                        </li>
                        <li>
                          • This is {selectedChain} deposit address type.
                          Transferring to an unsupported network could result in
                          loss of deposit.
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="Withdraw_modal"
        tabindex="-1"
        aria-labelledby="Withdraw_modalLaebl"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Withdraw Funds
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5">
              {selectedCurrency === "INR" ? (
                <>
                  <div className="form-group mb-4">
                    <input
                      className="form-control"
                      type="text"
                      name="withdrawAmount"
                      value={withdrawAmount}
                      placeholder="Amount"
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 mt-4 d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="withdraw_Top"
                      value={otp}
                      placeholder="OTP"
                      style={{
                        width: "revert",
                        marginRight: "35px"
                    }}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                     <button
                      type="button"
                      className="btn btn-gradient btn-small w-10 justify-content-center "
                      style={{
                        height: "45px",
                        fontSize: "12px"
                    }}
                    disabled={seconds > 0 || minutes > 0}
                    onClick={() => resendOTP()}
                    >
                     Get OTP
                    </button>
                  </div>
                  {seconds > 0 || minutes > 0 ? (
            <p>
              Resend OTP in : {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : ''}
                  <div className="form-group mb-4">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-gradient btn-small w-100 justify-content-center"
                      data-bs-toggle="modal"
                      data-bs-target="#Withdraw_modal3"
                    >
                      <span>Withdraw INR</span>
                    </button>
                  </div>
                  <p>Withdrawal Fee:- {fee}% TDS</p>
                </>
              ) : (
                <>
                  <div className="form-group mb-4">
                    <input
                      className="form-control"
                      type="text"
                      name="wallet_Add"
                      value={wallet_Add}
                      placeholder="Wallet Address"
                      onChange={(e) => setwallet_Add(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <input
                      className="form-control"
                      type="text"
                      name="amount_val"
                      value={amount_val}
                      placeholder="Amount"
                      onChange={(e) => setAmount_avl(e.target.value)}
                    />
                       <div className="form-group mb-4 mt-4 d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="withdraw_Top"
                      value={otp}
                      placeholder="OTP"
                      style={{
                        width: "revert",
                        marginRight: "35px"
                    }}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                     <button
                      type="button"
                      className="btn btn-gradient btn-small w-10 justify-content-center "
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      // color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                       height: "45px",
                      fontSize: "12px"
                    }}
                    onClick={resendOTP}
                    >
                    {seconds > 0 || minutes > 0 ? 'Resend OTP' : 'Get OTP'}
                    </button>
                  </div>
                  {seconds > 0 || minutes > 0 ? (
            <p>
             Resend OTP in : {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>) : ''}

                  </div>
                  <div className="form-group mb-4">
                    <button
                      type="button"
                      className="btn btn-gradient btn-small w-100 justify-content-center"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#Withdraw_modal2"

                    >
                      <span>Withdraw</span>
                    </button>
                  </div>
                  <p>Withdrawal Fee:- {fee} {selectedCurrency}</p>
                  {/* <p>Hello world!!</p> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Withdrawal Modal INR*/}

      <div
        class="modal fade"
        id="Withdraw_modal3"
        tabindex="-1"
        aria-labelledby="Withdraw_modalLaebl"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              {/* <h3 class="modal-title" data-bs-dismiss="modal"></h3> */}
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount} , please confirm your withdraw by clicking
                    'Confirm' button below.
                  </h4>
                </div>

                <Row>
                  <Col>
                    <div className="form-group mb-4">
                      <button
                        type="button"
                        className="btn btn-danger btn-small w-100 justify-content-center"
                        data-bs-dismiss="modal"
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group mb-4">
                      <button
                        type="button"
                        className="btn btn-success btn-small w-100 justify-content-center"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#Withdraw_modal3"
                        onClick={() => handleWithdrawCoinInr(withdrawAmount, otp)}
                      >
                        <span>Confirm</span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </div>
      {/* Withdrawal Modal */}

      <div
        class="modal fade"
        id="Withdraw_modal2"
        tabindex="-1"
        aria-labelledby="Withdraw_modalLaebl"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"

              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency} {amount_val} ,
                    please confirm your withdraw by clicking 'Confirm' button
                    below.
                  </h4>
                </div>

                <Row>
                  <Col>
                    <div className="form-group mb-4">
                      <button
                        type="button"
                        className="btn btn-danger btn-small w-100 justify-content-center"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#Withdraw_modal2"
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group mb-4">
                      <button
                        type="button"
                        className="btn btn-success btn-small w-100 justify-content-center"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#Withdraw_modal2"
                        onClick={() => handleWithdrawCoin(wallet_Add, amount_val, coinName, otp)}
                      >
                        <span>Confirm</span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </div>
      {/* TransHistory modal */}
      <div
        class="modal fade"
        id="transfer_history"
        tabindex="-1"
        aria-labelledby="transfer_history"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5 body_history">

              <>
                <div class="tt_item ">
                  <span class="tt_disable">Coin</span>
                  <span class="tt_normal">
                    <b> {transferData?.short_name} </b>
                  </span>
                </div>

                <div class="tt_item ">
                  <span class="tt_disable">Status</span>
                  <span
                    class={`${transferData?.status === "Completed"
                      ? "badge badge-success"
                      : transferData?.status === "Cancel"
                        ? "badge badge-danger"
                        : transferData?.status === "Pending"
                          ? "badge badge-warning"
                          : ""
                      }`}
                  >
                    <strong>{transferData?.status}</strong>
                  </span>
                </div>
                <div class="tt_data">
                  <div class="tt_item">
                    <span class="tt_disable">Transaction Type</span>
                    <span class="tt_normal">
                      <b>{transferData?.transaction_type}</b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">Fee</span>
                    <span class="tt_normal">
                      <b>{parseFloat(transferData?.fee).toFixed(5)}</b>
                    </span>
                  </div>
                  <div class="tt_item">
                    <span class="tt_disable">Amount</span>
                    <span class="tt_normal">
                      <b>{transferData?.amount}</b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">From Address</span>
                    <span class="tt_normal">
                      <b>{transferData?.from_address}</b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">To Address</span>
                    <span class="tt_normal">
                      <b> {transferData?.to_address} </b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">Chain</span>
                    <span class="tt_normal">
                      <b> {transferData?.chain} </b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">
                      Transaction Fees <br />
                      <small>Incl.of all applicable taxes</small>
                    </span>
                    <span class="tt_normal">
                      <b>{!fees ? "0" : fees}</b>
                    </span>
                  </div>
                  <div class="tt_item ">
                    <span class="tt_disable">Remarks</span>
                    <span class="tt_normal">
                      <b>Polarize Exchange</b>
                    </span>
                  </div>
                </div>
              </>



            </div>


          </div>
        </div>
      </div>

      {/* Stack modal */}
      <div
        class="modal fade"
        id="Stack_modal"
        tabindex="-1"
        aria-labelledby="Stack_modalLaebl"
        aria-hidden="true"
        onClick={(e) => e.stopPropagation()}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Stake Module
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetHandler()}
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5">
              <div className="form-group mb-4">
                <label for="phase">Staking Plans</label>
                <select
                  name="phase"
                  value={stakePhase}
                  className=""
                  onChange={(e) => handleStakephase(e)}
                    // setStakePhase(e.target.value)
                >
                  <option selected >Select Staking Plans</option>           
                  <option value={packageList}>{packageList}</option>
                </select>
              </div>
              <div className="form-group mb-4">
                <label for="time">Duration</label>
               
                <select
                  name="time"
                  value={time}
                  className=""
                  onClick = {()=>{if(stakePhase == ''){
                    alertErrorMessage("Please first select Staking Plan")
                  }}}
                  onChange={(e) => {
                    // if(stakePhase !== ''){
                      setTime(e.target.value);
                    // } else {
                    //   alertErrorMessage("Please first select Staking Plan")
                    // }
                    
                  }}
                >
                  <option selected>Select Duration</option>
                  
                  {duration?.map(item=>(
                    <option value={item}>{item/12} year</option>
                  ))}
                  {/* <option value="12">1 year</option>
                  <option value="24">2 year</option>
                  <option value="36">3 year</option> */}
                </select>


              </div>
              <div className="form-group mb-4">
                <label for="phase">Amount in PLR</label>
                <input
                  // className="form-control"
                  type="number"
                  onWheelCapture={e => {
                    e.target.blur()
                  }}
                  name="amount_val"
                  value={stakeAmount}
                  placeholder="Amount(PLR)"
                  onChange={(e) => {
                    if (time && stakePhase) {
                      handleInputAmount(e)
                      // setStakeAmount(e.target.value);
                      //  handlePercentage((e.target.value) * AllData);
                      //  setstacking_price((e.target.value) * AllData)
                    } else {
                      alertErrorMessage("Please first select Staking Plan & Time")
                    }
                  }}
                />
                <span className="txt_badge" > Total Amount in USDT :
                  {stakeAmount > 0 ? stakeAmount * AllData : <span> 0.00 </span>}</span>
              </div>




              <div className="form-group mb-4">
                <label for="reward rate">Reward Rate Per Month</label>
                <input
                  // className="form-control"
                  type="text"
                  name="percentage"
                  value={percentage + "%"}
                  placeholder="Percentage"
                />
              </div>

              <div className="form-group mb-4">
                <label for="reward rate">Referral Code</label>
                <input
                  // className="form-control"
                  type="text"
                  name="referral"
                  value={referralCode}
                  placeholder="Referral Code (Optional)"
                  onChange={(e)=>setReferralCode(e.target.value)}
                />
              </div>

              <div className="form-group mb-4">
                <button
                  type="button"
                  disabled={percentage == 0 ? true : false}
                  className="btn btn-gradient btn-small w-100 justify-content-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStaking(userId, stakePhase, stakeAmount, time, percentage, currencyId, stacking_price, referralCode);
                  }}
                >
                  <span>Stake</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TradeHistory modal */}
      <div class="modal fade" id="trade_history" tabindex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Trade Details
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-5 body_history">
              <div class="tt_data">
                <div class="tt_item ">
                  <span class="tt_disable">Date &amp; Time</span>
                  <span class="tt_normal">
                    <b>{moment(tradeHisDetails?.updatedAt).format("YYYY MM DD : HH:MM")} </b>
                  </span>
                </div>
                <div class="tt_item">
                  <span class="tt_disable">Currency</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.currency}</b>
                  </span>
                </div>
              </div>
              <div class="tt_item ">
                <span class="tt_disable">Side</span>
                <span class="tt_normal">
                  <b>{tradeHisDetails?.side}</b>
                </span>
              </div>

              <div class="tt_data">
                <div class="tt_item">
                  <span class="tt_disable">Transaction Type</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.transaction_type}</b>
                  </span>
                </div>
                <div class="tt_item ">
                  <span class="tt_disable">
                    Fee
                  </span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.fee}</b>
                  </span>
                </div>

                <div class="tt_item ">
                  <span class="tt_disable">Fee Type</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.fee_type}</b>
                  </span>
                </div>
                <div class="tt_item">
                  <span class="tt_disable">Amount</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.amount}</b>
                  </span>
                </div>
                <div class="tt_item ">
                  <span class="tt_disable">Order Type</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.order_type}</b>
                  </span>
                </div>

                <div class="tt_item ">
                  <span class="tt_disable">Quantity</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.quantity}</b>
                  </span>
                </div>
                <div class="tt_item ">
                  <span class="tt_disable">Order Id</span>
                  <span class="tt_normal">
                    <b>{tradeHisDetails?.order_id}</b>
                  </span>
                </div>


                <div class="tt_item ">
                  <span class="tt_disable">Remarks</span>
                  <span class="tt_normal">
                    <b>Polarize Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundPage;
