import React, { useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";


const TradingFee = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleInputReset = () => {
    setName("");
    setEmail("");
    setMobileNumber("");
    setSubject("");
    setMessage("");
  }

  const handleContact = async (name, email, mobileNumber, subject, message) => {
    await AuthService.Contactus(name, email, mobileNumber, subject, message).then(async result => {
      if (result.message === "Your request Submitted.") {
        try {
          alertSuccessMessage(result.message);
          handleInputReset();
        } catch (error) {
          alertErrorMessage(error);
          /*  console.log('error', `${error}`); */
        }
      } else {
        alertErrorMessage(result.message);
      }
    })

  }


  const staticData = [
   "PLR/USDT",
    "BTC/USDT",
   "ETH/USDT",
    "BNB/USDT",
    "XRP/USDT",
    "ADA/USDT",
    "DOGE/USDT",
    "MATIC/USDT",
    "SOL/USDT",
    "DOT/USDT",
    "SHIB/USDT",
    "LINK/USDT",
    "APE/USDT",
    "SAND/USDT",
    "FIL/USDT",
    "LRC/USDT",
    "BTT/USDT",
    "AUDIO/USDT",
    "MASK/USDT",
    "ROSE/USDT",
    "OCEAN/USDT",
    "FET/USDT",
    "CELR/USDT",
    "WIN/USDT",
    "UNI/USDT",
    "MANA/USDT",
    "GRT/USDT",
   " GALA/USDT",
    "AVAX/USDT",
    "ENS/USDT",
    "BABYDOGE/USDT",
    "FLOKI/USDT",
    "ELON/USDT",
    "TWT/USDT",
   " ENJ/USDT",
   " CHZ/USDT",
    "TRX/USDT",
    "LTC/USDT",
    "BCH/USDT",
    "ATOM/USDT",
   " WLD/USDT",
    "OKB/USDT",
    "Pepe/USDT",
    "DAI/USDT",
    "MKR/USDT",
    "EGLD/USDT",
    "SNX/USDT",
    "COMP/USDT",
    "ZEC/USDT",
    "SXP/USDT",
   " WOO/USDT",
    "REN/USDT",
    "1INCH/USDT",
    "CRV/USDT",
   " BAT/USDT",
    "GMT/USDT",
    "Blur/USDT",
    "IOTX/USDT",
    "ONT/USDT",
    "CTSI/USDT",
    "SUSHI/USDT",
    "DEXE/USDT",
    "ALPHA/USDT",
    "LINA/USDT",
    "WRX/USDT",
    "COTI/USDT",
    "MBOX/USDT",
    "MDX/USDT",
    "DODO/USDT",
    "TKO/USDT",
    "CLV/USDT",
    "UNFI/USDT",
    "Flux/USDT",
    "LDO/USDT",
    "NEAR/USDT",
    "AAVE/USDT",
    "BIT/USDT",
    "EOS/USDT",
    "THETA/USDT",
    "AXS/USDT",
    "INJ/USDT",
    "FTM/USDT",
    "FLOW/USDT",
    "KCS/USDT",
    "FTT/USDT",
    "LUNC/USDT",
    "ARB/USDT",
    "MX/USDT",
    "ZIL/USDT",
    "DYDX/USDT",
    "ETC/USDT",
    "QNT/USDT",
    "EPS/USDT",
    "TIME /USDT",
    "FRONT/USDT",
    "NWC/USDT",
    "ORAI/USDT",
    "GT/USDT",
    "CELO/USDT",
    "BAND/USDT"
    
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page

  // Calculate the start and end indices for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  // Create a slice of the static data for the current page
  const paginatedData = staticData.slice(startIndex, endIndex);

  console.log(paginatedData, "ppp");

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">  Trading Fees </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Trading Fees</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="row" >
            <div className="col-md-12" >

             
            {/* <div class="create-item-wrapper plicy_sec career_sec p-0"> */}
            <div className="table-responsive" >
                <table class="table">
                <thead>
                    <tr>
                        <th>
                            Spot Market
                        </th>
                        <th>
                        Minimum Order Size
                        </th>
                        <th>
                        Buy  <smal>Maker Fee</smal>

                        </th>
                        <th>
                        Buy  <smal>Taker Fee</smal>

                        </th>
                        <th>
                        Sell <small>Maker Fee</small>

                        </th>
                        <th>
                        Sell <small>Taker Fee</small>

                        </th>
                        <th>
                        Status
                        </th>
                    </tr>
                </thead>
               <tbody>
               {paginatedData.map((item, index) => (
                <>
             <tr>
                        <td>{item}</td>
                        <td>10$</td>
                        <td>0.3%</td>
                        <td>0.3%</td>
                        <td>0.3%</td>
                        <td>0.3%</td>
                        <td>Active</td> 
                    </tr>
        
               </>
          ))}
               </tbody>
               </table>

                {/* Pagination controls */}
        <div className="pagination">
        <button className="btn btn-gradient btn-sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          Previous
        </button>
        <span className="px-2">Page {currentPage + 1}</span>
        <button className="btn btn-gradient btn-sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= staticData.length}
        >
          Next
        </button>
      </div>
            </div>
            {/* </div> */}


               
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TradingFee;