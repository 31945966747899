import React, { useContext } from "react";
import LandingPage from "../ui/pages/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthHeader from "../customComponent/AuthHeader";
import UserHeader from "../customComponent/UserHeader";
import Footer from "../customComponent/Footer";
import LoginPage from "../ui/pages/LoginPage";
import ForgotPassPage from "../ui/pages/ForgotPassPage";
import ReferralPage from "../ui/pages/ReferralPage";
import TermsOfUsePage from "../ui/pages/TermsOfUsePage";
import PrivacyPolicyPage from "../ui/pages/PrivacyPolicyPage";
import SignupPage from "../ui/pages/SignupPage";
import TradePage from "../ui/pages/TradePage";
import ProfilePage from "../ui/pages/ProfilePage";
import { ProfileContext } from "../context/ProfileProvider";
import ContactPage from "../ui/pages/ContactPage";
import MarketPage from "../ui/pages/MarketPage";
import FundPage from "../ui/pages/FundPage";
import ComingSoonPage from "../ui/pages/ComingSoonPage";
import AboutUs from "../ui/pages/AboutUs";
import KycPage from "../ui/pages/KycPage";
import StakingPage from "../ui/pages/StakingPage";
import NotificationPage from "../ui/pages/NotificationPage";
import AmlPolicy from "../ui/pages/AmlPolicy";
import TradingFee from "../ui/pages/TradingFee";
import ReferralList from "../ui/pages/ReferralList";
import TermsNConditions from "../ui/pages/Terms&Conditions"
import { RequiresAuth } from "../requiresAuth";
import { ToastContainer } from "react-toastify";
import StakingDetails from "../ui/pages/StakingDetails";
import ReferredByMe from "../ui/pages/ReferredByMe";
import StakingReferredIncome from "../ui/pages/StakingReferredIncome";
import { HashRouter } from 'react-router-dom';

const Routing = () => {
  const [profileState] = useContext(ProfileContext);
  const token = sessionStorage.getItem("token");

  const myTheme = localStorage.getItem("theme");
  if (!myTheme) {
    localStorage.setItem("theme", "1");
  }

  return (
    <HashRouter>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
      {!token && !profileState.token ? <AuthHeader /> : <UserHeader />}
      <Routes>
        <Route exact path="/" element={<LandingPage />}></Route>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/signup" element={<SignupPage />}></Route>
        <Route
          exact
          path="/forgotpassword"
          element={<ForgotPassPage />}
        ></Route>
        <Route exact path="/trade" element={<TradePage />}></Route>
        <Route
          exact
          path="/market"
          element={<MarketPage />}
        ></Route>
        <Route exact path="/fees" element={<TradingFee />}></Route>
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/amlPolicy" element={<AmlPolicy />}></Route>
        <Route exact path="/termsOfUse" element={<TermsOfUsePage />}></Route>
        <Route exact path="/termsNConditions" element={<TermsNConditions />}></Route>
        <Route exact path="/aboutus" element={<AboutUs />}></Route>
        <Route exact path="/contact" element={<ContactPage />}></Route>

        {/* {!token && !profileState.token ? (
          <Route path="*" element={<ComingSoonPage />} />
        ) : (
          <> */}
        <Route exact path="/stacking" element={<RequiresAuth><StakingPage /></RequiresAuth>}></Route>
        <Route exact path="/stakingDetails" element={<RequiresAuth><StakingDetails /></RequiresAuth>}></Route>
        <Route
          exact
          path="/notifications"
          element={
            <RequiresAuth>
              <NotificationPage />
            </RequiresAuth>
          }
        ></Route>

        <Route
          exact
          path="/funds"
          element={
            <RequiresAuth>
              <FundPage />
            </RequiresAuth>
          }
        ></Route>

        <Route
          exact
          path="/referralList"
          element={
            <RequiresAuth>
              <ReferralList />
            </RequiresAuth>
          }
        ></Route>
        <Route exact path="/myReferrals" element={<RequiresAuth> <ReferredByMe/> </RequiresAuth>}/>
        <Route exact path="/stakingReferredIncome" element={<RequiresAuth> <StakingReferredIncome/> </RequiresAuth>}/>
        <Route
          exact
          path="/profile"
          element={
            <RequiresAuth>
              <ProfilePage />
            </RequiresAuth>
          }
        ></Route>
        <Route
          exact
          path="/referral"
          element={
            <RequiresAuth>
              <ReferralPage />
            </RequiresAuth>
          }
        ></Route>
        <Route
          exact
          path="/kyc"
          element={
            <RequiresAuth>
              <KycPage />
            </RequiresAuth>
          }
        ></Route>
        {/* </>
        )} */}
        <Route path="*" element={<ComingSoonPage />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
};

export default Routing;
