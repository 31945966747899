import React, { useState, useContext, useEffect } from "react";
import { ProfileContext } from "../../context/ProfileProvider";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../api/services/AuthService";
import { ApiConfig } from "../../api/apiConfig/apiConfig";
import { useEditProfile } from "../../context/EditProfileContext";

const UserHeader = () => {
  const [profileState, updateProfileState] = useContext(ProfileContext);
  const navigate = useNavigate();
  const [notificationStatus,setNotificationStatus] = useState()
  // const [getSelfy, setGetSelfy] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");

  const { pathname } = useLocation();
  const {
    firstName,
    lastName,
    getSelfy,
    setGetSelfy,
    setFirstName,
    setLastName,
  } = useEditProfile();

  const selfy = localStorage.getItem("selfie");
  console.log(selfy, "sfsddyyag");

  useEffect(() => {
    handleNotifications();
  }, []);

  useEffect(() => {
    handleDetials();
  }, [window.location.pathname]);

  console.log(window.location.pathname, "ddd");

  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    await AuthService.getNotificationList().then(async (result) => {
      if (result.success) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  console.log(window.location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleLogOut = () => {
    updateProfileState({});
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const tab = document.getElementById("body");
    let theme = localStorage.getItem("theme");
    if (theme === "1") {
      tab.classList.add("theme-dark");
    } else {
      tab.classList.toggle("theme-dark");
      tab.classList.toggle("theme-dark");
    }
  }, []);

  const handleTheme = () => {
    const tab = document.getElementById("body");
    let theme = localStorage.getItem("theme");
    if (theme === "1") {
      localStorage.setItem("theme", "2");
      tab.classList.remove("theme-dark");
      tab.classList.add("theme-dark");
    } else {
      localStorage.setItem("theme", "1");
      tab.classList.add("theme-dark");
      tab.classList.remove("theme-dark");
    }
  };

  function showMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.add("active");
  }

  function hideMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.remove("active");
  }

  useEffect(() => {
    handleDetials();
  }, []);

  const handleDetials = async () => {
    await AuthService.getDetails().then(async (result) => {
      if (result.success) {
        setGetSelfy(result?.data?.profilepicture);
        setFirstName(result?.data?.firstName);
        setLastName(result?.data?.lastName);
        setNotificationStatus(result?.data?.notification_status)
      }
    });
  };

  console.log(window.location.pathname, "uuu");

  const location = window.location.pathname == "/notifications"

  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="container">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div className="logo-wrapper">
                <Link to="/" className="logo logo-light">
                  <img src="images/logo-white.png" alt="brand" />{" "}
                </Link>
                <Link to="/" className="logo logo-dark">
                  <img src="images/logo-dark.png" alt="brand" />{" "}
                </Link>
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <div className="mainmenu-wrapper">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li className="">
                      <Link className="" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/market">
                        Market
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/trade">
                        Trade
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/funds">
                        Funds
                      </Link>
                    </li>
                    {/* <li className="">
                      <Link className="" to="/stacking">
                        Staking
                      </Link>
                    </li> */}
                    <div class="dropdown link-dropdown">
                      <button class="btn btn-link btn-dark dropdown-toggle" type="button" id="StakingDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        Staking
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="StakingDropdown">
                        <li><Link class="dropdown-item flex-column text-start mb-0 bg-dark" >  <small>Refered by</small>  <span className="text-warning">  <strong> User Name Here </strong>  </span> </Link> </li>
                        <li><Link class="dropdown-item" to="/stacking"> Self Staking History </Link></li>
                        <li><Link class="dropdown-item" to="/myReferrals"> My Referrals </Link></li>
                        <li><Link class="dropdown-item" to="/stakingReferredIncome"> Total Staking Referral Income </Link></li>
                      </ul>
                    </div>

                  </ul>
                </nav>
              </div>
              <ul className="header-right-inner">
                <li className="setting-option mobile-menu-bar d-block d-xl-none">
                  <button
                    className="hamberger-button"
                    onClick={() => showMenu()}
                  >
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>

                {/* notification  */}
                <li className="avatar-info notifications">
                  {location ? <span className="cursor_pointer text-white  btn-icon">
                    <i class="ri-notification-3-line me-0"></i>
                  </span> : <span className="cursor_pointer text-white  btn-icon">
                    <i class="ri-notification-3-line me-0"></i>
                    { notificationStatus ? 
                     <span className="nofti_alert"></span> : "" }
                  </span>}
                  
                  <ul className="submenu pb-0">
                    <h6 class="dropdown-header text-white d-flex align-items-center pb-3 ">
                      <i class="ri-notification-3-line me-3"></i>New
                      Notificatons
                    </h6>
                    <hr />

                    {notifications.length > 0
                      ? notifications.slice(0, 2).map((value, index) => (
                          <li className="noft_data">
                            <Link to="/notifications">
                              <div>
                                <div class="small text-gray-500">
                                  {value?.title}
                                </div>
                                <span class="font-weight-bold">
                                  <td>{value?.message}</td>
                                </span>
                              </div>
                            </Link>
                          </li>
                        ))
                      : <div style={{alignSelf: "center"}}>no data available</div>}

                    <Link
                      to="/notifications"
                      class="dropdown-item text-center small text-gradient"
                    >
                      Show All Alerts
                    </Link>
                  </ul>
                </li>
                {/* notification  End */}

                <li className="avatar-info profile_icon">
                  <span>
                    <a href="#">
                      {!getSelfy ? (
                        <img src="images/profilelogo.png" alt="user avatar" />
                      ) : (
                        <img
                          src={`${ApiConfig.appUrl + getSelfy}`}
                          alt="user avatar"
                        />
                      )}
                    </a>
                    <small>
                      {" "}
                      {firstName} {lastName}{" "}
                    </small>
                  </span>

                  <ul className="submenu">
                    <li>
                      <Link to="/profile">
                        <i className="ri-user-line"></i>
                        <div className="ac_name">
                          My Account <br />{" "}
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      
                    </li> */}

                    <li>
                      <Link className="" to="/referral">
                        <i class="ri-share-line"></i>Invite & Earn
                      </Link>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLogOut()}
                      >
                        <i className="ri-logout-box-r-line"></i>Log out
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                <label className="theme-switcher-label d-flex active" for="theme-switcher" >
                  <input type="checkbox" className="theme-switcher" id="theme-switcher" onClick={() => handleTheme()} />
                  <div className="switch-handle">
                    <i className="ri-moon-line dark-text"></i>
                    <i className="ri-sun-line light-text"></i>
                  </div>
                </label>
              </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div className="popup-mobile-menu" id="qwert">
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <a className="logo-light" href="index.php">
                <img src="images/logo-white.png" alt="nft-logo" />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button" onClick={() => hideMenu()}>
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu">
              <li onClick={() => hideMenu()}>
                {" "}
                <Link className="" to="/">
                  Home
                </Link>
              </li>
              <li className="" onClick={() => hideMenu()}>
                <Link className="" to="/market">
                  Market
                </Link>
              </li>
              <li className="" onClick={() => hideMenu()}>
                <Link className="" to="/trade">
                  Trade
                </Link>
              </li>
              <li className="" onClick={() => hideMenu()}>
                <Link className="" to="/funds">
                  Funds
                </Link>
              </li>

              {/* <li onClick={() => hideMenu()}>
                <Link className="" to="/stacking">
                  Staking
                </Link>
                
                </li> */}

               

              <li className="btn-link_drop">
              <a class="btn btn-link d-flex align-items-center" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                 Staking
                <i class="ri-arrow-down-s-fill"></i>
              </a>
              

                <ul  class="collapse" id="collapseExample" >
                <hr/>
                  <li><Link class="btn-link-item flex-column text-start mb-0 " to="/referedBy"> <small>Refered by</small>  <span className="text-warning d-block">  <strong> User Name Here </strong>  </span> </Link> <hr/> </li>
                  <li><Link class="btn-link-item" to="/stacking"> Self Staking History </Link></li>
                  <li><Link class="btn-link-item" to="/myReferrals"> My Referrals </Link></li>
                  <li><Link class="btn-link-item" to="/stakingReferredIncome"> Total Staking Referral Income </Link></li>
                </ul>



              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
