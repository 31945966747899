import React, { useEffect } from 'react';
import './index.css';
import { widget } from '../../charting_library';
import Datafeed from './datafeed';
import logo from '../../assets/favicon.png'


export default function TVChartContainer({ symbol }) {
	let tvWidget = null;
	const getChart = (symbol) => {

		const widgetOptions = {
			symbol: `${symbol === "PLR/USDT" ? "POLARIZENETWORK/USDT" : symbol}`,
			interval: '1',
			fullscreen: false,
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			theme: 'dark',
			logo: {
				image: logo,
				link: "https://polarize.exchange/trade"
			},
			time_frames: [
				{ text: '6M', resolution: 'D', description: '6 Month' },
				{ text: '1Y', resolution: 'W', description: '1 Year' },
				{ text: '5Y', resolution: 'W', description: '5 Year' },
			],
			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
			},
			time_scale: {
				min_bar_spacing: 20
			},
			disabled_features: ["left_toolbar"],
		};
		tvWidget = new widget(widgetOptions);
		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				console.log('Chart is ready to display');

			});
		});
	};

	useEffect(() => {
		getChart(symbol);
	}, [symbol]);


	return (
		<>
			<div id={'TVChartContainer'} />
		</>

	);
}