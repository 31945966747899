import { makeApiRequest, makeApiRequest2, parseFullSymbol, } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream, } from './streaming.js';

const lastBarsCache = new Map();

// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1', '3', '5', '15', '1D', '1W', '1M'],

    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [{
        name: 'crypto',
        value: 'crypto',
    },
    ],
};

let methods;
export default methods = {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback,
    ) => {
        console.log('[searchSymbols]: Method call');

        onResultReadyCallback(symbolType);
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        // console.log('[resolveSymbol]: Method call', symbolName);
        if (symbolName) {
            const symbolInfo = {
                ticker: symbolName,
                name: symbolName,
                description: symbolName,
                type: symbolName,
                session: '24x7',
                timezone: 'Etc/UTC',
                exchange: '',
                minmov: 1,
                pricescale: 1000000,
                has_intraday: true,
                intraday_multipliers: ['1', '60'],
                supported_resolution: configurationData.supported_resolutions,
                has_no_volume: true,
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                volume_precision: 2,
                data_status: 'streaming',
            };
            // console.log('[resolveSymbol]: Symbol resolved', symbolName);
            onSymbolResolvedCallback(symbolInfo);
        }
        else if (!symbolName) {
            // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('cannot resolve symbol');
            return;
        }
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        let { from, to, firstDataRequest } = periodParams;
        const parsedSymbol = parseFullSymbol(symbolInfo.name);
        let isLocal = symbolInfo.local === true
        try {
            let data;
            let ApiData;
            if (isLocal) {
                ApiData = await makeApiRequest2(parsedSymbol.fromSymbol, parsedSymbol.toSymbol, from, to);
                data = ApiData?.data;
            } else {
                const url = resolution === '1D' ? 'histoday' : resolution == 60 ? 'histohour' : 'histominute'
                ApiData = await makeApiRequest(parsedSymbol.fromSymbol, parsedSymbol.toSymbol, to, url);
                data = ApiData?.Data?.Data;

            }
            if (ApiData?.Response === 'Error' || data?.length === 0) {
                onHistoryCallback([], {
                    noData: true,
                });
                return;
            }
            let bars = [];
            data.forEach(bar => {
                if (bar.time >= from && bar.time < to) {
                    bars = [...bars, {
                        time: bar.time * 1000,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }];
                }
            });
            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.name, {
                    ...bars[bars.length - 1],
                });
            }
            // console.log(bars.length,'bars');
            onHistoryCallback(bars, {
                noData: false,
            });
        } catch (error) {
            console.log('TEST');
            onErrorCallback(error);
        }
    },


    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
    ) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.name),
        );
    },

    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        unsubscribeFromStream(subscriberUID);
    },
};