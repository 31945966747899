import React, { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";
import {
  alertErrorMessage,
  alertSuccessMessage,
  alertMessage
} from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import Countdown from "../../../customComponent/Countdown";
import { useCount } from "../../../context/CountdownContext";
import { io } from "socket.io-client";

const LandingPage = () => {
  const [upperPairData, setUpperPairData] = useState([]);
  const [marketUpdate, setMarketUpdate] = useState([]);
  const [banner, setBanner] = useState([]);
  const [socket, setSocket] = useState(null);

  const { countdown } = useCount();

  const { hours, minutes, seconds } = countdown;

  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
      transports: ['websocket'],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true
    });
  
    setSocket(newSocket);
    let payload={
        'message':'market',
    }
    newSocket.emit('message', payload);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('message', (data) => {
        console.log(data, "market data");
        setUpperPairData(data.pairs.slice(0, 5));
      });
    }
  }, [socket]);

 

  const nextPage = (data) => {
    navigate("/trade", { state: data });
  };

  useEffect(() => {
    handleBanner();
    // $("#onload").modal("show");
  }, []);

  const handleBanner = async () => {
    // LoaderHelper.loaderStatus(true);
    await AuthService.bannerList().then(async (result) => {
      if (result.success) {
        // LoaderHelper.loaderStatus(false);
        try {
          setBanner(result.data);
          $("#onload").modal("show");
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        console.log(result.message)
        // LoaderHelper.loaderStatus(false);
        // alertErrorMessage(result?.message);
      }
    });
  };

//   useEffect(() => {
//     alertMessage(`<strong><h4>We are under maintenance.</h4></strong>
//         <strong><small>STAY TUNED!</small></strong>`)
// }, [])

  return (
    <>
      <section className="hero-banner-style top-section-gap ">
        <div className="hero-banner_inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="banner-content">
                  <h1 className="mb-4 title">
                    <small data-bs-toggle="modal" data-bs-target="#onload">
                      Fastest and Easiest way to
                    </small>
                    {/* <br /> */}
                    <em className="text-gradient"> Buy, Sell & Trade</em>
                  </h1>
                  <p>
                    The place where you can trade <br /> cryptocurrencies with global market price
                  </p>
                  <div className="group-btn mt-8">
                    <a href="/#/trade" className="btn btn-gradient">
                      <span>Get Start</span>
                    </a>
                  </div>
                </div>
                {/* <!-- End banner-content --> */}
              </div>
              {/* <!-- End .col --> */}
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <img
                  src="images/main_img.svg"
                  className=" img_roteate main_img w-100"
                />
              </div>
              {/* <!-- End .col --> */}
            </div>
          </div>
          {/* <!-- End .container --> */}
        </div>
      </section>
      {/* <!-- Start Live Auction --> */}
      <section className=" live_prices">
        <div class="container">
          <Swiper
            className="market_slider  pb-15"
            spaceBetween={10}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            // slidesPerView={5}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {marketUpdate.length > 0
              ? marketUpdate.map((item) => (
                  <SwiperSlide>
                    <div class="top-seller-style-two ">
                      <div class="d-flex-between">
                        <div class="d-flex-center">
                          <div class="thumb-wrapper">
                            <a
                              href="#"
                              class="thumb no-border"
                              onClick={() => nextPage(item)}
                            >
                              <img
                                src={ApiConfig.appUrl + item?.iconPath}
                                alt="top sellter"
                              />
                            </a>
                          </div>
                          <div class="content">
                            <h4 class="title pb-0 mb-0">
                              <a href="#" onClick={() => nextPage(item)}>
                                {item?.fname}/{item?.sname}
                              </a>
                            </h4>
                          </div>
                          {/* <!-- End .content --> */}
                        </div>
                        {/* <!-- End .d-flex-center --> */}
                        <i
                          onClick={() => nextPage(item)}
                          class="ri-arrow-right-line btn_icon"
                        ></i>
                      </div>
                      <hr />
                      <div class="d-flex-between">
                        <div>
                          <span class="price">{item?.price?.toFixed(6)}</span>
                          <br />
                          <div>
                            <small className="volume_price">
                              {item?.volume?.toFixed(6)}
                            </small>
                          </div>
                        </div>
                        <span
                          className={`price_small ${
                            item?.pChange >= 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {item?.pChange?.toFixed(2)}%
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
        {/* <!-- End .slick-activation-01 --> */}
      </section>
      {/* <!-- End Live Auction -->


  {/* /* <!-- Start Live Auction --> */}
      <section className=" market_update market_update_table pb-90">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2 className="text-gradient">Market Update</h2>
            <p>The place where you can trade with cryptocurrencies</p>
          </div>
          {/* <!-- End .section-title --> */}
        </div>
        {/* <!-- End .container --> */}

        <div className="container">
          <div class="table-responsive">
            <table class="table ">
              <thead>
                <tr>
                  <th> Pair</th>
                  <th> Last Price</th>
                  <th> 24H Change</th>
                  <th> 24H High</th>
                  <th> 24H Low</th>
                  <th> 24H Vol</th>
                  <th> 24H Turnover</th>
                  <th> Operation</th>
                </tr>
              </thead>
              <tbody>
                {upperPairData.length > 0
                  ? upperPairData.map((item) => (
                      <tr>
                        <td>
                          {" "}
                          <div class="td_div">
                            {/* <span class="star_btn btn_icon">
                            <i class="ri-star-line"></i>
                          </span> */}
                            <img
                              src={ApiConfig.appUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2 "
                            />
                           {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td>
                          <b>{item?.buy_price?.toFixed(7)}</b>
                        </td>
                        <td
                          className={
                            item?.pChange >= 0
                              ? "color-green text-success"
                              : "color-red text-danger"
                          }
                        >
                          <b>{item?.change?.toFixed(7)} </b>{" "}
                        </td>
                        <td>
                          <b>{item?.high?.toFixed(7)}</b>
                        </td>
                        <td>
                          <b>{item?.low?.toFixed(7)}</b>
                        </td>
                        <td>
                          <b>{item?.volume?.toFixed(7)}</b>
                        </td>
                        <td>
                          <b>{(item?.buy_price * item?.volume)?.toFixed(
                                      7
                                    )}</b>
                        </td>

                        {/* {hours <= "00" && minutes <= "00" && seconds <= "00" ? ( */}
                          <td>
                            <a
                              href=""
                              onClick={() => nextPage(item)}
                              class="btn btn-theme btn-sm"
                            >
                              Trade
                            </a>
                          </td>
                        {/* ) : (
                          <td>
                            {item?.fname === "PLR" ? (
                              <Countdown
                                timeTillDate="26 05 2023, 12:00 am"
                                timeFormat="DD MM YYYY, hh:mm a"
                              />
                            ) : (
                              <a
                                href=""
                                onClick={() => nextPage(item)}
                                class="btn btn-theme btn-sm"
                              >
                                Trade
                              </a>
                            )}
                          </td>F
                        )} */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {/* <!-- End .slick-activation-01 --> */}
      </section>
      {/* <!-- End Live Auction --> */}

      <section className=" bacts_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2 className="text-gradient">Become a crypto trader in seconds</h2>
            <p>We've got everything you need to start trading.</p>
          </div>

          <div className="row">
            <div className="col-lg-4 mb-2">
              <div className="card card-dark h-100">
                <div className="card-body text-center">
                  <img src="images/bacts_1.png" alt="" />
                  <h4 className="text-gradient">Buy & Sell Crypto</h4>
                  <p className="mb-0">
                    {" "}
                    On Our Polarize Exchange You Can Buy & Sell more than 100+
                    Cryptocurrencies within a click{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <div className="card card-dark h-100">
                <div className="card-body text-center">
                  <img src="images/bacts_2.png" alt="" />
                  <h4 className="text-gradient"> Trade Assets </h4>
                  <p className="mb-0">
                    Trade with 3 Pairs Including USDT, INR and PLR
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <div className="card card-dark h-100">
                <div className="card-body text-center">
                  <img src="images/bacts_3.png" alt="" />
                  <h4 className="text-gradient"> Earn Rewards for Trading </h4>
                  <p className="mb-0">Refer and earn 30% Trading brokerage</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" wactp_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2>
              World Class Trading{" "}
              <span className="text-gradient"> Platform </span>{" "}
            </h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="images/feature/1.png" alt="Exchange" />
                </div>
                <h4 className="title text-gradient">Exchange</h4>
                <p>
                  {" "}
                  Buy and Sell popular digital currencies, keep track of them in
                  the one place.{" "}
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="images/feature/2.png" alt="High performance" />
                </div>
                <h4 className="title text-gradient">High performance</h4>
                <p>
                  {" "}
                  Our exchange is superfast in its work due to a combination of
                  cutting-edge technology, a highly skilled team, strong
                  partnerships, and advanced trading systems.{" "}
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="images/feature/3.png" alt="Super Fast KYC" />
                </div>
                <h4 className="title text-gradient">Super Fast KYC</h4>
                <p>
                  We use advanced tools and technology to automate parts of the
                  verification process, which enables us to complete
                  verifications quickly and accurately.
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img
                    src="images/feature/5.png"
                    alt="User Friendly Interface"
                  />
                </div>
                <h4 className="title text-gradient">
                  {" "}
                  User Friendly Interface{" "}
                </h4>
                <p>
                  We place a high priority on providing a user-friendly
                  interface that is easy to navigate and use.
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="images/feature/6.png" alt="Safe & Secure" />
                </div>
                <h4 className="title text-gradient"> Safe & Secure </h4>
                <p>
                  We are committed to providing our users with a safe and secure
                  platform for trading and investing in cryptocurrencies, and we
                  take our responsibility to protect their assets and personal
                  information very seriously.
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="images/feature/7.png" alt="24x7 Help " />
                </div>
                <h4 className="title text-gradient">24x7 Help </h4>
                <p>
                  At our exchange, we take pride in our commitment to providing
                  excellent customer support 24/7.
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
          </div>
        </div>
      </section>

     

      <section className=" hiw_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2 className="text-gradient">How it work</h2>
            <p>Follow these simple steps , and start your trading journey</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block text-center hiw_card hiw_card_bar">
                <div className="thumb m-auto">
                  <img src="images/hiw_1.png" alt="nft wallet" />
                </div>
                <span>Step 1</span>
                <h4 className="title text-gradient">Login & Register</h4>
                <p>Enter your email address and create a strong password.</p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block text-center hiw_card hiw_card_bar">
                <div className="thumb m-auto">
                  <img src="images/hiw_2.png" alt="nft wallet" />
                </div>
                <span>Step 2</span>
                <h4 className="title text-gradient">Complete KYC</h4>
                <p>
                Submit Your Identity Document & Wait for your account to be verified & approved.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block text-center hiw_card">
                <div className="thumb m-auto">
                  <img src="images/hiw_3.png" alt="nft wallet" />
                </div>
                <span>Step 3</span>
                <h4 className="title text-gradient"> Start Trading </h4>
                <p>Once approved, login to your account and start trading.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" faq_sec ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="section-title ">
                <h6 className="mb-3">Need Help?</h6>
                <h2 className="text-gradient">
                  You Can Learn More From Our Asked Questions
                </h2>
              </div>

              <div class="accordion " id="accordionExample">
                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_1">
                    <button
                      class="accordion-button p-0  collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_1"
                      aria-expanded="false"
                      aria-controls="collapse_1"
                    >
                      Can I protect my cryptocurrency holdings on exchange from
                      theft or hacking?
                    </button>
                  </h2>
                  <div
                    id="collapse_1"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_1"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body card-body-padding-top ">
                      <p class="mb-0 text-white ">
                        Keep Your Password Safe and off rest we'll take care of
                        your fund's
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_2">
                    <button
                      class="accordion-button p-0 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_2"
                      aria-expanded="false"
                      aria-controls="collapse_2"
                    >
                      How long does it typically take to process a
                      cryptocurrency withdrawal on exchange?
                    </button>
                  </h2>
                  <div
                    id="collapse_2"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_2"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body card-body-padding-top">
                      <p class="mb-0 text-white ">
                        Withdrawal of Every Cryptocurrency Takes 24 hours for
                        withdraw
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_3">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_3"
                      aria-expanded="false"
                      aria-controls="collapse_3"
                    >
                      What is the KYC (Know Your Customer) requirements for
                      opening an account on Polarize Exchange?
                    </button>
                  </h2>
                  <div
                    id="collapse_3"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_3"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body  ">
                      <p class="mb-0 text-white ">
                        You need to provide the following details:
                      </p>

                      <p class="mb-0 text-white ">
                        {" "}
                        Aadhar Card, driving licence, Passport, Voting Card, Pan
                        Card, Mobile number Bank details, Self-Photo, Email.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_4">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_4"
                      aria-expanded="false"
                      aria-controls="collapse_3"
                    >
                      How long does it take to withdraw funds from my account?
                    </button>
                  </h2>
                  <div
                    id="collapse_4"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_4"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body  ">
                      <p class="mb-0 text-white ">It will take 24 hrs</p>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_5">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_5"
                      aria-expanded="false"
                      aria-controls="collapse_5"
                    >
                      What is the minimum deposit amount required to start
                      trading?
                    </button>
                  </h2>
                  <div
                    id="collapse_5"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_5"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body  ">
                      <p class="mb-0 text-white ">
                        There is no limit to deposit amount.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <h2 class="card-header" id="headingcollapse_3">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_6"
                      aria-expanded="false"
                      aria-controls="collapse_6"
                    >
                      How to resolve missing Crypto Deposit issue?
                    </button>
                  </h2>
                  <div
                    id="collapse_6"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingcollapse_6"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body  ">
                      <p class="mb-0 text-white ">
                        <strong>Steps-1</strong>
                        <br />
                        <br />
                        Confirm the blockchain transaction: The first step is to
                        verify that the transaction has been successfully
                        processed on the relevant blockchain. Check the
                        transaction ID or hash and then check it on a blockchain
                        explorer such as http://bscscan.com for BEP20 tokens
                        Etherscan for Ethereum Tokens or Blockchain.info for
                        Bitcoin. If the transaction is unconfirmed or not found,
                        Please Contact Support.
                      </p>

                      <br />
                      <br />

                      <p class="mb-0 text-white ">
                        <strong>Steps-2</strong>
                        <br />
                        <br />
                        Check the deposit address: Verify that you sent the
                        crypto to the correct deposit address for your account
                        on Polarize Exchange. It's possible that you may have
                        accidentally sent your crypto to the wrong address, in
                        that case you will need to contact the support team for
                        the recipient address.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <img src="images/faq_bg.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className=" joc_sec btn-gradient ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title mb-0 ">
                <h2 className="text-dark">Join Our Community</h2>
                <p className="text-dark mb-0">
                  Polarize Exchange is global. Join the conversation in any of
                  our worldwide communities.
                </p>
                <div className="d-flex  joc_social_row ">
                  <a
                    className="joc_social"
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100089301487392&mibextid=ZbWKwL"
                  >
                    <i className="ri-facebook-line  ri-xl mb-2"></i>
                    <span>Facebook</span>
                  </a>
                  <a
                    className="joc_social"
                    target="_blank"
                    href="https://twitter.com/polarizenetwork?t=zbYhHrtEH-PEA3YVwhhbgg&s=09"
                  >
                    <i className="ri-twitter-line  ri-xl mb-2"></i>
                    <span>Twitter</span>
                  </a>
                  <a
                    className="joc_social"
                    target="_blank"
                    href="https://www.instagram.com/polarizenetwork/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                  >
                    <i className="ri-instagram-line  ri-xl mb-2"></i>
                    <span>Instagram</span>
                  </a>
                  <a
                    className="joc_social"
                    target="_blank"
                    href="https://youtube.com/@polarizeofficial3170"
                  >
                    <i class="ri-youtube-line  ri-xl mb-2"></i>
                    <span>Youtube</span>
                  </a>
                  <a
                    className="joc_social"
                    target="_blank"
                    href="https://t.me/polarizenetwork"
                  >
                    <i class="ri-telegram-line  ri-xl mb-2"></i>
                    <span>Telegram</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="images/joc_img.svg" alt="" />
            </div>
          </div>
        </div>
      </section> */}

      {banner.length > 0 ?

      <div
        class="modal fade main_slide"
        id="onload"
        tabindex="-1"
        aria-labelledby="autoloadmodalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body p-0">
              <Swiper
                className="main_slide"
                loop={true}
                autoplay={{
                  delay: 2000,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                // slidesPerView={5}
                pagination={{
                  dynamicBullets: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                }}
              >
                {banner.map((item) =>
                  item?.status === "Active" ? (
                    <SwiperSlide>
                      <img
                        src={"https://api.polarize.exchange/"  + item?.banner_path}
                        className="img-fluid"
                      />
                    </SwiperSlide>
                  ) : (
                    ""
                  )
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </div> : null}
    </>
  );
};

export default LandingPage;
