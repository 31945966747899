import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-8">
              <div className="footer-widget first-block">
                <div className="mb-4">
                  <Link to="/" className="logo-light">
                    <img src="images/logo-white.png" alt="brand" />
                  </Link>
                  <Link to="/" className="logo logo-dark">
                    <img src="images/logo-dark.png" alt="brand" />{" "}
                  </Link>
                </div>
                <p className="mb-5">
                Unlock the power of crypto with Polarize Exchange 
                </p>
                <div className="social">
                  <a
                    className="icon-ssc"
                    href="https://www.facebook.com/profile.php?id=100089301487392&mibextid=ZbWKwL"
                  >
                    <i className="ri-facebook-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://twitter.com/polarizenetwork?t=zbYhHrtEH-PEA3YVwhhbgg&s=09"
                  >
                    <i className="ri-twitter-x-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://www.instagram.com/polarizenetwork/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                  >
                    <i className="ri-instagram-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://youtube.com/@polarizeofficial3170"
                  >
                    <i className="ri-youtube-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://t.me/+LXgrph185qtkZTU9"
                  >
                    <i className="ri-telegram-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://www.linkedin.com/company/officialpolarize/"
                  >
                    <i className="ri-linkedin-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://polarizenetwork.medium.com"
                  >
                    <i className="ri-medium-line"></i>
                  </a>
                  <a
                    className="icon-ssc"
                    href="https://www.threads.net/@polarizenetwork"
                  >
                    <i className="ri-threads-line"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-2 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>About</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/aboutus">About Us</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy </Link>
                  </li>
                  <li>
                    <Link to="/termsOfUse">Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to="/termsNConditions">Terms & Conditions</Link>
                  </li>
                  {/* <li>
                    <Link to="#"> KYC Policy </Link>
                  </li> */}
                  <li>
                    <Link to="/amlPolicy">AML Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-2 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>Contact</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/fees">Fees</Link>
                  </li>
                  <li>
                    <a href="https://forms.gle/qH7TioD2x9um7LDv5" target="_blank" > List your coin</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-3 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>Download mobile app</h4>
                <div className="down_tab">
                  <div>
                    <a href="#">
                      <img src="images/play_store.png" className="img-fluid" />
                    </a>
                    <a href="#">
                      <img src="images/app_store.png" className="img-fluid" />
                    </a>
                  </div>
                  <div className=" ms-3">
                    <img src="images/qr_code.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End .col --> */}
          </div>
        </div>

        <div className="container"><p><strong>Disclaimer:</strong> Crypto products and NFTS are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions. The disclaimer is for all those who are using this platform whose age is above 18 years. Below 18 years age is not applicable to use this application.</p></div>
        {/* <!-- End .container --> */}
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>
            Copyright © 2023 All Rights Reserved … Designed & developed ❤️ by Polarize Network
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
