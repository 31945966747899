import React, { useState, useEffect } from "react";
import DefaultInput from "../../../customComponent/DefaultInput";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import HomeService from "../../../api/services/HomeService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import {
  validateEmail,
  notEqualsZero,
  validateNumber,
} from "../../../utils/Validation";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { $ } from "react-jquery-plugin";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { useEditProfile } from "../../../context/EditProfileContext";

const SettingsPage = (props) => {
  const {
    emailId,
    setEmailId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    getSelfy,
    setGetSelfy,
    editProfile,
    setEditProfile,
  } = useEditProfile();
  // const [emailId, setEmailId] = useState();
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [myfile, setMyfile] = useState("");
  const [userId, setUserId] = useState("");

  const [localSelfy, setLocalSelfy] = useState("");
  // const [editProfile, setEditProfile] = useState(true);
  const [isMobileDisabled, setIsMobileDisabled] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  // const [getSelfy, setGetSelfy] = useState([]);

  const [crop, setCrop] = useState();

  const handleChangeSelfie = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    const imgata = URL.createObjectURL(fileUploaded);
    setMyfile(fileUploaded);
    setLocalSelfy(imgata);
  };

  const handleSettings = async (
    myfile,
    firstName,
    lastName,
    emailId,
    mobile
  ) => {
    var formData = new FormData();
    // formData.append("_id", userId);
    formData.append("profilepicture", myfile);
    formData.append("firstName", firstName.replace(/\s+/g, ' ').trim());
    formData.append("lastName", lastName.replace(/\s+/g, ' ').trim());
    formData.append("emailId", emailId.replace(/\s+/g, ' ').trim());
    formData.append("mobileNumber", mobile.replace(/\s+/g, ' ').trim());
    LoaderHelper.loaderStatus(true);
    await HomeService.updateSettings(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          handleDetials();
          //window.location.reload(false);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  useEffect(() => {
    handleDetials();
  }, []);

  useEffect(() => {
    if (emailId && firstName && lastName && getSelfy) {
      console.log(emailId, "emaiolId");
      setEditProfile(false);
    }
  }, []);

  const handleDetials = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getDetails().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserId(result?.data?._id);
          setEmailId(result?.data?.emailId);
          if (result?.data?.emailId) {
            setIsEmailDisabled(true);
          }
          setMobile(result?.data?.mobileNumber);
          if (result?.data?.mobileNumber) {
            setIsMobileDisabled(true);
          }
          setFirstName(result?.data?.firstName);

          setLastName(result?.data?.lastName);
          setGetSelfy(result?.data?.profilepicture);
          localStorage.setItem("selfie", result?.data?.profilepicture);
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  console.log(mobile);

  console.log(getSelfy, "getSelfy");

  return (
    <>
      <div
        class="tab-pane"
        id="SecurityPill"
        role="tabpanel"
        aria-labelledby="Security-pill"
      >
        <div class="row">
          <div class="col-md-12 col-lg-8 m-auto">
            <div class="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
              <form>
                <div class="row">
                  <div className="col-md-12">
                    <div class="avatar-upload">
                      <div class="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          name="myfile"
                          onChange={handleChangeSelfie}
                        />
                        <label for="imageUpload">
                          <i class="ri-camera-line"></i>
                        </label>
                      </div>
                      <div class="avatar-preview">
                        {!getSelfy && myfile === "" ? (
                          <img src="images/profilelogo.png" />
                        ) : (
                          <ReactCrop
                            onChange={(c) => setCrop(c)}
                            style={{ cursor: "auto" }}
                          >
                            <img
                              src={
                                !localSelfy
                                  ? `${ApiConfig.appUrl + getSelfy}`
                                  : localSelfy
                              }
                            />
                          </ReactCrop>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-4">
                    <div class="field-box">
                      <label for="text" class="form-label">
                        First Name
                      </label>
                      <DefaultInput
                        type="text"
                        name="firstName"
                        disabled={!editProfile}
                        // className="form-control"
                        aria-describedby="First Name"
                        placeholder="Enter Your First Name"
                        value={firstName === "undefined" ? "" : firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-4">
                    <div class="field-box">
                      <label for="text" class="form-label">
                        Last Name
                      </label>
                      <DefaultInput
                        type="text"
                        name="lastName"
                        disabled={!editProfile}
                        placeholder="Enter Last Name"
                        value={lastName === "undefined" ? "" : lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-4">
                    <div class="field-box">
                      <label for="text" class="form-label">
                        Email
                      </label>
                      <DefaultInput
                        type="email"
                        name="Email"
                        disabled={isEmailDisabled ? true : false}
                        placeholder="Enter Email"
                        value={emailId === "undefined" ? "" : emailId}
                        errorStatus={
                          validateEmail(emailId) !== undefined &&
                          notEqualsZero(emailId)
                        }
                        errorMessage={validateEmail(emailId)}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-4">
                    <div class="field-box">
                      <label for="text" class="form-label">
                        Mobile Number
                      </label>
                      <DefaultInput
                        type="number"
                        name="Mobile"
                        placeholder="Enter Mobile Number"
                        disabled={isMobileDisabled ? true : false}
                        errorStatus={validateNumber(mobile)}
                        errorMessage={validateNumber(mobile)}
                        value={mobile === "undefined" ? "" : mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-4 mt-4">
                    <div class="field-box">
                      <button
                        class="btn btn-gradient w-100 justify-content-center btn-medium {"
                        type="button"
                        disabled={!editProfile}
                        onClick={() => {
                          handleSettings(
                            myfile,
                            firstName,
                            lastName,
                            emailId === "undefined" ? "" : emailId,
                            mobile === "undefined" ? "" : mobile
                          );
                          setEditProfile(false);
                        }}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 mb-4 mt-4">
                    <div class="field-box">
                      <button
                        class="btn btn-gradient w-100 justify-content-center btn-medium {"
                        type="button"
                        onClick={() => setEditProfile(true)}
                      >
                        <span> Edit Profile</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade main_slide"
        id="onload"
        tabindex="-1"
        aria-labelledby="autoloadmodalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body p-0">
              <Swiper
                className="main_slide"
                loop={true}
                autoplay={{
                  delay: 5000,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                // slidesPerView={5}
                pagination={{
                  dynamicBullets: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                }}
              >
                <SwiperSlide>
                  <a href="#">
                    <img src="images/ad_anner.png" img-fluid />
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href="#">
                    <img src="images/ad_anner.png" img-fluid />
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href="#">
                    <img src="images/ad_anner.png" img-fluid />
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href="#">
                    <img src="images/ad_anner.png" img-fluid />
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href="#">
                    <img src="images/ad_anner.png" img-fluid />
                  </a>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
