import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import moment from "moment";
import { alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";

const StakingDetails = () => {
  
const duration = sessionStorage.getItem("duration")

const stakingId = sessionStorage.getItem("stakingId")

const isHarvest = sessionStorage.getItem("isHarvest")


const [stakingDetails ,setStakingDetails] = useState([])
const [currencyName,setCurrencyName] = useState('')
const [disabledButtons, setDisabledButtons] = useState([]);
const [status, setStatus] = useState()

const [id,setId] = useState()


useEffect(()=>{
handleStakingDetails(stakingId)
},[])


const handleStakingDetails = async (stakingId) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.getStakingDetails(stakingId).then(async (result) => {
    console.log(result, "kk");
    LoaderHelper.loaderStatus(false);
    if (result.success) {
      try {
        setStakingDetails(result?.data?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
  });
};



const handleHarvest = async (currency_name, interest_amount, _id) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.getHarvest(currency_name, interest_amount, _id).then(async (result) => {
    console.log(result, "kk");
    LoaderHelper.loaderStatus(false);
    if (result.success) {
      try {
        setDisabledButtons(prevDisabledButtons => [...prevDisabledButtons,  _id]);
        alertSuccessMessage("Successfully Harvested, check your wallet")
        handleStakingDetails(stakingId)
      } catch (error) {
        console.log(error);
      }
    }
  });
};

  return (
    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Staking Reward History</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Staking Reward History
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container">

        <div class="accordion plr_accordian" id="yeartabsaccordians">
          {duration == 1 ? <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearOne" aria-expanded="false" aria-controls="yearOne">
              1st Year
            </button>
          </h2>
          <div id="yearOne" class="accordion-collapse collapse " aria-labelledby="yearOne" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

            <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(0,12).length > 0 ? stakingDetails.slice(0,12).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}> {value?.harvest_status == true || status == true ? "Harvested" : "Harvest"}</button>
                            </td>
                          </tr>
                        </tbody>
                      )) :  <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>  : duration == 2 ? <>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearOne" aria-expanded="false" aria-controls="yearOne">
              1st Year
            </button>
          </h2>
          <div id="yearOne" class="accordion-collapse collapse " aria-labelledby="yearOne" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

            <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(0,12).length > 0 ? stakingDetails.slice(0,12).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}>Harvest</button>
                            </td>
                          </tr>
                        </tbody>
                      )) :  <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearTwo" aria-expanded="false" aria-controls="yearTwo">
              2nd Year
            </button>
          </h2>
          <div id="yearTwo" class="accordion-collapse collapse " aria-labelledby="yearTwo" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

            <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(12,23).length > 0 ? stakingDetails.slice(12,23).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}>Harvest</button>
                            </td>
                          </tr>
                        </tbody>
                      )) :  <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>
        </> : <>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearOne" aria-expanded="false" aria-controls="yearOne">
              1st Year
            </button>
          </h2>
          <div id="yearOne" class="accordion-collapse collapse " aria-labelledby="yearOne" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

            <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(0,12).length > 0 ? stakingDetails.slice(0,12).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}>
                              {value?.harvest_status == true || status == true ? "Harvested" : "Harvest"}
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      )) :  <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearTwo" aria-expanded="false" aria-controls="yearTwo">
              2nd Year
            </button>
          </h2>
          <div id="yearTwo" class="accordion-collapse collapse " aria-labelledby="yearTwo" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

            <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(12,24).length > 0 ? stakingDetails.slice(12,24).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}>
                              {value?.harvest_status == true || status == true ? "Harvested" : "Harvest"}
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      )) : <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#yearThree" aria-expanded="false" aria-controls="yearThree">
              3rd Year
            </button>
          </h2>
          <div id="yearThree" class="accordion-collapse collapse " aria-labelledby="yearThree" data-bs-parent="#yeartabsaccordians">
            <div class="accordion-body p-0">

              <div class="table-responsive">
                  <table class="table mb-0 table-dark table-striped">
                    <thead>
                      <tr>
                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Interest Amount</th>
                      <th>Interest Rate</th>
                      <th>Phase</th>
                      <th>Action</th>
                      </tr>
                    </thead>              
                    {stakingDetails.slice(24,36).length > 0 ? stakingDetails.slice(24,36).map((value, index) => (
                        <tbody>
                          <tr
                            className="cursor_pointer"
                          >
                            <td>{index + 1}</td>
                            {/* <td>
                              {moment(value?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td> */}
                            <td>{value?.currency_name}</td>
                            <td>{value?.interest_amount}</td>
                            <td>{value?.interest_rate} %</td>
                            <td>{value?.phase_name}</td>
                            <td>
                              <button className="btn btn-gradient" onClick={()=>{handleHarvest(value?.currency_name, value?.interest_amount, value?._id ); setStatus(value?.harvest_status);}} disabled={value?.harvest_status == true ? true : false || status== true ? true : false}>
                              {value?.harvest_status == true || status == true ? "Harvested" : "Harvest"}
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      )) : <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </table>                
              </div>


            </div>
          </div>
        </div>
        </> }
        
       
        
        
 
      </div>


          {/* <div class=" custom-tab-content p-0">
            <div
              class=" form-field-wrapper  p-0" 
            >
             
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default StakingDetails;
