import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import AuthService from "../../../api/services/AuthService";


const ReferredByMe = () => {


  const [ myReferrals, setMyReferrals] = useState([])

  useEffect(()=>{
handleMyReferrals()
  },[])

  const handleMyReferrals = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getMyReferrals().then(async (result) => {
      console.log(result);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setMyReferrals(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (

    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title"> My Referrals</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                My Referrals
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container">
          <div class="tab-content custom-tab-content p-0">
            <div
              class="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds"
            >
              <div class="table-responsive">
                {myReferrals?.length === 0 ? (
                  <div className="favouriteData">
                    <img
                      src="images/no-data.svg"
                      className="img-fluid"
                      width="96"
                      height="96"
                      alt=""
                    />
                    <br />
                    <p className="mt-3 mb-4"> No Data Found. </p>
                  </div>
                ) : (
                  <table class="table" style={{fontSize: "14px"}}>
                    <thead className="border-bottom"  >
                      <tr>
                      <th>Sr No.</th> 
                      <th>Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Staking(USDT/PLR)</th>
                        <th>Staking Duration</th>
                        <th>Staking Referral Reward</th>
                      </tr>
                    </thead>
                    {myReferrals?.length > 0 ? (
                      myReferrals?.map((value, index) => (
                        <tbody>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{moment(value?.date).format("MMM Do YYYY, h:mm:ss a")}</td>
                            <td>
                              {value?.firstName} {value?.lastName}
                            </td>

                            <td>{value?.email}</td>
                            <td>{value?.mobile}</td>
                            <td>$ {value?.usdtAmount} / {value?.stacking_price}</td>
                            <td>{value?.month_time/12} Year</td>
                            <td>{value?.referral_reward}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="6">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </table>
                )}
              </div>
             
            </div>
          </div>

          



        </div>
      </section>

     



    </>
  );
};

export default ReferredByMe;
