import { ApiConfig } from "../apiConfig/apiConfig";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../apiConfig/apiCall";

const AuthService = {
  /*** Calling Api's **/

  login: async (signId, password) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email_or_phone: signId.replace(/\s+/g, ' ').trim(),
      password: password.replace(/\s+/g, ' ').trim(),
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (
    countryCode,
    signId,
    firstName,
    lastName,
    password,
    cPassword,
    invitation,
    otp
  ) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const params = {
      cid: +countryCode.trim(),
      email_or_phone: signId,
      password: password,
      confirm_password: cPassword,
      referral_code: invitation,
      verification_code: otp,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getCode: async ( vCode,signId,authType      ) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      email_or_phone: signId,
      type: +authType,
      otp: vCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signid, resend) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      resend: true,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  otpVerify: async (otp, userId, emailId, first, last, type) => {
    const { baseUrl, otpVerify } = ApiConfig;
    const url = baseUrl + otpVerify;
    const params = {
      userId: userId,
      emailId: emailId,
      firstName: first,
      lastName: last,
      motp: otp,
      otp: true,
      otpType: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  referralLink: async () => {
     const token = sessionStorage.getItem("token");
    const { baseAuth, referalcode } = ApiConfig;
    const url = baseAuth + referalcode;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  referralCount: async () => {
     const token = sessionStorage.getItem("token");
    const { baseAuth, referalcount } = ApiConfig;
    const url = baseAuth + referalcount;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
       Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  updateSettings: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },

  submitkyc: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, identity } = ApiConfig;

    const url = baseAuth + identity;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };

    return ApiCallPost(url, formData, headers);
  },

  googleAuth: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  update2fa: async (authType, code, verifyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: code,
      email_or_phone: verifyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  placeOrder: async (
    infoPlaceOrder,
    buyprice,
    buyamount,
    base_currency_id,
    quote_currency_id,
    side
  ) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      order_type: infoPlaceOrder,
      base_currency_id: base_currency_id,
      quote_currency_id: quote_currency_id,
      side: side,
      price: +buyprice,
      quantity: +buyamount,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserfunds: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  generateAddress: async (currID, chain) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      currency_id: currID,
      chain: chain,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  estimatedPortfolio: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  pastOrder: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      base_currency_id: base_currency_id,
      quote_currency_id: quote_currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getStaking: async (userId, stakePhase, stakeAmount, time, percentage, currencyId,stacking_price, referralCode) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, staking } = ApiConfig;
    const url = baseUrl + staking;

    const params = {
      userId: userId,
      phase_name: stakePhase,
      usdtAmount: stacking_price,
      currency_id: currencyId,
      month_percentage: percentage,
      month_time: +time,
      stacking_price:+stakeAmount,
      referral_code: referralCode,
    };
    const headers = {
      "Content-Type": "application/json",
       Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  getStakingHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, stakingHistory } = ApiConfig;
    const url = baseUrl + stakingHistory;
    const params = {};


    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  getReferralIncome: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, referralIncome } = ApiConfig;
    const url = baseUrl + referralIncome;
    const headers = {
      "Content-Type": "application/json",
       Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  tradeHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, tradeHistory } = ApiConfig;
    const url = baseTrans + tradeHistory;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  transactionHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, transactionHistory } = ApiConfig;
    const url = baseUrl + transactionHistory;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  transferHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, transferHistory } = ApiConfig;
    const url = baseTrans + transferHistory;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getStakingDetails: async (stakingId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, stakingDetails } = ApiConfig;
    const url = baseUrl + stakingDetails;
    const params = {
      stacking_id: stakingId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  packageList: async () => {
    const { baseUrl, getPackageList } = ApiConfig;
    const url = baseUrl + getPackageList;

    const params = {
    }

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

 durations: async (phase) => {
    const { baseUrl, getDuration } = ApiConfig;
    const url = baseUrl + getDuration;

    const params = {
      phase_name : phase
    }

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getPercentage: async (stakePhase, time, usdtAmount) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, percentage } = ApiConfig;
    const url = baseUrl + percentage;
    const params = {
      phase_name: stakePhase,
      month_time: time,
      amount: usdtAmount
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getHarvest: async (currency_name, interest_amount, _id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, harvest } = ApiConfig;
    const url = baseUrl + harvest;
    const params = {
      _id: _id,
      amount: interest_amount,
      currency_name: currency_name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getMyReferrals:async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, myReferrals } = ApiConfig;
    const url = baseAuth + myReferrals;
    const params = {};


    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },



  cancelOrder: async (orderId) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      order_id: orderId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getCode2: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      signId: signid,
      type: "forgot",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (signId, otp, password) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: signId,
      verification_code: otp,
      new_password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  bannerList: async () => {
    const token = sessionStorage.getItem("token");

    const { baseAdmin, bannerList } = ApiConfig;
    const url = baseAdmin + bannerList;

    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  referralAmount: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, referralAmount } = ApiConfig;
    const url = baseAuth + referralAmount;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  categoryList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAdmin, categoryList } = ApiConfig;
    const url = baseAdmin + categoryList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getNotificationList: async () => {
    const token = sessionStorage.getItem("token");

    const { baseAdmin, notificationList } = ApiConfig;
    const url = baseAdmin + notificationList;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url,headers);
  },

  notificationStatus: async (id) => {
    const { baseAdmin, notificationStatus } = ApiConfig;
    const url = baseAdmin + notificationStatus;
    const params = {
      _id : id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  coinDetails: async (currency_id) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      currency_id: currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  setSecurity: async (oldpassword, password, conPassword) => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, security } = ApiConfig;
    const url = baseAuth + security;
    const params = {
      old_password: oldpassword,
      new_password: password,
      confirm_password: conPassword
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  favoriteCoin: async (pairId) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      pair_id: pairId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  favoriteList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, favoriteList } = ApiConfig;
    const url = baseAuth + favoriteList;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  setCurrency: async (currencyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, setCurrency } = ApiConfig;

    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  referralList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, referralList } = ApiConfig;

    const url = baseAuth + referralList;
    const params = {
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  getVerifyDeposit: async () => {
    const token = sessionStorage.getItem("token");

    const { baseUrl, depositVerify } = ApiConfig;

    const url = baseUrl + depositVerify;
    const params = {};

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  withdrawalAmount: async (wallet_Add, amount_val, coinName, otp) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, withdrawal} = ApiConfig;

    const url = baseWallet + withdrawal;

    const params = {
      withdrawal_address: wallet_Add,
      amount: +amount_val,
      coinName: coinName,
      otp:otp
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  cryptoCompareApi: async (base, quote, to, resolution) => {
    const token = sessionStorage.getItem('token');
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=PLR&tsym=${quote}&toTs=${to}&limit=2000`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },
};

export default AuthService;
