import React from "react";

const TermsNConditions = () => {
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Terms & Conditions</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Terms & Conditions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="create-item-wrapper plicy_sec">

<p><h3><strong>1.	Acceptance of Terms</strong></h3>
<br/>
<p> 
Thank you for choosing Polarize Crypto Exchange ("Polarize Exchange") as your platform for cryptocurrency trading and related services. Before you begin using our services, it is essential that you carefully read and fully understand the following Terms of Use. By accessing or using the Polarize platform, you acknowledge that you have read, understood, and agreed to be bound by these terms. If you do not agree with any provision of these terms, please refrain from accessing or using our platform.
</p>
             
                <p>
                  <strong>Eligibility:</strong>
                  <br/>
                  By accessing or using the Polarize platform, you represent and warrant that you are at least 18 years of age or the legal age in your jurisdiction to enter into this agreement. If you are accessing or using the platform on behalf of an entity, you further represent and warrant that you have the authority to bind that entity to these terms.
                </p>
                <p>
                  <strong>Platform Use:</strong>
                  <br/>
                  You agree to use the Polarize platform solely for its intended purposes, including trading and managing cryptocurrencies in accordance with applicable laws and regulations. You acknowledge that the use of the platform may involve financial risks, and you assume full responsibility for any risks and consequences associated with your trading activities.
                </p>
                <p>
                  <strong>Account Registration:</strong>
                  <br/>
                  In order to access certain features of the Polarize platform, you may be required to create a user account. You agree to provide accurate, complete, and up-to-date information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
                </p>
                <p>
                  <strong>Compliance with Laws:</strong>
                  <br/>
                  You agree to comply with all applicable laws, regulations, and legal obligations while using the Polarize platform. This includes, but is not limited to, anti-money laundering (AML) and know your customer (KYC) requirements. You acknowledge that Polarize may request additional information and documentation to verify your identity and compliance with these obligations.
                </p>
                <p>
                  <strong>Risk Disclosure:</strong>
                  <br/>
                  Trading cryptocurrencies involves substantial risk and may not be suitable for all users. You acknowledge that the cryptocurrency market is highly volatile and subject to significant price fluctuations. You understand and accept that Polarize does not provide investment advice and any trading decisions you make are solely your responsibility.
                </p>
                <p>
                  <strong>Security Measures:</strong>
                  <br/>
                  Polarize employs industry-standard security measures to protect the integrity and confidentiality of user information and funds. However, you acknowledge that no security measure is foolproof, and Polarize cannot guarantee absolute security. You agree to take necessary precautions, such as enabling two-factor authentication and safeguarding your account credentials, to ensure the security of your account.
                </p>
                <p>
                  <strong>Intellectual Property:</strong>
                  <br/>
                  All content and materials available on the Polarize platform, including but not limited to logos, trademarks, text, graphics, and software, are the intellectual property of Polarize or its licensors. You agree not to reproduce, distribute, modify, or create derivative works from any such content without prior written consent from Polarize.
                </p>
                <p>
                  <strong>Modifications and Termination:</strong>
                  <br/>
                  Polarize reserves the right to modify, suspend, or terminate the platform or any part thereof at any time without prior notice. Polarize also reserves the right to modify these Terms of Use at its discretion. It is your responsibility to review the terms periodically for any changes. Continued use of the platform after modifications signifies your acceptance of the revised terms.
                </p>
                <p>
                  <strong>Limitation of Liability:</strong>
                  <br/>
                  To the maximum extent permitted by applicable law, Polarize and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Polarize platform, including but not limited to trading losses, data loss, or financial losses.
                </p>
                <p>
                  <strong>Governing Law and Dispute Resolution:</strong>
                  <br/>
                  These Terms of Use shall be governed by and construed in accordance with the laws of Aurangabad. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Aurangabad.
                </p>
                If you have any questions or concerns regarding these Terms of Use, please contact our support team at support@polarizenetwork.com . By accepting these terms, you acknowledge that you have read and understood the Terms of Use and agree to be bound by them.
                </p>

                {/*  */}

                <p><h3><strong>2.	User Responsibilities</strong></h3>
<br/>
<p> 
As a user of the Polarize Crypto Exchange ("Polarize"), you agree to adhere to the following responsibilities while using our platform:
</p>
             
                <p>
                  <strong>Account Security:</strong>
                  <br/>
                  You are responsible for maintaining the security and confidentiality of your Polarize account. This includes safeguarding your login credentials, such as your username and password, and ensuring that they are not shared with unauthorized individuals. You should also enable additional security measures, such as two-factor authentication, to enhance the security of your account.
                </p>
                <p>
                  <strong>Compliance with Laws and Regulations:</strong>
                  <br/>
                  You agree to comply with all applicable laws, regulations, and legal obligations related to cryptocurrency trading and use of the Polarize platform. This includes, but is not limited to, complying with anti-money laundering (AML) and know your customer (KYC) requirements. You are responsible for understanding and adhering to the regulations specific to your jurisdiction.
                </p>
                <p>
                  <strong>Accurate Information:</strong>
                  <br/>
                  You agree to provide accurate, complete, and up-to-date information during the registration process and while using the Polarize platform. It is your responsibility to ensure that the information associated with your account, including your contact details and identification documents, is accurate and current.
                </p>
                <p>
                  <strong>Risk Assessment and Decision Making:</strong>
                  <br/>
                  You acknowledge that trading cryptocurrencies involves significant risks and market volatility. It is your responsibility to assess and understand these risks before engaging in any trading activities on the Polarize platform. You agree that any trading decisions you make are based on your own independent judgment, and you assume full responsibility for the outcomes of those decisions.
                </p>
                <p>
                  <strong>Prohibited Activities:</strong>
                  <br/>
                  You agree not to engage in any prohibited activities while using the Polarize platform. Prohibited activities include, but are not limited to:
                  <ul>
                    <li>●	Engaging in illegal or fraudulent activities, such as money laundering or market manipulation.</li>
                    <li>●	Using the platform for any unlawful purposes or activities.</li>
                    <li>●	Accessing or attempting to access accounts or systems without proper authorization.</li>
                    <li>●	Disrupting or interfering with the operation or security of the Polarize platform.</li>
                    <li>●	Sharing false, misleading, or malicious information about Polarize or other users.</li>
                    <li>●	Engaging in any activity that may harm or infringe upon the rights of Polarize or other users.</li>
                  </ul>
                </p>
                <p>
                  <strong>Privacy and Data Protection:</strong>
                  <br/>
                  You agree to respect the privacy of other users and protect their personal information. You should not collect, disclose, or misuse any personal data of other Polarize users without their explicit consent. Additionally, you should familiarize yourself with Polarize's Privacy Policy and understand how your own personal information is collected, stored, and used by the platform.
                </p>
                <p>
                  <strong>Reporting and Communication:</strong>
                  <br/>
                  You agree to promptly report any suspicious or unauthorized activities on the Polarize platform to the appropriate channels provided by Polarize. This includes reporting any security breaches, suspected fraud, or violations of the platform's terms. You should also maintain open and respectful communication with Polarize's support team, providing accurate information and cooperating in resolving any issues or concerns.
                </p>
                <p>
                  <strong>Third-Party Resources:</strong>
                  <br/>
                  Polarize may provide links or integrate with third-party resources, such as external websites or applications. You acknowledge and agree that your use of these third-party resources is at your own risk and subject to the terms and privacy policies of those third parties. Polarize is not responsible for the availability, accuracy, or legality of such external resources. Failure to comply with these user responsibilities may result in the suspension or termination of your Polarize account and may subject you to legal consequences as determined by applicable laws and regulations.
                </p>
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to abide by these user responsibilities.
               </p>
               {/*  */}

               <p><h3><strong>3.	Intellectual Property</strong></h3>
<br/>          
                <p>
                  <strong>Ownership:</strong>
                  <br/>
                  All content and materials available on the Polarize Crypto Exchange ("Polarize") platform, including but not limited to logos, trademarks, text, graphics, images, icons, and software, are the intellectual property of Polarize or its licensors. All rights, title, and interest in the platform's intellectual property are reserved by Polarize.
                </p>
                <p>
                  <strong>Limited License:</strong>
                  <br/>
                  Subject to your compliance with these Terms of Use, Polarize grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the platform solely for its intended purposes. This license does not grant you any rights to use, copy, reproduce, modify, distribute, or create derivative works from the platform's intellectual property unless explicitly authorized in writing by Polarize.
                </p>
                <p>
                  <strong>Trademarks and Logos:</strong>
                  <br/>
                  The Polarize name, logo, and any other trademarks or service marks displayed on the platform are the exclusive property of Polarize. You are prohibited from using, reproducing, or displaying these trademarks without prior written consent from Polarize.
                </p>
                <p>
                  <strong>User Content:</strong>
                  <br/>
                  By using the Polarize platform, you may have the ability to submit or upload content, including but not limited to comments, feedback, or suggestions. You retain ownership of any intellectual property rights that you hold in the content you submit. However, by submitting content on the platform, you grant Polarize a non-exclusive, worldwide, royalty-free, and sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, and display the content in connection with the operation and promotion of the platform.
                </p>
                <p>
                  <strong>Copyright Infringement:</strong>
                  <br/>
                  Polarize respects the intellectual property rights of others and expects its users to do the same. If you believe that your copyrighted work has been infringed upon on the Polarize platform, please promptly notify Polarize's designated Copyright Agent and provide the following information:
                  <ul>
                    <li>●	A description of the copyrighted work that you claim has been infringed.</li>
                    <li>●	Sufficient information to locate the allegedly infringing material on the platform.</li>
                    <li>●	Your contact information, including your name, address, telephone number, and email address.</li>
                    <li>●	A statement that you have a good faith belief that the use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
                    <li>●	A statement, made under penalty of perjury, that the information provided in your notice is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li>
                  </ul>
                </p>
                <p>
                  <strong>Unauthorized Use:</strong>
                  <br/>
                  You agree not to use, reproduce, distribute, modify, or create derivative works from any of the platform's intellectual property without obtaining prior written permission from Polarize. Any unauthorized use of the platform's intellectual property may result in legal consequences and enforcement actions.
                </p>
                <p>
                  <strong>Reporting Intellectual Property Infringement:</strong>
                  <br/>
                  If you believe that any content or materials on the Polarize platform infringe upon your intellectual property rights, please promptly notify Polarize's designated Intellectual Property Agent and provide the following information:
                  <ul>
                    <li>●	A description of the intellectual property right that you claim has been infringed.</li>
                    <li>●	Sufficient information to identify the allegedly infringing content or materials.</li>
                    <li>●	Your contact information, including your name, address, telephone number, and email address.</li>
                    <li>●	A statement that you have a good faith belief that the use of the intellectual property is not authorized by the owner, its agent, or the law.</li>
                    <li>●	A statement, made under penalty of perjury, that the information provided in your notice is accurate and that you are the owner or authorized to act on behalf of the owner.</li>
                  </ul>

                </p>
                <p>
                  <strong>Reservation of Rights:</strong>
                  <br/>
                  Except for the limited license expressly granted to you under these Terms of Use, all rights, title, and interest in the Polarize platform's intellectual property are reserved by Polarize. No licenses or rights are granted to you, whether by implication, estoppel, or otherwise, except as expressly stated herein.
                </p>
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to respect the intellectual property rights of Polarize and third parties.
               </p>

               {/*  */}

               <p><h3><strong>4.	Privacy and Data Collection</strong></h3>
<br/>          
<p>
Protecting your privacy and ensuring the security of your personal information is of utmost importance to Polarize Crypto Exchange ("Polarize"). This Privacy and Data Collection policy outlines how we collect, use, store, and protect your data when you access or use the Polarize platform.
</p>
                <p>
                  <strong>Information Collection:</strong>
                  <br/>
                  We collect certain information from you when you register an account, access the platform, or interact with our services. This information may include, but is not limited to:
                  <ul>
                    <li>
                    ●	Personal information: such as your name, email address, contact information, and identification documents.
                    </li>
                    <li>●	Account information: including your username, password, and security settings.</li>
                    <li>●	Transactional data: such as information related to your cryptocurrency transactions on the Polarize platform.</li>
                    <li>
                    ●	Communication data: including your correspondence with Polarize, support requests, and feedback.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Use of Information:</strong>
                  <br/>
                  We use the collected information for the following purposes:
                  <ul>
                    <li>●	To provide and improve our services, including customer support and platform functionality.</li>
                    <li>●	To facilitate cryptocurrency transactions and ensure compliance with legal and regulatory requirements.</li>
                    <li>●	To personalize your experience and customize the content and features of the Polarize platform.</li>
                    <li>●	To communicate with you about our services, updates, promotions, and important announcements.</li>
                    <li>●	To detect, prevent, and address fraud, security breaches, and unauthorized activities.</li>
                    <li>●	To conduct research and analysis for the enhancement of our platform and user experience.</li>
                  </ul>
                </p>
                <p>
                  <strong>Data Security:</strong>
                  <br/>
                  Polarize employs industry-standard security measures to protect your personal information and safeguard it against unauthorized access, disclosure, alteration, or destruction. These security measures include secure data transmission, encryption, firewalls, access controls, and regular security audits. However, please note that no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </p>
                <p>
                  <strong>Data Retention:</strong>
                  <br/>
                  We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy and Data Collection policy, unless a longer retention period is required or permitted by law. If you request the deletion of your account, we will securely delete or anonymize your personal information, unless we are legally obligated to retain it for legitimate business or legal purposes.
                </p>
                <p>
                  <strong>Data Sharing and Disclosure:</strong>
                  <br/>
                  We may share your personal information with trusted third parties, including service providers, financial institutions, regulatory authorities, and law enforcement agencies, as required or permitted by applicable laws and regulations. We do not sell, rent, or trade your personal information to third parties for marketing purposes without your explicit consent.
                </p>
                <p>
                  <strong>Cookies and Tracking Technologies:</strong>
                  <br/>
                  Polarize may use cookies and similar tracking technologies to collect and store certain information about your interactions with the platform. These technologies help us analyze user behavior, customize your experience, and gather demographic information. You have the option to control or disable cookies through your browser settings, but please note that doing so may affect the functionality and usability of the Polarize platform.
                </p>
                <p>
                  <strong>Third-Party Links:</strong>
                  <br/>
                  The Polarize platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of these websites and services before providing any personal information to them.
                </p>
                <p>
                  <strong>Children's Privacy:</strong>
                  <br/>
                  The Polarize platform is not intended for individuals under the age of 18 or the legal age in their jurisdiction. We do not knowingly collect or solicit personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete that information.
                </p>
                <p>
                  <strong>Updates to the Privacy Policy:</strong>
                  <br/>
                  We may update this Privacy and Data Collection policy from time to time. Any changes will be effective upon posting the updated policy on the Polarize platform. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.
                </p>
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms outlined in this Privacy and Data Collection policy.
               </p>
               {/*  */}

               <p><h3><strong>5.	Limitations & Liabilities</strong></h3>
<br/>          
<p>
Please read the following limitations and liabilities carefully as they govern your use of the Polarize Crypto Exchange ("Polarize") platform. By accessing or using the platform, you agree to the terms outlined below:
</p>
                <p>
                  <strong>No Financial Advice:</strong>
                  <br/>
                  Polarize does not provide financial or investment advice. The information and materials presented on the platform are for informational purposes only and should not be considered as financial, investment, or legal advice. You acknowledge that any trading or investment decisions you make are solely based on your own judgment and at your own risk.
                </p>
                <p>
                  <strong>Platform Availability:</strong>
                  <br/>
                  Polarize strives to provide a reliable and accessible platform. However, we do not guarantee uninterrupted or error-free access to the platform at all times. The platform may be subject to occasional downtime, maintenance, or technical issues. We are not liable for any losses or damages resulting from the unavailability, delay, or interruption of the platform.
                </p>
                <p>
                  <strong>Third-Party Services and Content:</strong>
                  <br/>
                  Polarize may integrate or provide links to third-party services, websites, or content. We do not endorse, control, or assume any responsibility for the availability, accuracy, legality, or quality of these third-party services or content. Your use of such third-party resources is at your own risk, and you should review their terms and privacy policies before using them.
                </p>
                <p>
                  <strong>Trading Risks:</strong>
                  <br/>
                  Trading cryptocurrencies involves substantial risks, including but not limited to market volatility, liquidity risks, and regulatory risks. You acknowledge and accept these risks when using the Polarize platform for cryptocurrency trading. Polarize does not assume any responsibility or liability for any financial losses, damages, or negative outcomes resulting from your trading activities.
                </p>
                <p>
                  <strong>Security:</strong>
                  <br/>
                  Polarize takes reasonable measures to protect the security of your account and personal information. However, no system can guarantee absolute security, and we cannot be held liable for unauthorized access, data breaches, or security incidents that are beyond our control. It is your responsibility to maintain the security of your account and take appropriate measures to protect your information.
                </p>
                <p>
                  <strong>User Conduct:</strong>
                  <br/>
                  You are responsible for your actions and conduct while using the Polarize platform. You agree not to engage in any illegal, fraudulent, or prohibited activities. You will not misuse the platform, disrupt its operation, or interfere with the rights of other users. Polarize is not liable for any damages or losses resulting from your actions or conduct on the platform.
                </p>
                <p>
                  <strong>Indemnification:</strong>
                  <br/>
                  You agree to indemnify and hold Polarize, its affiliates, directors, employees, and agents harmless from any claims, damages, losses, liabilities, costs, or expenses (including legal fees) arising out of or related to your use of the Polarize platform, your violation of these terms, or your infringement of any rights of third parties.
                </p>
                <p>
                  <strong>Limitation of Liability:</strong>
                  <br/>
                  To the maximum extent permitted by applicable law, Polarize, its affiliates, directors, employees, and agents shall not be liable for any indirect, consequential, incidental, punitive, or special damages, including but not limited to loss of profits, revenue, data, or business opportunities. In no event shall our total liability exceed the amount you have paid to us, if any, for accessing or using the Polarize platform.
                </p>
                <p>
                  <strong>Governing Law and Jurisdiction:</strong>
                  <br/>
                  These limitations and liabilities shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
                </p>
                If you do not agree with any of these limitations and liabilities, your sole remedy is to discontinue using the Polarize platform.
               </p>

              {/*  */}

              <p><h3><strong>6.	Termination</strong></h3>
<br/>          
<p>
This "Termination" clause outlines the conditions under which Polarize Crypto Exchange ("Polarize") may terminate or suspend your access to the platform. By using the Polarize platform, you agree to comply with the terms and conditions set forth below:
</p>
                <p>
                  <strong>Termination by Polarize</strong>
                  <br/>
                  Polarize reserves the right to terminate or suspend your access to the platform at any time and for any reason, including but not limited to the following:
                  <ul>
                    <li>
                    ● Breach of Terms: If you violate any provision of the Terms of Use, including our policies and guidelines
                    </li>
                    <li>● Legal Compliance: If your use of the platform violates any applicable laws, regulations, or legal obligations.</li>
                    <li>●	 Security Concerns: If there are reasonable grounds to believe that your account has been compromised, involved in fraudulent activities, or poses a security risk.</li>
                  <li>●	 Non-Usage: If you have not accessed or used the platform for an extended period of time without prior notice or explanation.</li>
                  <li>●	Operational Considerations: If Polarize decides, in its sole discretion, to cease providing the platform or discontinue certain features or services.</li>
                  </ul>
                </p>
                <p>
                  <strong>Effect of Termination:</strong>
                  <br/>
                  Upon termination or suspension of your access to the Polarize platform, the following consequences may apply:
                  <ul>
                    <li>●	Account Closure: Your account may be closed, and you may no longer have access to the platform or any associated services.</li>
                    <li>●	Loss of Data: You acknowledge that Polarize is not responsible for the retention or storage of your account data or any information associated with your account following termination. It is your responsibility to back up any necessary data before termination.</li>
                    <li>●	Restrictions: After termination, you are prohibited from accessing or using the Polarize platform, creating new accounts, or engaging in any activities on the platform unless explicitly authorized by Polarize in writing.</li>
                  </ul>
                </p>
                <p>
                  <strong>Survival</strong>
                  <br/>
                  Termination of your access to the platform does not affect any provisions of the Terms of Use that are intended to survive termination, including but not limited to the sections on Intellectual Property, Limitations & Liabilities, Indemnification, and Governing Law.
                </p>
                <p>
                  <strong>Right to Modify or Discontinue Platform:</strong>
                  <br/>
                  Polarize reserves the right to modify, suspend, or discontinue the platform or any part thereof at any time and without prior notice. We shall not be liable to you or any third party for any modification, suspension, or discontinuation of the platform.
                </p>
                <p>
                  <strong>Your Right to Terminate:</strong>
                  <br/>
                  You may choose to terminate your account and discontinue using the Polarize platform at any time by following the account closure process provided by Polarize.
                </p>
                <p>
                  <strong>Survival of Obligations:</strong>
                  <br/>
                  Termination of your access to the platform does not release you from any obligations or liabilities incurred prior to termination, including but not limited to outstanding payment obligations, indemnification obligations, or violations of intellectual property rights.
                </p>
                <p>
                  <strong>Notice of Termination:</strong>
                  <br/>
                  In the event of termination or suspension of your access to the platform, Polarize may provide you with notice via email or through the platform itself. However, Polarize reserves the right to terminate your access without prior notice, especially in cases of security concerns or legal violations.
                </p>
               </p>
                
{/*  */}
<p><h3><strong>7.	Governing Law and Dispute Resolution</strong></h3>
<br/>          
                <p>
                  <strong>Governing Law:</strong>
                  <br/>
                  These Terms of Use and any disputes arising from or in connection with the Polarize Crypto Exchange ("Polarize") platform shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any provisions of the United Nations Convention on Contracts for the International Sale of Goods are expressly excluded from these Terms of Use.
                </p>
                <p>
                  <strong>Dispute Resolution:</strong>
                  <br/>
                  It is the goal of Polarize to resolve any disputes or disagreements that may arise in a fair and efficient manner. Therefore, you agree that any dispute, claim, or controversy arising out of or relating to these Terms of Use or your use of the Polarize platform shall be resolved through the following dispute resolution mechanisms:
                  <ul>
                    <li>●	Informal Resolution: In the event of any dispute, you agree to first attempt to resolve the matter informally by contacting Polarize's customer support team and providing a clear and detailed description of the issue. Polarize will make reasonable efforts to address and resolve the dispute within a reasonable timeframe.</li>
                    <li>●	Mediation: If the dispute is not resolved through informal resolution, both parties agree to attempt to resolve the matter through mediation. The mediation will be conducted in accordance with the mediation rules and procedures agreed upon by both parties. The costs of mediation shall be shared equally between the parties, unless otherwise agreed.</li>
                    <li>●	Arbitration: If the dispute cannot be resolved through mediation, both parties agree to submit the dispute to binding arbitration. The arbitration shall be conducted in accordance with the rules and procedures of [Arbitration Institution/Provider] agreed upon by both parties. The arbitration award shall be final and binding on both parties, and any judgment upon the award may be entered in any court having jurisdiction.</li>
                  </ul>
                </p>
                <p>
                  <strong>Class Action Waiver:</strong>
                  <br/>
                  You and Polarize agree that any dispute resolution proceedings will be conducted on an individual basis and not as a class, consolidated, or representative action. You waive any right to participate in or assert claims as a plaintiff or class member in any purported class or representative proceeding.
                </p>
                <p>
                  <strong>Severability:</strong>
                  <br/>
                  If any provision of this "Governing Law and Dispute Resolution" term is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
                </p>
                <p>
                  <strong>Jurisdiction:</strong>
                  <br/>
                  Any legal action or proceeding arising out of or related to these Terms of Use or your use of the Polarize platform shall be brought exclusively in the courts of Aurangabad. You consent to the jurisdiction of such courts and waive any objection to the convenience or propriety of venue in such courts.
                </p>
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms of this "Governing Law and Dispute Resolution" provision. You further agree to abide by the selected dispute resolution mechanisms as outlined above.
               </p>
               {/*  */}
               <p><h3><strong>8.	User Account </strong></h3>
               <br/>          
                <p>
                  <strong>Registration and Account Creation:</strong>
                  <br/>
                  To access and use the services provided by Polarize Crypto Exchange ("Polarize"), you must create a user account. You agree to provide accurate, complete, and up-to-date information during the registration process. It is your responsibility to maintain the confidentiality of your account credentials and to promptly update any changes to your account information.
                </p>
                <p>
                  <strong>Account Security:</strong>
                  <br/>
                  You are solely responsible for maintaining the security of your Polarize account. This includes safeguarding your login credentials, such as your username, password, and any additional authentication factors. You should take appropriate measures to prevent unauthorized access to your account, such as enabling two-factor authentication and using strong, unique passwords. You must notify Polarize immediately if you suspect any unauthorized activity or breach of your account.
                </p>
                <p>
                  <strong>Account Usage:</strong>
                  <br/>
                  You agree to use your Polarize account solely for its intended purposes, which include accessing and utilizing the features and services provided by the platform. You are responsible for all activities that occur under your account, and you agree not to use your account for any illegal, fraudulent, or unauthorized activities.
                </p>
                <p>
                  <strong>User Identification and Verification:</strong>
                  <br/>
                  Polarize may require you to undergo user identification and verification procedures, such as providing identification documents or other relevant information, to comply with regulatory requirements and prevent fraudulent activities. You agree to cooperate with Polarize in providing accurate and valid information for user verification purposes.
                </p>
                <p>
                  <strong>Account Termination or Suspension:</strong>
                  <br/>
                  Polarize reserves the right to suspend or terminate your account or restrict your access to the platform, at its sole discretion and without prior notice, if it determines that you have violated these Terms of Use or engaged in any prohibited activities. Polarize also reserves the right to suspend or terminate accounts for reasons related to security, regulatory compliance, or legal obligations.
                </p>
                <p>
                  <strong>Account Data and Privacy:</strong>
                  <br/>
                  Polarize collects and processes personal data in accordance with its Privacy Policy. By creating a Polarize account, you consent to the collection, use, storage, and processing of your personal information as described in the Privacy Policy.
                </p>
                <p>
                  <strong>Account Communication:</strong>
                  <br/>
                  By creating a Polarize account, you agree to receive communications from Polarize related to your account, platform updates, security notifications, promotional offers, and other relevant information. You can manage your communication preferences through your account settings or by contacting Polarize's customer support.
                </p>
                <p>
                  <strong>Third-Party Account Access:</strong>
                  <br/>
                  You are solely responsible for maintaining the confidentiality of any third-party account credentials (e.g., wallets) linked to your Polarize account. Polarize is not responsible for any unauthorized access, use, or loss of funds associated with your linked third-party accounts.
                </p>
                <p>
                  <strong>Account Inactivity:</strong>
                  <br/>
                  Polarize reserves the right to suspend or terminate inactive accounts. An account is considered inactive if you have not accessed or made any transactions on the platform for a specified period of time, as determined by Polarize.
                </p>
                <p>
                  <strong>Closing Your Account:</strong>
                  <br/>
                  If you wish to close your Polarize account, you can initiate the account closure process through the platform. Closing your account may result in the permanent deletion of your account information and the inability to recover any associated data.
                </p>
                By creating a Polarize account, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "User Account" provision.
               
               </p>
               {/*  */}

               <p><h3><strong>9.	Third Party Links & Content </strong></h3>
               <br/>          
                <p>
                  <strong>External Links:</strong>
                  <br/>
                  The Polarize Crypto Exchange ("Polarize") platform may contain links or references to third-party websites, applications, or resources that are not owned or controlled by Polarize. These links are provided for your convenience and informational purposes only. Polarize does not endorse, guarantee, or assume any responsibility for the content, accuracy, legality, or functionality of these third-party links.
                </p>
                <p>
                  <strong>Third-Party Services and Applications:</strong>
                  <br/>
                  Polarize may integrate with or provide access to third-party services, applications, or tools. Your use of such services or applications is subject to the terms and conditions of the respective third parties. Polarize does not warrant the availability, performance, security, or suitability of these third-party services and shall not be liable for any damages or losses incurred as a result of your use of or reliance on such services.
                </p>
                <p>
                  <strong>Content Disclaimer:</strong>
                  <br/>
                  Polarize may display or provide access to content, information, or materials created by third parties. This includes but is not limited to user-generated content, market data, news articles, educational resources, and advertisements. Polarize does not endorse, guarantee, or assume any responsibility for the accuracy, completeness, timeliness, or reliability of such third-party content. You acknowledge and agree that any reliance on such content is at your own risk.
                </p>
                <p>
                  <strong>User Responsibility:</strong>
                  <br/>
                  When accessing third-party links or consuming third-party content through the Polarize platform, you are solely responsible for evaluating and assessing the suitability, accuracy, and legality of the information or services provided. It is your responsibility to exercise caution and use your own judgment when interacting with third-party links or content.
                </p>
                <p>
                  <strong>Risks and Liabilities:</strong>
                  <br/>
                  You acknowledge and agree that Polarize shall not be responsible or liable for any damages, losses, or expenses incurred as a result of your use of or reliance on third-party links or content. This includes but is not limited to any direct, indirect, incidental, consequential, or punitive damages, or any loss of profits, data, or reputation.
                </p>
                <p>
                  <strong>Third-Party Terms and Privacy Policies:</strong>
                  <br/>
                  When accessing third-party links or using third-party services through the Polarize platform, you are subject to the terms and conditions and privacy policies of those third parties. It is your responsibility to review and comply with these terms and policies.
                </p>
                <p>
                  <strong>User Discretion:</strong>
                  <br/>
                  You have the discretion to choose whether to access or interact with third-party links or content provided on the Polarize platform. You understand and acknowledge that Polarize does not have control over these third parties, and Polarize's inclusion of third-party links or content does not imply endorsement, partnership, or association with those entities.
                </p>
                
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and disclaimers outlined in this "Third Party Links & Content" provision. You further acknowledge and agree to exercise caution and make informed decisions when interacting with third-party links or content.
               
               </p>
               {/*  */}

               <p><h3><strong>10.	Modification of Terms </strong></h3>
               <br/>          
                <p>
                  <strong>Right to Modify:</strong>
                  <br/>
                  Polarize Crypto Exchange ("Polarize") reserves the right to modify, amend, or update these Terms of Use at any time, in its sole discretion, without prior notice. Any changes to the terms will be effective immediately upon posting the updated version on the Polarize platform. It is your responsibility to review the terms periodically to stay informed of any modifications.
                </p>
                <p>
                  <strong>Notification of Changes:</strong>
                  <br/>
                  Polarize may notify you of significant changes to the terms through various means, including but not limited to email, platform notifications, or prominent notices on the Polarize website. However, it is your responsibility to regularly check for updates and review the revised terms.
                </p>
                <p>
                  <strong>Continued Use:</strong>
                  <br/>
                  By continuing to access or use the Polarize platform after the modified terms have been posted, you indicate your acceptance of the updated terms. If you do not agree with the modified terms, you should immediately cease using the platform and close your Polarize account.
                </p>
                <p>
                  <strong>Retroactive Application:</strong>
                  <br/>
                  Changes to the terms will apply prospectively from the date of their posting on the Polarize platform. They will not have retroactive effect unless explicitly stated. Any rights or obligations that have accrued prior to the effective date of the modifications will be governed by the version of the terms in place at the time the rights or obligations arose.
                </p>
                <p>
                  <strong>Severability:</strong>
                  <br/>
                  If any provision of these Terms of Use is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be modified or interpreted to the extent necessary to make it valid, legal, and enforceable, while preserving the parties' original intent as closely as possible.
                </p>
                <p>
                  <strong>Waiver:</strong>
                  <br/>
                  Failure by Polarize to enforce any provision of these Terms of Use shall not be deemed a waiver of its right to enforce such provision or any other provision in the future.
                </p>
                <p>
                  <strong>Entire Agreement:</strong>
                  <br/>
                  These Terms of Use, together with any additional terms and policies referenced herein, constitute the entire agreement between you and Polarize concerning the use of the platform and supersede any prior agreements or understandings, whether oral or written.
                </p>
                
              
By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this "Modification of Terms" provision.

               
               </p>
               {/*  */}

               <p><h3><strong>11.	Indemnification </strong></h3>
               <br/>          
                <p>
                  <strong>Scope of Indemnification:</strong>
                  <br/>
                  You agree to indemnify, defend, and hold harmless Polarize Crypto Exchange ("Polarize"), its directors, officers, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or related to your use of the Polarize platform, your violation of these Terms of Use, or any other actions or omissions that result in a breach of your obligations.
                </p>
                <p>
                  <strong>Indemnification Process:</strong>
                  <br/>
                  In the event that Polarize becomes aware of a claim or potential claim that falls within the scope of indemnification, Polarize will promptly notify you in writing. You agree to assume control of the defense and settlement of any such claim, at your own expense, with counsel reasonably acceptable to Polarize. Polarize may, at its own cost, participate in the defense of such claim with separate counsel of its choice.
                </p>
                <p>
                  <strong>Cooperation:</strong>
                  <br/>
                  You agree to cooperate fully with Polarize in the defense of any claim that falls within the scope of indemnification. This includes providing Polarize with all necessary information, documentation, and assistance to effectively defend against such claims. Failure to cooperate may result in the forfeiture of your right to indemnification.
                </p>
                <p>
                  <strong>Limitation of Liability:</strong>
                  <br/>
                  Polarize will use commercially reasonable efforts to protect your account and information. However, in no event shall Polarize be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the platform or any claims covered by the indemnification provision.
                </p>
                <p>
                  <strong>Insurance:</strong>
                  <br/>
                  Polarize may, but is not obligated to, maintain appropriate insurance coverage to protect itself from claims covered by the indemnification provision. The existence of insurance coverage shall not limit or modify your indemnification obligations under these Terms of Use.
                </p>
                <p>
                  <strong>Modification of Indemnification Provision:</strong>
                  <br/>
                  Polarize reserves the right to modify the terms of this indemnification provision at any time, in its sole discretion. Any changes will be effective upon posting the updated version on the Polarize platform. It is your responsibility to review the indemnification provision periodically to stay informed of any modifications.
                </p>
                <p>
                  <strong>Severability:</strong>
                  <br/>
                  If any provision of this indemnification provision is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be modified or interpreted to the extent necessary to make it valid, legal, and enforceable, while preserving the parties' 
                  original intent as closely as possible.
                </p>
           
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "Indemnification" provision.             
               </p>
               {/*  */}

               <p><h3><strong>12.	Severability </strong></h3>
               <br/>          
                <p>
                  <strong>Severability Clause:</strong>
                  <br/>
                  If any provision of these Terms of Use or any part thereof is determined to be invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect to the maximum extent permitted by law. The invalid, illegal, or unenforceable provision shall be deemed severed from these Terms of Use, and the parties shall make their best efforts to replace the severed provision with a valid and enforceable provision that achieves, to the extent possible, the original intent of the parties.
                </p>
                <p>
                  <strong>Modification of Severability Clause:</strong>
                  <br/>
                  If any provision of the severability clause is found to be invalid, illegal, or unenforceable, the parties agree that the court shall have the authority to modify the provision to the extent necessary to make it valid, legal, and enforceable while preserving the parties' original intent as closely as possible.
                </p>
                <p>
                  <strong>Effect on Other Terms:</strong>
                  <br/>
                  The severability of any provision or part thereof shall not affect the validity or enforceability of any other provision or part thereof. The remaining provisions of these Terms of Use shall continue in full force and effect, and the rights and obligations of the parties shall be construed and enforced accordingly.
                </p>
                <p>
                  <strong>Waiver of Severability:</strong>
                  <br/>
                  The failure of Polarize Crypto Exchange ("Polarize") to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. The waiver of any breach or default of these Terms of Use shall not be deemed a waiver of any subsequent breach or default.
                </p>
                <p>
                  <strong>Entire Agreement:</strong>
                  <br/>
                  These Terms of Use, including the severability clause, constitute the entire agreement between you and Polarize regarding your use of the platform. It supersedes any prior agreements, understandings, or representations, whether oral or written, relating to the subject matter herein.
                </p>
               
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "Severability" provision.         
               </p>
               {/*  */}

               <p><h3><strong>13.	Entire Agreement </strong></h3>
               <br/>          
                <p>
                  <strong>Comprehensive Agreement:</strong>
                  <br/>
                  These Terms of Use, together with any additional terms, policies, or guidelines referenced herein, constitute the entire agreement between you and Polarize Crypto Exchange ("Polarize") regarding your use of the platform. They supersede any prior agreements, understandings, or representations, whether oral or written, relating to the subject matter herein.
                </p>
                <p>
                  <strong>Integration:</strong>
                  <br/>
                  The terms and conditions contained in these Terms of Use represent the complete and exclusive understanding between you and Polarize concerning your use of the platform. Any other communications, representations, or statements, whether made by Polarize's employees or third parties, are superseded by these Terms of Use and shall not be binding.
                </p>
                <p>
                  <strong>Modification:</strong>
                  <br/>
                  Any modifications or amendments to these Terms of Use must be made in writing and agreed upon by both you and Polarize. No other representations or modifications, whether made orally or in writing, shall have any force or effect unless specifically incorporated into these Terms of Use.
                </p>
                <p>
                  <strong>Prior Agreements:</strong>
                  <br/>
                  Any prior agreements, whether oral or written, between you and Polarize regarding the subject matter of these Terms of Use are hereby terminated and superseded by these Terms of Use.
                </p>
                <p>
                  <strong>Survival:</strong>
                  <br/>
                  In the event of the termination of these Terms of Use, any provisions that by their nature should survive termination shall continue to apply. This includes but is not limited to provisions related to intellectual property rights, limitations of liability, indemnification, and dispute resolution.
                </p>
                <p>
                  <strong>Language:</strong>
                  <br/>
                  These Terms of Use may be provided to you in multiple languages for your convenience. In the event of any discrepancies or inconsistencies between different language versions, the English version shall prevail.
                </p>
                <p>
                  <strong>Waiver:</strong>
                  <br/>
                  The failure of Polarize to enforce any provision of these Terms of Use shall not be deemed a waiver of its right to enforce such provision or any other provision in the future.
                </p>
                <p>
                  <strong>Severability:</strong>
                  <br/>
                  If any provision of these Terms of Use is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be modified or interpreted to the extent necessary to make it valid, legal, and enforceable, while preserving the parties' original intent as closely as possible.
                </p>
                
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "Entire Agreement" provision.   
               </p>
               {/*  */}
               <p><h3><strong>14.	Waiver</strong></h3>
               <br/>          
                <p>
                  <strong>Voluntary Waiver:</strong>
                  <br/>
                  Any failure or delay by Polarize Crypto Exchange ("Polarize") to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision unless explicitly stated in writing. Waiver of any breach or default of these Terms of Use shall not be deemed a waiver of any subsequent breach or default.
                </p>
                <p>
                  <strong>Limited Waiver:</strong>
                  <br/>
                  Any waiver granted by Polarize shall be limited to the specific instance or matter for which it is granted and shall not be construed as a general waiver of any rights or remedies available to Polarize under these Terms of Use or applicable law.
                </p>
                <p>
                  <strong>Written Waiver:</strong>
                  <br/>
                  Any waiver granted by Polarize must be in writing and signed by an authorized representative of Polarize. No other form of waiver, including but not limited to oral communications or course of conduct, shall be considered a valid waiver.
                </p>
                <p>
                  <strong>Effect on Other Provisions:</strong>
                  <br/>
                  The waiver of any right or provision under these Terms of Use shall not affect the validity or enforceability of any other provision. All other terms and conditions of these Terms of Use shall remain in full force and effect.
                </p>
                <p>
                  <strong>Reservation of Rights:</strong>
                  <br/>
                  Any waiver granted by Polarize is without prejudice to its right to enforce any other provisions of these Terms of Use or to exercise any rights or remedies available to it under the law.
                </p>
                <p>
                  <strong>Waiver by Users:</strong>
                  <br/>
                  No waiver of any right or provision by you, as a user of the Polarize platform, shall be effective unless explicitly agreed to in writing by Polarize.
                </p>
                <p>
                  <strong>Severability:</strong>
                  <br/>
                  If any provision of this waiver provision is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be modified or interpreted to the extent necessary to make it valid, legal, and enforceable, while preserving the parties' original intent as closely as possible.
                </p>
               
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "Waiver" provision.    
               </p>
               {/*  */}
               <p><h3><strong>15.	Contact Information</strong></h3>
               <br/>          
                <p>
                  <strong>Communication Channels:</strong>
                  <br/>
                  Polarize Crypto Exchange ("Polarize") provides various communication channels for users to contact Polarize and seek assistance or submit inquiries. These channels may include email support, live chat, support tickets, and designated customer service contact information.
                </p>
                <p>
                  <strong>Support Availability:</strong>
                  <br/>
                  Polarize will make reasonable efforts to ensure that support channels are available during specified hours of operation. However, availability may be subject to occasional downtime for maintenance, upgrades, or unforeseen circumstances. Polarize will strive to provide advance notice of any scheduled maintenance or service disruptions that may affect support availability.
                </p>
                <p>
                  <strong>Response Time:</strong>
                  <br/>
                  Polarize aims to respond to user inquiries and support requests in a timely manner. While Polarize endeavors to address inquiries promptly, response times may vary depending on the nature and complexity of the inquiry, as well as the volume of support requests received.
                </p>
                <p>
                  <strong>Contact Information:</strong>
                  <br/>
                  The contact information for Polarize, including the email address, physical address, and any designated customer service phone numbers, shall be made readily available to users. This information may be accessible through the Polarize website, user portal, or other communication channels established by Polarize.
                </p>
                <p>
                  <strong>Updates to Contact Information:</strong>
                  <br/>
                  Polarize reserves the right to update or change its contact information at any time, in its sole discretion. Any changes to contact information will be communicated to users through appropriate channels, such as platform notifications, email announcements, or updates on the Polarize website.
                </p>
                <p>
                  <strong>User Responsibilities:</strong>
                  <br/>
                  Users are responsible for providing accurate and up-to-date contact information to Polarize. It is important to maintain active email addresses and other relevant contact details to ensure effective communication between users and Polarize.
                </p>
                <p>
                  <strong>Fraudulent Communications:</strong>
                  <br/>
                  Users should exercise caution when receiving communications claiming to be from Polarize. Polarize will never request sensitive information, such as login credentials or personal identification details, via email or other non-secure channels. If you receive a suspicious communication, please report it to Polarize immediately.
                </p>
               
                By using the Polarize platform, you acknowledge that you have read, understood, and agreed to the terms and responsibilities outlined in this "Contact Information" provision.
               </p>
               {/*  */}
               <p>
               <center><h3><strong><h3><strong>End of Terms and Conditions</strong></h3></strong></h3></center>
               <br/>
               <p>
               These Terms and Conditions govern your use of the Polarize Crypto Exchange platform ("Polarize"). By accessing or using the Polarize platform, you agree to be bound by these Terms and Conditions. If you do not agree with any provision of these Terms and Conditions, you should immediately cease using the platform.
We encourage you to read these Terms and Conditions carefully to ensure that you understand your rights and responsibilities as a user of the Polarize platform. These Terms and Conditions cover important topics such as your obligations, intellectual property, privacy and data collection, dispute resolution, and more.
Polarize may modify or update these Terms and Conditions at any time, in its sole discretion, without prior notice. It is your responsibility to review these Terms and Conditions periodically to stay informed of any changes. Your continued use of the Polarize platform after any modifications indicates your acceptance of the updated Terms and Conditions.
If any provision of these Terms and Conditions is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. Polarize's failure to enforce any provision of these Terms and Conditions shall not be deemed a waiver of its right to enforce such provision or any other provision in the future.
These Terms and Conditions, together with any additional terms, policies, or guidelines referenced herein, constitute the entire agreement between you and Polarize regarding your use of the platform and supersede any prior agreements or understandings, whether oral or written.
<br/>
<br/>
Thank you for using the Polarize Crypto Exchange platform. If you have any questions or concerns about these Terms and Conditions, please contact us through the provided contact information.
<br/>
<br/>
Last updated: 1st June 2023
© [2023] Polarize Crypto Exchange. All rights reserved.

               </p>
               </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsNConditions;
