import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const ReferralList = () => {
  const [data, setData] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    getReferralList();
    // getRewardsList();
  }, []);

//   const getRewardsList = async () => {
//     await AuthService.rewardsList().then(async (result) => {
//       if (result.success) {
//         try {
//           setHistoryData(result?.data);
//           // alertSuccessMessage(result.message);
//         } catch (error) {
//           alertErrorMessage(error);
//         }
//       } else {
//         alertErrorMessage(result.message);
//       }
//     });
//   };

  const getReferralList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.referralList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setData(result?.data);
          // alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.date).format("Do MMMM YYYY")}</>;
  };

  const mailFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.email ? row?.email : row?.number}</>;
  };

  const dateHistoryFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const amountFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.amount}$</>;
  };

  const qFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.quantity} TBE</>;
  };

  const serialFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{rowIndex + 1}</>;
  };

  const kycFormatter = (cell,row,rowIndex,formatExtraData) => {
    return <>{row?.kycVerified === 2 ? <div style={{color:"green"}}>Verified</div>: row?.kycVerified === 0 || row?.kycVerified === ""  ? <div style={{color:"#ffe047"}}>Not Submitted</div> :row?.kycVerified === 1? <div style={{color:"#ffe047"}}>Pending</div> : <div style={{color:"red"}}>Rejected</div> }</>
  }

  const columns = [
    { dataField: "serialNo", text: "SR No", formatter: serialFormatter },
    { dataField: "email", text: "EMAIL"},
    { dataField: "mobile", text: "PHONE NO." },
    { dataField: "kycVerified ", text: "KYC STATUS", formatter: kycFormatter },
    {
      dataField: "date",
      text: "DATE",
      formatter: dateFormatter,
    },
  ];

  const columnsHistory = [
    { dataField: "serialNo", text: "SR No", formatter: serialFormatter },
    { dataField: "quantity", text: "QUANTITY", formatter: qFormatter },
    { dataField: "amount", text: "AMOUNT", formatter: amountFormatter },
    {
      dataField: "createdAt",
      text: "DATE",
      formatter: dateHistoryFormatter,
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 7,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    alwaysShowAllBtns: true,
  });

  return (
    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1
              class="title text-gradient"
              data-bs-toggle="modal"
              data-bs-target="#popup_success"
            >
              Referral List
            </h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="/referral">Referral Program</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Referral List
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section class="pb-90">
        <div class="container">
          {/* <div class="d-flex-between mb-5">
            <ul class="nav custom-tabs">
              <li>
                <a data-bs-toggle="tab" href="#history">
                  Reward History
                </a>
              </li>
              <li>
                <a class="active" data-bs-toggle="tab" href="#refer">
                  Referred Friends
                </a>
              </li>
            </ul>
          </div> */}

          <div className="tab-content custom-tab-content  ">
            <div class="tab-pane container active show fade" id="refer">
              <div class="row">
                <div class="col-md-12 m-auto">
                  <div class="form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded ">
                    <div class=" ">
                      {data.length === 0 ? (
                        <div className="favouriteData" style={{ textAlign: "center" }}>
                          <img
                            src="images/no-data.svg"
                            className="img-fluid"
                            width="96"
                            height="96"
                            alt=""
                          />
                          <br />
                          <p className="mt-3 mb-4"> No Data Found. </p>
                        </div>
                      ) : (
                        <table class="table">
                          <BootstrapTable
                            bootstrap4
                            keyField="_id"
                            columns={columns}
                            data={data}
                            pagination={pagination}
                          />
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* {history && ( */}
            <div class="tab-pane container fade" id="history">
              <div class="row">
                <div class="col-md-12 m-auto">
                  <div class="form-field-wrapper table_scroll p-0 switch_btn    bg-lighten card-rounded ">
                    <div class=" ">
                      {historyData.length === 0 ? (
                        <div className="favouriteData">
                          <img
                            src="images/no-data.svg"
                            className="img-fluid"
                            width="96"
                            height="96"
                            alt=""
                          />
                          <br />
                          <p className="mt-3 mb-4"> No Data Found. </p>
                        </div>
                      ) : (
                        <table class="table">
                          <BootstrapTable
                            bootstrap4
                            keyField="_id"
                            columns={columnsHistory}
                            data={historyData}
                            pagination={pagination}
                          />
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralList;
