import React, { useEffect, useState } from "react";
import {
  alertSuccessMessage,
  alertErrorMessage,
} from "../../../customComponent/CustomAlertMessage";
import HomeService from "../../../api/services/HomeService";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { useNavigate, Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const ReferralPage = () => {
  const navigate = useNavigate();

  const { appUrl } = ApiConfig;
  const [totalReferrals, setTotalReferrals] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  // const [totalRewardRate, setTotalRewardRate] = useState('');
  const [referralLink, setReferralLink] = useState("");
  const [tradingCommission, setTradingCommission] = useState("");
  const [referralListCount, setReferralListCount] = useState([])

  useEffect(() => {
    handleReferralData();
    handleReferralLink();
    getReferralList()
    // handleReferralAmount();
  }, []);

  const getReferralList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.referralList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setReferralListCount(result?.data.length);
          // alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleReferralData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.referralCount().then(async (result) => {
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          // alertSuccessMessage(result.sucess);
          setTotalReferrals(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // const handleReferralAmount = async () => {
  //   LoaderHelper.loaderStatus(true);
  //   await HomeService.referralAmount().then(async (result) => {
  //     if (result.success) {
  //       try {
  //         LoaderHelper.loaderStatus(false);
  //         // alertSuccessMessage(result.sucess);
  //         setTotalRewards(result.data?.referalCommission);
  //         setTradingCommission(result.data?.tradingCommission);
  //       } catch (error) {
  //         alertErrorMessage(error);
  //       }
  //     } else {
  //       LoaderHelper.loaderStatus(false);
  //       alertErrorMessage(result.message);
  //     }
  //   });
  // };

  const handleReferralLink = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.referralLink().then(async (result) => {
      //console.log(typeof result, "dfsdfsdf");
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          // alertSuccessMessage("hdkjghdfjhdfkgfd");
          setReferralLink(result?.data?.user_code);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // const copyFunc = () => {
  //   navigator.clipboard.writeText("http://54.206.100.52:3002/" + 'signup?reffcode=' + referralLink).then(() => {
  //     alertSuccessMessage("Copied!!");
  //   })
  //   .catch(() => {
  //     alertErrorMessage("Something went wrong");
  //   })
  // }

  const linkExc = "https://polarize.exchange/" + "signup?reffcode=" + referralLink + "_exc";
  const linkStk = "https://polarize.exchange/" + "signup?reffcode=" + referralLink + "_stk";

  return (
    <>
      <section class="inner-page-banner">
        <div class="container">
          <div class="inner text-center">
            <h1 class="title text-gradient">Polarize Referral Program</h1>

            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/profile">My Account</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Referral Program
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className="bg-1">
        <section class=" invite_sec logg_inn bg_img">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12 col-md-12 mx-auto">
                <div class="card">
                  <div class="card-body invite_card ">
                    <h5>
                      {" "}
                      <i class="fa fa-link"></i> Your referral link for Exchange
                    </h5>
                    <form data-copy="true">
                      <input
                        type="text"
                        value={
                          "https://polarize.exchange/" +
                          "signup?reffcode=" +
                          referralLink + "_exc"
                        }
                        data-click-select-all
                      />
                      <button
                        type="button"
                        style={{ cursor: "pointer" }}
                        value="Copy"
                        onClick={() => {
                          copy(linkExc);
                          alertSuccessMessage("Copied!!");
                        }}
                      >
                        {" "}
                        <i class="ri-file-copy-line"></i>{" "}
                      </button>
                      
                    </form>

                    <h5>
                      {" "}
                      <i class="fa fa-link"></i> Your referral link for Staking
                    </h5>

                    <form data-copy="true">
                      <input
                        type="text"
                        value={
                          "https://polarize.exchange/" +
                          "signup?reffcode=" +
                          referralLink + "_stk"
                        }
                        data-click-select-all
                      />
                      <button
                        type="button"
                        style={{ cursor: "pointer" }}
                        value="Copy"
                        onClick={() => {
                          copy(linkStk);
                          alertSuccessMessage("Copied!!");
                        }}
                      >
                        {" "}
                        <i class="ri-file-copy-line"></i>{" "}
                      </button>
                    </form>

                    <span
                      class="partnerx-hero-description-title"
                      style={{ paddingBottom: "20px" }}
                    >
                      Refer &amp; earn 30% of trading fee paid by your friends
                      as reward. Be your own Boss!
                    </span>

                    {/* <div class="share mb-3" >
                    <a href="javascript:void(0)" className={`pr_item ${referalPer === "30" ? "pr_item_current" :""}`} onClick={() => setReralPr("30")}>  30/0 </a>
                    <a href="javascript:void(0)" className={`pr_item ${referalPer === "20" ? "pr_item_current" :""}`} onClick={() => setReralPr("20")}>  20/10 </a>
                    <a href="javascript:void(0)" className={`pr_item ${referalPer === "15" ? "pr_item_current" :""}`} onClick={() => setReralPr("15")}>  15/15 </a>
                   
                  </div> */}

                    {/* <div class="share" >
                      <a href="#" className="item-facebook" ><i class="ri-facebook-circle-fill"></i> </a>
                      <a href="#" className="item-whatsapp"> <i class="ri-whatsapp-fill"></i> </a>
                      <a href="#" className="item-telegram"> <i class="ri-telegram-fill"></i></a>
                      <a href="#" className="item-twitter"><i class="ri-twitter-fill"></i> </a>
                    </div> */}

                    <div class="row g-3">
                      <div class="col-md-6 col-lg-3">
                        <div class="bl_card">
                          <img src="images/download_1.png" class="img-fluid" />
                          <h3 class="">
                            <small>Total Referrals</small>
                            {referralListCount > 0 ? referralListCount : 0}
                          </h3>
                          <Link
                            to="/referralList"
                            className=" text-gradient mt-3"
                          >
                            View Referal List
                          </Link>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3">
                        <div class="bl_card" style={{ height: "14.5rem" }}>
                          <img src="images/download_2.png" class="img-fluid" />
                          <h3 class="">
                            <small>Total Referral Rewards in PLR</small>
                            {totalRewards}
                          </h3>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3">
                        <div class="bl_card" style={{ height: "14.5rem" }}>
                          <img src="images/download_3.png" class="img-fluid" />
                          <h3 class="">
                            <small>Your Trade Reward </small>
                            30 %
                          </h3>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-3">
                        <div class="bl_card" style={{ height: "14.5rem" }}>
                          <img src="images/download_3.png" class="img-fluid" />
                          <h3 class="">
                            <small>Total Trade Commission </small>
                            {tradingCommission}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="partnerx-section partnerx-hero">
          <div class="partnerx-hero-bullets">
            <ul class="partnerx-hero-bullets-list">
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/50.png"
                  alt="Bullet-1"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Earn 30% as reward of every trading fee
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/cash-on-delivery.png"
                  alt="Bullet-2"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Payout every 24 hours!
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/bitcoin_new.png"
                  alt="Bullet-3"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Unlimited referrals
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/money.png"
                  alt="Bullet-4"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Unlimited rewards
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReferralPage;
