import React from "react";

const TermsOfUsePage = () => {
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> Terms Of Use </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terms Of Use
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="create-item-wrapper plicy_sec">
                <p>
                  Before using the online platform provided by Polarize Network
                  Pvt Ltd, please read the following user terms and conditions
                  carefully. These terms, together with any documents referred
                  to in them, set out the terms on which you may use our
                  website, http://polarize.exchange/, associated mobile and
                  software applications (existing or available in the future)
                  and our digital assets trading services.
                </p>

                <p>
                  By accessing, browsing, or registering to use the online
                  platforms as either a guest or registered user, you agree to
                  comply with these terms. We recommend that you print a copy of
                  these terms for future reference.
                </p>

                <p>
                  Your use of the online platforms confirms your acceptance of
                  these terms. If you do not agree with these terms, you must
                  not use the online platforms. If you do not agree with any
                  future amendments made to these terms, you must stop using the
                  online platforms immediately.
                </p>

                <p><strong>Other Applicable Terms:</strong>

                <p>
                  <strong> 1. </strong> These Terms include additional terms
                  that are relevant to your use of the online platforms,
                  including:
                   <ul>
                    <li><strong>●</strong> Our Privacy Policy, which outlines how we
                  process any personal data that we collect from you or that you
                  provide to us. By using the online platforms, you agree to
                  this processing and warrant that all data you provide is
                  accurate.</li>
                  <li><strong>●</strong> Our Anti-Money Laundering Policy, which sets
                  out the terms for verifying users' identities and the
                  procedures for identifying and reporting prohibited or illegal
                  activities committed through our services. When using the
                  online platforms, you must comply with the terms of the AML
                  policy.</li>
                  </ul> 
                </p>
                </p>

<p><strong>About Polarize Exchange:</strong>
             
                <p>
                  <strong>2.</strong> The online platforms provide access to an
                  online digital assets exchange that currently facilitates the
                  (i) online trading of digital assets including their sale and
                  purchase (either using fiat currency or another form of
                  digital assets), (ii) transfer of funds from a user’s fiat
                  wallet into its linked bank account and vice versa, and (iii)
                  online storage of digital assets owned by the users
                  (collectively the "Services"), operated by Jai Tech India (the
                  “Company” or “Polarize Exchange”) incorporated under the
                  Indian Companies Act with registered office at 1/22 Asaf Ali
                  Road New Delhi 110002.
                </p>
                </p>

                <p>
                  <strong>  Definitions:</strong> <br />
                  <strong>●</strong> "Coin Wallet" means
                  an online address accessible through the online platforms and
                  operated by a user for the storage of digital assets. <br /> <strong>●</strong>
                  "Content" means any information, text, graphics, or other
                  materials uploaded by the Company or users that appear on the
                  online platforms for other users to access. <br /> <strong>●</strong> "Digital
                  Assets" refers to blockchain-based cryptocurrencies such as
                  Bitcoin, Litecoin, Ethereum, Ripple, etc., as well as
                  proprietary coins and tokens.
                  <br /> <strong>●</strong> "Fiat Wallet" means an online address accessible
                  through the online platforms and operated by a user for the
                  storage of the user’s fiat currency holdings.
                  <br /> <strong>●</strong> "Funds" refers to both digital assets or fiat
                  currency, as the case may be.
                  <br /> <strong>●</strong> "Linked Bank Account" refers to any bank account owned
                  and operated by the user and held with a scheduled commercial
                  bank, the details of which were provided by the user during
                  the activation process mentioned under clause 7.1 herein.
                  <br /> <strong>●</strong> "Sanctions Lists" have the meaning given to it under
                  the AML Policy.
                  <br /> <strong>●</strong> "Wallet(s)" refers to a user’s coin wallet and/or its
                  fiat wallet, as the case may be.
                  <br /> <strong>●</strong> Any reference to “you” or “your” or “user” refers to
                  you as a user of the online platforms and the Services, and
                  any reference to “we,” “our,” and “us” shall refer to the
                  Company as the provider of the Services.
                </p>

                <p> <strong>Changes to These Terms and Services: </strong></p>
                <p>
                 <strong>4.1.</strong> We may revise
                  these Terms at any time by amending this page. Please check
                  this page periodically to take notice of any changes we made,
                  as they are binding on you. <br/><br/><strong>4.2.</strong> We may change the Services
                  without prior notice by adding or removing functionalities or
                  features, stopping providing the Services or features of the
                  Services to you or users generally, or creating usage limits
                  for the Services.
                </p>

<p><strong> Changes to the Online Platforms:</strong>
                <p>
                <strong>5.1.</strong> We may update the
                  online platforms from time to time and may change the content
                  at any time. However, please note that any content on the
                  online platforms may be out of date at any given time, and we
                  are under no obligation to update it.<br/><br/> <strong>5.2.</strong> We do not
                  guarantee that the online platforms or any content on them
                  will be free from errors or omissions.
                </p>
                </p>

                <p><strong>Accessing the online platforms  </strong>
                <p>
                 <strong>6.1.</strong> We cannot guarantee that
                  your use of the online platforms will always be available or
                  uninterrupted. Access to the online platforms is permitted on
                  a temporary basis and we may suspend, withdraw, discontinue,
                  or change all or any part of the online platforms without
                  notice. We will not be liable to you, including for any losses
                  incurred due to the volatility of prices of the digital
                  assets, if for any reason the online platforms are unavailable
                  at any time or for any period.
                  <br/><br/><strong>6.2.</strong>  You are responsible for
                  ensuring that all individuals who access the online platforms
                  through your internet connection are aware of these terms and
                  other applicable terms and conditions, and that they comply
                  with them.
                </p>
                </p>

                <p>
                  <strong>Account and Password</strong>
                  <p>
                    <strong>7.1.</strong> When you register with us on the
                    online platforms, we will create an account for you and
                    provide you with the necessary information to access it. To
                    use any of our services, you must activate your account by
                    completing the identity verification process as per the AML
                    policy. If you fail to do so, we reserve the right to
                    terminate your user account. "Account information" refers to
                    the password and any other information you provide as part
                    of our security procedures. If you register on the online
                    platforms through a third-party website such as Gmail, the
                    login information for that account will also be considered
                    part of the account information. You must keep your account
                    information confidential and not share it with anyone else.
                    Any activity carried out on the online platforms or any
                    other website accessed through your account will be
                    considered your responsibility.
                    <br />
                    <br />
                    <strong>7.2.</strong> By registering on the online
                    platforms, you confirm that:
                    <ul>
                      <li>
                        <strong>(a) </strong> you are legally competent to enter into contracts,
                        which means that if you are an individual, you are at
                        least eighteen years old, or if you are registering on
                        behalf of an entity, you are authorized to enter into
                        and agree to these terms and register for our services
                        under the laws of India;
                      </li>
                      <li><strong>(b) </strong> your name is not on any sanctions lists; and</li>
                      <li>
                        <strong>(c) </strong> your membership has not been suspended or terminated
                        by us for any reason. If we change the eligibility
                        criteria for registration and you no longer meet them,
                        we reserve the right to close your account without any
                        liability to us. You are responsible for ensuring that
                        you comply with these terms and with all applicable
                        laws, rules, and regulations. If your access to our
                        services is revoked or prohibited for any reason, you
                        agree not to use or access the online platforms or our
                        services in any way.
                      </li>
                    </ul>
                    <strong>7.3.</strong> We have the right to disable your
                    access to your user account or any part of it at any time
                    if, in our reasonable opinion, you have violated any of
                    these terms, including the AML policy.
                    <br />
                    <br />
                    <strong> 7.4. </strong> If you suspect that someone other
                    than you has access to your account information or any part
                    of it, you must notify us immediately by sending an email to
                    support@polarize.exchange We are not liable for any losses
                    or other consequences arising from unauthorized use of your
                    account.
                  </p>
                </p>

                <p>
                  <p>
                    <strong>8. </strong> Your wallets Upon activating your user
                    account, you will be given a fiat wallet and a coin wallet
                    to buy, send, receive, or store digital assets that are
                    supported by the online platforms. Prior to initiating an
                    order or transaction, you must maintain a minimum balance of
                    funds in your wallets. We have the right to refuse to
                    execute any order or transaction that violates the AML
                    policy or directions from enforcement authorities.
                    <br />
                    <strong>8.1. Terms of Fiat Wallet:</strong>
                    <ul>
                      <li>
                        <strong>8.1.1.</strong> The fiat wallet is used to store
                        your fiat holdings, which can only be used to purchase
                        digital assets through Polarize Exchange. The sale
                        proceeds of any digital assets will be credited to this
                        wallet, less the applicable transaction fee. You will
                        not receive any interest on the funds held in the fiat
                        wallet.
                      </li>
                      <li>
                        <strong>8.1.2.</strong> To acquire digital assets using
                        fiat currency, you must transfer funds from your linked
                        bank account to your fiat wallet. Your fiat wallet will
                        show transferred funds within three working days of the
                        initiation of such transfer, subject to any delays in
                        the relevant banking channels.
                      </li>
                      <li>
                        {" "}
                        <strong>8.1.3.</strong> You may request to withdraw
                        funds held in your fiat wallet by transferring them to
                        your linked bank account through the online platforms at
                        any time ("withdrawal request"). The company will try to
                        process each withdrawal request within three working
                        days of receiving it, subject to any delays in the
                        relevant banking channels.
                      </li>
                    </ul>
                    <strong>8.2. Terms of Coin Wallet:</strong>
                    <ul>
                      <li>
                        {" "}
                        <strong>8.2.1.</strong> The coin wallet is used to store
                        digital assets, which can be used to purchase other
                        digital assets. Any sale proceeds obtained in the form
                        of digital assets will be credited to this wallet, and
                        you will not receive any interest on the funds held
                        herein.
                      </li>
                      <li>
                        {" "}
                        <strong>8.2.2.</strong> You may withdraw funds from this
                        wallet but can only transfer them to another digital
                        asset wallet ("external wallet"). You may need to verify
                        that you own and operate the external wallet before your
                        withdrawal request is accepted.
                      </li>
                      <li>
                        {" "}
                        <strong>8.2.3.</strong> If you transfer funds to a
                        recipient who is not a user of our online platforms, we
                        will invite them to open a user account. If the
                        recipient fails to open a user account within 30 days,
                        we will transfer the funds back to your coin wallet.
                      </li>
                      <li>
                        {" "}
                        <strong>8.2.4.</strong> You are not allowed to transfer,
                        store, or receive any digital assets that are not
                        supported by our online platforms. You understand that
                        the company will not be responsible for any losses that
                        you may incur by attempting to transfer, store, or
                        receive digital assets that contravene this clause.
                      </li>
                    </ul>
                  </p>
                </p>

                <p>
                  <strong>
                    9: User Representations, Covenants, and Obligations
                  </strong>
                  <br /> These terms dictate your conduct on the online
                  platforms and outline your responsibilities. By using our
                  services, you agree to the following:
                  <ul>
                    <li>
                      {" "}
                      <strong>● </strong> You must comply with all the
                      obligations outlined in these terms, including the
                      Anti-Money Laundering (AML) policy, and economic sanctions
                      programs administered or enforced by any country or
                      international authority. These include the US Department
                      of the Treasury’s Office of Foreign Assets Control, the US
                      Department of State, and the United Nations Security
                      Council.
                    </li>
                    <li>
                      <strong>● </strong> We are prohibited from providing
                      services or entering into relationships with certain
                      individuals and institutions. If we are required to block
                      funds or digital assets associated with your user account
                      or linked bank account in accordance with a sanctions
                      program or other similar government sanctions programs, we
                      may suspend or terminate your user account or linked bank
                      account, return funds or digital assets to the destination
                      of their origin or an account specified by authorities, or
                      require you to withdraw funds or digital assets from your
                      user account or linked bank account within a certain
                      period of time. We are not responsible for any losses,
                      whether direct or indirect, that you may incur as a result
                      of our complying with applicable laws, regulatory
                      authority or government agency guidance or direction, or
                      any legal order.
                    </li>
                    <li>
                      {" "}
                      <strong>● </strong> You may only use our services for
                      lawful purposes and comply with these terms and
                      conditions. These terms and conditions also apply to all
                      applicable laws, statutes, by-laws, acts of legislature or
                      parliament, rules, regulations, orders, ordinances,
                      protocols, codes, guidelines, or policies of any
                      governmental authority, including without limitation of
                      the corruption of the corruption.
                    </li>
                    <li>
                      <strong>● </strong> You are solely responsible for
                      creating and maintaining all content in your account.
                    </li>
                    <li>
                      <strong>● </strong> You are responsible for safeguarding
                      the password you use to access the services and any
                      activities or actions under your account. We encourage you
                      to use strong passwords, preferably using a combination of
                      upper- and lower-case letters, numbers, and symbols with
                      your account. We are not liable for any loss or damage
                      arising from your failure to comply with this instruction.
                      You acknowledge that you will irreversibly lose your
                      digital assets if you delete your account.
                    </li>
                    <li>
                      {" "}
                      <strong>● </strong> You must provide us with only true and
                      accurate information, including identification documents
                      submitted by you.
                    </li>
                    <li>
                      <strong>● </strong> You cannot cancel any orders initiated
                      but not executed on the exchange. If any order is
                      partially executed, we may in our sole discretion permit
                      cancellation of the unexecuted order. You acknowledge that
                      all orders and/or transactions are irreversible once
                      executed.
                    </li>
                    <li>
                      {" "}
                      <strong>● </strong> You must maintain sufficient funds in
                      your wallets before initiating any order and/or
                      transaction. If you have insufficient funds in your
                      wallet, the company may either cancel your order or
                      execute a partial transaction using the funds available in
                      your wallet.
                    </li>
                    <li>
                      <strong>● </strong> You understand that certain taxes may
                      be applicable upon the trading of digital assets, and you
                      would be required to determine your tax liability under
                      the applicable laws. You acknowledge that you are solely
                      responsible for the payment of any taxes that may arise in
                      connection with your use of services.
                    </li>
                    <li>
                      {" "}
                      <strong>● </strong> The actual market rate at which an
                      order and/or transaction is executed may vary due to the
                      volatility of digital assets' price.
                    </li>
                    <li>
                      <strong>● </strong> The company may be required to suspend
                      trading in cases of a force majeure event. You acknowledge
                      that your access to the services and/or the funds during
                      such periods may be limited or restricted, and the market
                      conditions may differ significantly following the
                      completion of such force majeure events.
                    </li>
                    <li>
                      <strong>● </strong> We do not control the underlying
                      technology governing the mining, creation, sale of any
                      digital assets. You acknowledge that the company does not
                      exercise any control over the market price, circulation,
                      or volatility of digital assets. The contract for the sale
                      of any digital assets shall be a strictly bipartite
                      contract between the seller and the buyer.
                    </li>
                    <li>
                      <strong>● </strong> It is illegal in India and all
                      relevant jurisdictions (save where the proper licenses are
                      obtained) to require a person to pay.
                    </li>
                  </ul>
                </p>

                <p>
                  <p>
                    <strong>10.1. </strong> Trading in digital assets may result
                    in substantial losses, and it is recommended that you
                    evaluate your financial situation and risk tolerance prior
                    to trading. Please take note of the following risks:
                    <ul>
                      <li>
                        <strong>10.1.1. </strong> Digital assets are currently
                        unregulated, and in the future, trading, holding, or
                        transferring some or all digital assets may be
                        considered illegal in India. It is advisable to seek
                        legal advice before using online platforms.
                      </li>
                      <li>
                        <strong>10.1.2. </strong> The value of digital assets is
                        highly volatile, and you may lose all of your funds. Due
                        to the market's infancy, during a market disruption or a
                        force majeure event, you may encounter difficulties or
                        impossibility in liquidating your position in certain
                        market conditions.
                      </li>
                      <li>
                        <strong> 10.1.3. </strong> Digital assets are not backed
                        by a central bank or any other financial regulator, and
                        as a result, there is no third party that may take
                        corrective action in the event of a global/regional
                        crisis.
                      </li>
                      <li>
                        <strong>10.1.4. </strong> Digital assets are vulnerable
                        to security breaches and government crackdowns since
                        they are stored online, which may compromise the
                        integrity or anonymity of the digital asset system.
                      </li>
                    </ul>
                    <strong>10.2. </strong> You agree that the above risks
                    associated with trading digital assets are not exhaustive,
                    and there may be additional risks not foreseen by the
                    company.
                    <br />
                    <br />
                    <strong>10.3. </strong> By creating a user account, you
                    acknowledge that you have evaluated your financial standing
                    and risk tolerance and have determined that it is suitable
                    for trading in digital assets.
                  </p>
                </p>

                <p>
                  <strong>Fees</strong>
                  <p>
                    <strong>11.1. </strong> Creating an account on the online
                    platforms and using them is free of charge.
                    <br />
                    <br />
                    <strong>11.2. </strong> However, trading on the online
                    platform will incur a transaction fee for each executed
                    transaction. The amount of the transaction fee will be as
                    specified in the fee schedule.
                    <br />
                    <br />
                    <strong>11.3. </strong> Depositing digital assets into the
                    coin wallet is free of charge. Depositing fiat currency into
                    the fiat wallet via credit card, debit card, or net banking
                    may require a transaction fee. The applicable fee for
                    withdrawing digital assets or fiat currency will be as
                    provided in the fee schedule. Additionally, deposits and
                    withdrawals of digital assets or fiat currency will be
                    subject to withdrawal limits specified in the fee schedule.
                  </p>
                </p>

                <p>
                  <strong>Intellectual Property Rights </strong>
                  <p>
                    <strong>12.1. </strong> All content found on the online
                    platforms, unless otherwise specified, is the property of
                    the company and is protected by copyright, trademark, and
                    other applicable laws.
                    <br />
                    <br />
                    <strong>12.2. </strong> The trademarks, service marks, and
                    logos used on the online platforms, including those of the
                    company and others, are the property of their respective
                    owners.
                    <br />
                    <br />
                    <strong>12.3. </strong> Users are prohibited from reverse
                    engineering or disassembling any part of the online
                    platforms for any reason whatsoever. Any such action shall
                    be considered a material breach of these terms and may
                    result in the termination of the defaulting user's account.{" "}
                    <br />
                    <br />
                    <strong>12.4. </strong> The online platforms may contain
                    third-party intellectual property, including the software
                    used for creating or distributing digital assets. The
                    company does not own any rights to such third-party IP and
                    is bound by the license terms for such IP. By agreeing to
                    these terms, users also agree to be bound by the said
                    license terms.
                  </p>
                </p>

                <p>
                  <strong>13. No reliance on information </strong> means that
                  users of the online platforms should not rely solely on the
                  information provided on the website as investment advice, and
                  should obtain specialist advice before making any investment
                  decisions. The company does not provide investment advice, and
                  users are solely responsible for determining whether any
                  investment, investment strategy, or related transaction is
                  appropriate for them based on their personal investment
                  objectives, financial circumstances, and risk tolerance. The
                  company accepts no responsibility for any decisions made by
                  users based on the information provided on the online
                  platforms, and under no circumstances will the operation of
                  the platform be deemed to create a relationship that includes
                  the provision of or tendering of investment advice. Users
                  should rely on their own judgment and discretion when using
                  the online platform, and the company assumes no responsibility
                  for any loss or injury that may arise due to the reliance on
                  any information published on the online platforms. 
                  <ul>

                    <li><strong>13.1.</strong> The
                  information provided on the online platforms is solely for
                  general purposes and is not intended to constitute investment
                  advice. You should seek professional advice before making any
                  investment decisions based on the content provided on the
                  online platforms. All trades executed on the online platforms
                  are based on the parameters of your order instructions, and
                  you are solely responsible for determining whether any
                  investment, investment strategy, or related transaction is
                  suitable for you based on your personal investment objectives,
                  financial circumstances, and risk tolerance. You acknowledge
                  and agree that your use of the online platforms does not
                  create a relationship that includes the provision of
                  investment advice, and we shall not be responsible or liable
                  for any decisions you make in connection with the online
                  platforms.</li>
                  <li><strong>13.2.</strong>The online platforms may display content that
                  is not owned by us, and such content is the sole
                  responsibility of the person or entity that makes it
                  available. The content on the online platforms, including
                  text, audio, video, photographs, graphics, and other visuals,
                  is for informational purposes only and does not constitute
                  professional investment advice, tips, or recommendations. We
                  do not provide investment or financial advice to our users,
                  and any reliance on the information provided on the online
                  platforms is at your own risk. We shall not be liable for any
                  loss or injury that may arise due to your reliance on any
                  information published on the online platforms.</li>
                  <li><strong>13.3.</strong> We are
                  not responsible for the content of any third-party websites
                  linked to the online platforms, and the inclusion of any such
                  links should not be construed as an endorsement by us of the
                  linked websites. Your use of any linked websites is at your
                  own risk, and we shall not be liable for any loss or damage
                  that may arise from such use.</li>
                  <li><strong>13.4.</strong> While we make reasonable
                  efforts to ensure that the information on the online platforms
                  is accurate, complete, and up-to-date, we do not make any
                  express or implied representations, warranties, or guarantees
                  regarding the content's accuracy, completeness, or timeliness.</li>
                  </ul>
                   
                </p>

                <p>
                  <strong>Disclaimer of Warranties </strong>
                  <p>
                    <strong>14.1. </strong> By using the Services and Online
                    Platforms, you acknowledge and agree that you do so at your
                    own risk. The Services and Online Platforms are provided on
                    an "as is" and "as available" basis. We make our best
                    efforts to provide high-quality services to all users, but
                    to the fullest extent permitted by law, we expressly
                    disclaim and waive all warranties and conditions of any
                    kind, whether express or implied, including but not limited
                    to warranties of merchantability, fitness for a particular
                    purpose, or title. The content of the Services or Online
                    Platforms may contain bugs, errors, problems, or other
                    limitations. We assume no liability or responsibility for
                    any errors or omissions in content. <br />
                    <br />
                    <strong>14.2. </strong> We are not responsible for the
                    content uploaded by you on the Online Platforms. We are not
                    liable for any direct or indirect damages or losses caused
                    to you, including without limitation, lost profits, business
                    interruption, or other loss resulting from the use of or
                    reliance in any way on anything available on the Online
                    Platforms. It is solely your responsibility to evaluate the
                    accuracy, reliability, completeness, and usefulness of
                    content available on the Online Platforms that you use.{" "}
                    <br />
                    <br />
                    <strong>14.3. </strong> We make no warranty that the
                    Services or Online Platforms will meet your requirements, or
                    that the Services or your access to the Online Platforms
                    will be uninterrupted, timely, accurate, or reliable. Nor do
                    we make any warranty as to the permanent availability of any
                    information and/or that may be stored or transferred through
                    the Services or the Online Platforms. If there is any defect
                    in any software being used for the provision of the
                    Services, we do not make any warranty that defects in such
                    software will be corrected. You understand and agree that
                    any material and/or data downloaded or otherwise obtained
                    through use of the Services or Online Platforms is done at
                    your own discretion and risk, and you will be solely
                    responsible for any damage to your computer system or loss
                    of data that results from the download of such material or
                    data. If you store or transfer any information and/or data
                    through the Services or Online Platforms, you are strongly
                    advised to make back-up duplicate copies and are solely
                    responsible for any loss. <br />
                    <br />
                    <strong>14.4. </strong> No advice or information, whether
                    oral or written, obtained by you from the Services or Online
                    Platforms shall create any warranty not expressly made
                    herein.
                  </p>
                </p>

                <p>
                  <p>
                    <strong>15.1. </strong> You are prohibited from introducing
                    any malicious or technologically harmful material, including
                    viruses, Trojan, worms, logic bombs, or any other harmful
                    material. Attempting to gain unauthorized access to the
                    online platforms, the server on which it is stored, or any
                    server, computer or database connected to the online
                    platforms is strictly prohibited and may constitute a
                    criminal offense under the IT Act. We reserve the right to
                    report any such breach to the relevant law enforcement
                    authorities and to disclose your identity to them. If you
                    breach this provision, your right to use the online
                    platforms will be terminated immediately.
                    <br />
                    <br />
                    <strong>15.2. </strong>
                    We shall not be held responsible for any loss or damage
                    caused by viruses, denial-of-service attacks, distributed
                    denial-of-service attacks, or other technologically harmful
                    materials that may infect your computer equipment, computer
                    programs, data, or other proprietary material resulting from
                    your use of the online platforms or from downloading any
                    content on it, or on any website linked to it.
                    <br />
                    <br />
                    <strong>15.3. </strong> You are advised to use your own
                    virus protection software as we cannot guarantee or warrant
                    that files available for downloading from the online
                    platform will be free of infection by viruses, worms, Trojan
                    horses or other code that manifest contaminating or
                    destructive properties.
                  </p>
                </p>

                <p>
                  <strong> Limitation of Liability</strong>
                  <p>
                    <strong>16.1. </strong> To the extent permitted by law, we
                    exclude all conditions, warranties, representations, or
                    other terms which may apply to the online platform or any
                    content on it, including any information or the online
                    platform itself or services, whether express or implied.
                    <br />
                    <br />
                    <strong>16.2. </strong>
                    Our aggregate liability to any user shall not exceed the
                    fees paid by such user during the 6 (six) months immediately
                    preceding the date of claim for any direct, indirect, or
                    consequential loss or damage, whether in contract, tort
                    (including negligence), breach of statutory duty, or
                    otherwise, even if foreseeable, including those arising
                    under or in connection with: use of, or inability to use,
                    the services or the delays in transmission or operation of
                    the online platforms; or use of or reliance on any content
                    and/or information displayed on the online platforms; or
                    from errors, mistakes, omissions, or deletion of files; or
                    use of the services provided through the online platforms;
                    whether resulting from any communication failure, theft,
                    unauthorized access or a force majeure event.
                    <br />
                    <br />
                    <strong>16.3. </strong> If you are a business user, we shall
                    not be liable for any loss of profits, sales, business,
                    business opportunity or revenue, business interruption, loss
                    of anticipated savings, loss of business opportunity,
                    goodwill, or reputation.
                    <br />
                    <br />
                    <strong> 16.4. </strong> You agree not to use the online
                    platforms for any commercial or business purposes without
                    obtaining a legally valid license to do so in accordance
                    with these terms. Please note that all services provided
                    through the online platforms are only for domestic and
                    private use.
                  </p>
                </p>

                <p>
                  <strong>Electronic Communications</strong>
                  <p>
                    <strong>17.1. </strong> By visiting our online platforms or
                    sending us emails, you are communicating with us
                    electronically and consent to receiving electronic
                    communications from us. We may communicate with you via
                    email or by posting notices on our online platforms. You
                    agree that any agreements, notices, disclosures, and other
                    communications we provide to you electronically fulfill any
                    legal requirement for written communication. It is your
                    responsibility to regularly update any changes in your
                    contact information, including your email address. If we
                    send you any communication by email, we will send it to the
                    email address you provided us and consider it received once
                    it appears as sent in our email outbox. You may opt out of
                    receiving electronic communications from us at any time by
                    emailing us at support@polarize.exchange
                    <br />
                    <br /> <strong>17.2. </strong> You acknowledge that any
                    communication or information we provide to you through email
                    or our online platforms does not constitute investment
                    advice.
                  </p>
                </p>

                <p>
                  <strong>Linking and Framing</strong>

                  <p>
                    <strong>18.1. </strong> If you authenticate your account
                    through a third-party service, such as Google, you are
                    linking your account to your third-party account.
                    <br />
                    <br />
                    <strong>18.2. </strong> You may link to our home page as
                    long as it is done legally and fairly and does not harm our
                    reputation or take advantage of it.
                    <br />
                    <br />
                    <strong>18.3. </strong>
                    You must not establish a link that suggests any form of
                    association, approval, or endorsement on our part where none
                    exists.
                    <br />
                    <br /> <strong>18.4. </strong> You must not establish a link
                    to our online platforms on any website that you do not own.
                    <br />
                    <br />
                    <strong>18.5. </strong> Our website must not be framed on
                    any other website, and you may only link to our home page.
                    <br />
                    <br />
                    <strong>18.6. </strong> We reserve the right to withdraw
                    linking permission at any time without notice.
                    <br />
                    <br />
                    <strong>18.7. </strong> If you wish to use any content on
                    our online platforms beyond what is set out above, please
                    email us at support@polarize.exchange Indemnification You
                    agree to indemnify and hold harmless the company, its
                    owners, licensee, affiliates, subsidiaries, group companies
                    (as applicable), and their respective officers, directors,
                    agents, and employees from any claims, demands, or actions,
                    including reasonable lawyer's fees, made by any third party
                    or penalty imposed due to or arising out of:
                    <ul>
                      <li>(a) your use of our services</li>
                      <li>(b) your breach of these terms 18.</li>
                      <li>
                        (c) your violation of any law, rules, or regulations or
                        the rights (including infringement of intellectual
                        property rights) of a third party.
                      </li>
                    </ul>
                  </p>
                </p>

                <p>
                  <strong> Release and Waiver</strong>
                  <p>
                    <strong>19.1.</strong> You release and waive all claims
                    against the company, its subsidiaries, affiliates, officers,
                    agents, licensors, co-branders, or other partners, and
                    employees from any and all liability for claims, damages
                    (actual and/or consequential), costs and expenses (including
                    litigation costs and attorneys' fees) of every kind and
                    nature, arising from or in any way related to your use of
                    our online platforms, services, content, or digital assets.
                    You acknowledge that any fact relating to any matter covered
                    by this release may be found to be different from currently
                    believed, and accept the risk of such differences in fact.
                    Additionally, you expressly waive and relinquish any and all
                    rights and benefits that you may have under any other
                    national, state, or federal statute or common law principle
                    of similar effect, to the fullest extent permitted by law.
                    <br />
                    <br />
                    <strong>19.2. </strong>
                    We reserve the right to enforce our rights against you for
                    any obligation under these terms. If we fail to enforce our
                    rights or delay in doing so, it does not mean that we have
                    waived our rights. If we do waive a default by you, we will
                    only do so in writing, and it will not mean that we will
                    automatically waive any later default by you.
                  </p>
                </p>

                <p>
                  <strong>The Right of Set Off</strong>
                  <p>
                    <strong>20.1. </strong> By using our services, you
                    acknowledge and agree that we have the right to set off any
                    negative balances in your user account or coin wallet by
                    purchasing the necessary amount of digital assets at the
                    prevailing market price to offset such negative balances. We
                    may also use any of the other digital assets in your user
                    account or coin wallet to set off such negative balances.
                    Additionally, we reserve the right to use the balances in
                    your user account or coin wallet to settle any and all debts
                    owed to Polarize Exchange. rs in its sole discretion.
                  </p>
                </p>

                <p>
                  <strong>Applicable Law</strong>
                  <p>
                    <strong>21.1. </strong> Please note that these terms and
                    their formation, as well as the subject of these terms, are
                    governed by the laws of India. For more information, please
                    refer to our dispute resolution guidelines.
                  </p>
                </p>

                <p>
                  <strong>Termination</strong>
                  <p>
                    <strong>22.1. </strong> We reserve the right to deny you
                    access to our online platforms if we discover that you are{" "}
                    (a) not competent to contract under these terms or
                    applicable law, or (b) in breach of these terms. Our online
                    platforms are not available to persons whose names are
                    included in any sanctions lists or whose membership has been
                    suspended or terminated by us for any reason whatsoever.
                  </p>
                </p>

                <p>
                  <strong>General Terms</strong>
                  <p>
                    <strong>23.1. Relationship of the Parties: </strong> For all
                    purposes, including the execution of any order or
                    transaction initiated by you through our online platforms,
                    you and Polarize Exchange shall be independent parties. They
                    shall not act as partners, joint ventures, agents,
                    intermediaries, brokers, or in any other fiduciary capacity.
                    You shall not have any authority to assume or create any
                    obligation for or on behalf of Polarize Exchange, express or
                    implied, and you shall not attempt to bind us to any
                    contract.
                    <br />
                    <br />
                    <strong>23.2. Invalidity of Specific Terms: </strong> If any
                    provision of these terms is found to be invalid by a court
                    of competent jurisdiction, the other provisions of these
                    terms shall remain in full force and effect.
                    <br />
                    <br />
                    <strong>23.3. Entire Agreement: </strong> These terms, along
                    with our Privacy Policy, AML Policy, and Fees Schedule
                    (incorporated by reference herein), comprise the entire
                    understanding and agreement between you and Polarize
                    Exchange regarding the subject hereof. They supersede any
                    and all prior discussions, agreements, and understandings of
                    any kind (including prior versions of these terms) between
                    and among you and Polarize Exchange. Section headings in
                    these terms are for convenience only and shall not govern
                    the meaning or interpretation of any provision of these
                    terms.
                    <br />
                    <br />
                    <strong> 23.4. Third Parties: </strong> No person who is not
                    a party to these terms has any right under the Contracts
                    (Rights of Third Parties) Act, India, to enforce or enjoy
                    the benefit of any term of these terms.
                  </p>
                </p>

                <p>
                  <strong>Contact Us</strong>
                  <p>
                    <strong>24.1. </strong> For general inquiries, complaints,
                    and feedback, please email us at support@polarize.exchange
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUsePage;
