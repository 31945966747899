import { createContext, useContext, useState } from "react";


const CountContext = createContext();

const CountProvider = ({ children }) => {

    const [countdown, setCountdown] = useState({
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
      });
  
 

  return (
    <CountContext.Provider value={{ countdown, setCountdown }}>
      {children}
    </CountContext.Provider>
  );
};
const useCount = () => useContext(CountContext);

export { CountProvider, useCount };