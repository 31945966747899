import React from "react";
import Routing from "./Routing";
import { ProfileProvider } from "./context/ProfileProvider";
import Loading from "./customComponent/Loading";
import LoaderHelper from "./customComponent/Loading/LoaderHelper";
import { EditProfileProvider } from "./context/EditProfileContext";

function App() {
  return (
        <ProfileProvider>
          <EditProfileProvider>
            <Routing />
            <Loading ref={(ref) => LoaderHelper.setLoader(ref)} />
          </EditProfileProvider>
        </ProfileProvider>
  );
}

export default App;
