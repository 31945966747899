const appUrl = "https://api.polarize.exchange";

// const appUrl = "";

export const ApiConfig = {
  // =========EndPoints==========
  getcode: "verify-otp",
  getOtp: "send-otp",
  login: "login",
  register: "register",
  getDetails: "profile",
  updateSettings: "user/edit-profile",
  googleAuth: "generate-google-qr",
  update2fa: "enable-2fa",
  getHistoricalData: "historical-data",
  identity: "submit-kyc",
  placeOrder: "place-order",
  pastOrder: "past-order",
  cancelOrder: "cancel-order",
  categoryList: "coin-category-list",
  coinDetails: "coin-details",
  favoriteCoin: "favorite-coin",
  favoriteList: "favorite-list",
  tradeHistory: "trade-history",
  addFavourite: "exch/addfavcoin",
  getFavouriteList: "exch/getallfavcoin",
  setCurrency: "currency-preference",
  userfunds: "user-wallet",
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  forgotpassword: "forgot_password",
  referralAmount: "totalReferalAndReward",
  referalcode: "user_refer_code",
  referalcount: "total_refer_count",
  referralList: "referral_user_list",
  transferHistory: "wallet-history",
  depositVerify: "wallet/verify-deposit",
  withdrawal: "withdrawal",
  getAccDetails: "admin/admin_bank_details",
  bankdetails: "user_bank_details",
  getBankDetails:"get_user_bank_details",
  withdrawInr: "withdraw_inr",
  depositInr: "deposit_inr",
  staking : "stacking/select_stacking_package",
  stakingHistory: "staking/staking_history",
  transactionHistory: "staking/staking_income",
  bannerList: "banner_list",
  notificationList: "notification-list",
  notificationStatus: "notification_status",
  security: "change_password",
  referralIncome:"staking/referal-commission",
  stakingDetails : "staking/stacking-income-by-id",
  harvest: "stacking/harvest_commission",
  myReferrals: "referral_stacking_list",
  getPackageList: "stacking/true_packages",
  percentage:"stacking/return_percentage",
  getDuration: "stacking/month_time",

  // ============URLs================ //
  baseUrl: `${appUrl}/v1/`,
  appUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/user/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseKyc: `${appUrl}/kyc/`,

  // ============webSocketUrl================ //
  webSocketUrl: "wss://api.polarize.exchange",
};
