import React from "react";
import loading from "../../assets/logo-preloader.png";
import "./Loading.css";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderState: false,
    };
  }

  updateStatus = (status) => {
    this.setState({ loaderState: status });
  };

  render() {
    const { loaderState } = this.state;
    return (
      loaderState && (
        // <div className="centerbox">
        //   <div class="load">
        //       <div class="progress"></div>
        //       <div class="progress"></div>
        //       <div class="progress"></div>
        //   </div>
        // </div>

        <div class="centerbox">
          <div class="loader"></div>
        </div>
      )
    );
  }
}

export default Loading;
